import React, { Component } from "react";

import "./style.scss";

import User from "./elements/user/";
import Admin from "./elements/admin/";

import { Redirect } from "react-router-dom";

export default class Dashboard extends Component {
  render = () => {
    if (this.props.user.data) {
      let Page = null;

      switch (this.props.user.data.type) {
        case "admin":
          Page = <Admin {...this.props} />;
          break;
        default:
          Page = <User {...this.props} />;
          break;
      }

      return Page;
    } else {
      return <Redirect to={"/login"} />;
    }
  };
}
