import React from "react";

import { Link } from "react-router-dom";

export function RenderLink({ data, value, location }) {
  return (
    <Link
      to={{
        pathname: `/${location.pathname.split("/")[1]}/view/${
          location.pathname.split("/")[3]
        }/${data._id}`,
        prevUrl: location.pathname,
      }}
    >
      {value}
    </Link>
  );
}
