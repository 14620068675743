import React, { Component } from "react";

import { Helmet } from "react-helmet";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import request from "request";

export default class CreateBrand extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: "Create Brand" + " | " + "Chimera Solutions | Insights",
      form: {
        state: null,
        message: "",
      },
      field: {
        group: {
          data: [],
          value: "",
          message: "",
          state: null,
        },
        name: {
          value: "",
          message: "",
          state: null,
        },
        level: {
          value: [],
          message: "",
          state: null,
        },
        user: {
          value: [
            {
              id: "",
              name: "",
              state: null,
            },
          ],
          data: [],
          rawData: [],
          message: "",
          state: null,
        },
      },
    };

    this.valueChanged = this.valueChanged.bind(this);

    this.headers = {
      Authorization: this.props.user.data.usrKey,
      "Content-Type": "application/json",
      Accept: "application/json",
    };
  }

  valueChanged = (event) => {
    const element = event.target;

    if (element.getAttribute("data-field")) {
      const field = element.getAttribute("data-field");
      const key = element.getAttribute("data-key");

      this.setState((prevState) => {
        const value = prevState.field[field].value.map((data, index) => {
          const valueData = data;

          if (index === key) {
            switch (field) {
              case "level":
                valueData.status = data.status ? 0 : 1;
                break;
              default:
                break;
            }
          }

          return valueData;
        });

        return {
          field: {
            ...prevState.field,
            [field]: {
              ...prevState.field[field],
              value: value,
              message: "",
              state: null,
            },
          },
          form: {
            state: false,
          },
        };
      });
    } else {
      this.setState((prevState) => ({
        field: {
          ...prevState.field,
          [element.id]: {
            ...prevState.field[element.id],
            value: element.value,
            message: "",
            state: null,
          },
        },
        form: {
          state: false,
        },
      }));
    }

    this.checkField(element);
  };

  valdidateLevelStates = () => {
    this.setState(
      (prevState) => ({
        field: {
          ...prevState.field,
          level: {
            ...prevState.field.level,
            state: this.state.field.level.value.filter((level) => level.status)
              .length
              ? true
              : false,
          },
        },
      }),
      () => this.validateFields()
    );
  };

  checkField = (data) => {
    clearTimeout(this.time);
    this.time = setTimeout(() => {
      if (data.getAttribute("data-field")) {
        const field = data.getAttribute("data-field");

        switch (field) {
          case "level":
            let state = true;
            let message = "";

            const checkLevels = this.state.field.level.value.filter(
              (level) => level.status
            ).length;

            if (!checkLevels) {
              state = false;
              message = "please select a user level";
            }

            this.setState(
              (prevState) => {
                return {
                  field: {
                    ...prevState.field,
                    [field]: {
                      ...prevState.field[field],
                      message: message,
                      state: state,
                    },
                  },
                };
              },
              () => this.valdidateLevelStates()
            );
            break;
          default:
            break;
        }
      } else {
        switch (data.id) {
          case "group":
            this.setState((prevState) => ({
              field: {
                ...prevState.field,
                group: {
                  ...prevState.field.group,
                  value: "",
                  state: null,
                },
                name: {
                  ...prevState.field.name,
                  value: "",
                  state: null,
                },
                level: {
                  ...prevState.field.level,
                  value: [],
                  state: null,
                  message: "",
                },
                user: {
                  ...prevState.field.user,
                  value: [
                    {
                      id: "",
                      name: "",
                      state: null,
                    },
                  ],
                  data: [],
                  state: null,
                  message: "",
                },
              },
            }));

            if (data.value !== "0") {
              const group = JSON.parse(data.value);

              this.setState(
                (prevState) => ({
                  field: {
                    ...prevState.field,
                    group: {
                      ...prevState.field.group,
                      value: group.grpKey,
                      state: true,
                    },
                    level: {
                      ...prevState.field.level,
                      value: this.state.field.group.data
                        .find((res) => res.grpKey === group.grpKey)
                        .levels.map((level, key) => {
                          let status = 0;

                          if (!key) {
                            status = 1;
                          }

                          return { ...level, status: status };
                        }),
                    },
                    user: {
                      ...prevState.field.user,
                      data: this.state.field.user.rawData.filter(
                        (user) => user.group === group._id
                      ),
                    },
                  },
                }),
                () => this.valdidateLevelStates()
              );
            }
            break;
          case "name":
            if (data.value.length) {
              let state = true;
              let message = "";

              if (data.value.length <= 3) {
                state = false;
                message = "brand name should be minimum of 4 characters \n";
              }

              if (
                [].concat
                  .apply(
                    [],
                    [].concat.apply(
                      [],
                      this.state.field.group.data
                        .filter(
                          (data) => data.grpKey === this.state.field.group.value
                        )
                        .map((data) => data.brands.map((brand) => brand.name))
                    )
                  )
                  .find((name) => name === data.value)
              ) {
                state = false;
                message = "brand name exist \n";
              }

              this.setState(
                (prevState) => ({
                  field: {
                    ...prevState.field,
                    [data.id]: {
                      ...prevState.field[data.id],
                      value: data.value,
                      state: state,
                      message: message,
                    },
                  },
                }),
                () => this.validateFields()
              );
            }
            break;
          default:
            break;
        }

        this.validateFields();
      }
    }, 500);
  };

  validateFields = () => {
    this.setState((prevState) => ({
      form: {
        state: false,
      },
    }));

    if (
      this.state.field.name.state &&
      this.state.field.group.state &&
      this.state.field.level.state
    ) {
      this.setState((prevState) => ({
        form: {
          state: true,
        },
      }));
    }
  };

  checker(state) {
    const check = {
      icon: "check-circle",
      style: "null",
    };

    switch (state) {
      case true:
        check.icon = "check-circle";
        check.style = "correct";
        break;
      case false:
        check.icon = "times-circle";
        check.style = "wrong";
        break;
      default:
        break;
    }

    return check;
  }

  renderTitle = () => (
    <Helmet>
      <title>{this.state.title}</title>
    </Helmet>
  );

  handleChange = (date) => {
    this.setState((prevState) => ({
      field: {
        ...prevState.field,
        accntExp: date,
      },
    }));
  };

  getUser = () =>
    request(
      {
        method: "GET",
        url: this.props.chimeraserv.urlGet + "/user",
        headers: this.headers,
      },
      (error, response, body) => {
        this.setState((prevState) => ({
          field: {
            ...prevState.field,
            user: {
              ...prevState.field.user,
              rawData: JSON.parse(body),
            },
          },
        }));
      }
    );

  getGroup = () =>
    request(
      {
        method: "GET",
        url: this.props.chimeraserv.urlGet + "/group",
        headers: this.headers,
      },
      (error, response, body) => {
        this.setState((prevState) => ({
          field: {
            ...prevState.field,
            group: {
              ...prevState.field.group,
              data: JSON.parse(body),
            },
          },
        }));
      }
    );

  componentDidMount = () => {
    this.getGroup();
    this.getUser();
  };

  reset = () =>
    this.setState({
      reset: new Date().getTime(),
    });

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.reset !== this.state.reset) {
      this.setState((prevState) => ({
        form: {
          state: false,
          message: "",
        },
        field: {
          ...prevState.field,
          group: {
            value: "",
            data: [],
            message: "",
            state: null,
          },
          name: {
            value: "",
            message: "",
            state: null,
          },
          level: {
            data: [],
            value: [],
            message: "",
            state: null,
          },
          user: {
            ...prevState.field.user,
            data: [],
            value: [
              {
                id: "",
                name: "",
                state: null,
              },
            ],
            message: "",
            state: null,
          },
        },
      }));

      this.getGroup();
    }
  };

  add = () => {
    this.setState((prevState) => ({
      field: {
        ...prevState.field,
        user: {
          ...prevState.field.user,
          value: [
            ...prevState.field.user.value,
            {
              id: parseInt(prevState.field.user.value.length + 1),
              name: "",
              state: null,
            },
          ],
          message: "",
          state: null,
        },
      },
    }));
  };

  remove = (key) => {
    const newUsers = [].concat(
      [],
      this.state.field.user.value.filter((vale, index) => key !== index)
    );

    this.setState((prevState) => ({
      field: {
        ...prevState.field,
        user: {
          ...prevState.field.levels,
          value: newUsers,
          message: "",
          state: null,
        },
      },
    }));
  };

  submit = () => {
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        state: false,
        message: "",
      },
    }));

    request(
      {
        method: "POST",
        url: `${this.props.chimeraserv.urlPost}/group/create/${"brand"}`,
        headers: {
          Authorization: this.props.user.data.usrKey,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: {
          grpKey: this.state.field.group.value,
          name: this.state.field.name.value,
          levels: this.state.field.level.value
            .filter((level) => level.status)
            .map((level) => level.id),
          users: this.state.field.user.value.filter((user) => user.state),
        },
        json: true,
      },
      (error, response, body) => {
        if (!error) {
          if (!body.__v) {
            this.reset();

            this.setState((prevState) => ({
              form: {
                ...prevState.form,
                message: "Brand Created",
              },
            }));
          }
        } else {
          this.setState((prevState) => ({
            form: {
              ...prevState.form,
              message: JSON.stringify(error),
            },
          }));
        }
      }
    );
  };

  render() {
    return (
      <div className={"custForm"}>
        {this.renderTitle()}

        <div className={["viewTitle"]}>Create New Brand</div>
        <form onSubmit={() => {}} className={["form"]}>
          <div className={["form-group"]}>
            <label htmlFor="group">
              Group <span>*</span>
            </label>
            <FontAwesomeIcon
              className={[
                "icon",
                this.checker(this.state.field.group.state).style,
              ].join(" ")}
              icon={["fas", this.checker(this.state.field.group.state).icon]}
            />
            <select
              className="form-control"
              id="group"
              onChange={this.valueChanged}
            >
              <option defaultValue={0} value={0}>
                -- business group of brand --
              </option>
              {this.state.field.group.data.map((data, key) => (
                <option
                  value={JSON.stringify({
                    name: data.name,
                    grpKey: data.grpKey,
                    _id: data._id,
                  })}
                  key={key}
                >
                  {data.name}
                </option>
              ))}
            </select>
          </div>
          <div className={["form-group"]}>
            <label>
              Brand Name <span>*</span>
            </label>
            <FontAwesomeIcon
              className={[
                "icon",
                this.checker(this.state.field.name.state).style,
              ].join(" ")}
              icon={["fas", this.checker(this.state.field.name.state).icon]}
            />
            <div className={["field"]}>
              <input
                type={"text"}
                className={["form-control"]}
                id={"name"}
                placeholder={"enter brand name"}
                value={this.state.field.name.value}
                onChange={this.valueChanged}
              />
              <span>{this.state.field.name.message}</span>
            </div>
          </div>
          <div className={["form-group"]}>
            <label htmlFor="type">
              Levels <span>*</span>
            </label>
            <FontAwesomeIcon
              className={[
                "icon",
                this.checker(this.state.field.level.state).style,
              ].join(" ")}
              icon={["fas", this.checker(this.state.field.level.state).icon]}
            />
            <div className={["custom-control", "custom-checkbox"].join(" ")}>
              {this.state.field.level.value.map((data, key) => {
                return (
                  <div>
                    <input
                      type={"checkbox"}
                      className={["custom-control-input"]}
                      name="level"
                      id={key}
                      data-key={key}
                      onChange={this.valueChanged}
                      checked={data.status}
                      value={data.id}
                      data-field={"level"}
                    />
                    <label className={"custom-control-label"} htmlFor={key}>
                      {data.name}
                    </label>
                  </div>
                );
              })}
              <div className={["field"]}>
                <span>{this.state.field.level.message}</span>
              </div>
            </div>
          </div>
          <div className={["form-group"]}>
            <label>
              Users <span></span>
            </label>
            <div className={"multipart"}>
              {this.state.field.user.value.map((data, key) => (
                <div className={["field"]}>
                  <div className={"part"}>
                    <FontAwesomeIcon
                      className={["icon", this.checker(data.state).style].join(
                        " "
                      )}
                      icon={["fas", this.checker(data.state).icon]}
                    />
                    <input
                      type={"text"}
                      className={["form-control", "custField"].join(" ")}
                      id={"name"}
                      placeholder={"enter level name"}
                      value={data.name}
                      data-key={key}
                      onChange={this.valueChanged}
                      data-field={"user"}
                    />
                    {
                      <button
                        type={"button"}
                        onClick={() =>
                          key === 0 ? this.add() : this.remove(key)
                        }
                        className={[
                          "custBtn",
                          key === 0 ? "add" : "remove",
                        ].join(" ")}
                      >
                        <FontAwesomeIcon
                          className={[
                            "icon",
                            this.checker(this.state.field.user.state).style,
                          ].join(" ")}
                          icon={key === 0 ? "plus" : "minus"}
                        />
                      </button>
                    }
                  </div>
                  <span>{data.message}</span>
                </div>
              ))}
            </div>
          </div>
          {this.state.form.message ? (
            <div className={["message", "form-group"].join(" ")}>
              <FontAwesomeIcon
                className={["icon", "correct"].join(" ")}
                icon={"check-circle"}
              />
              {this.state.form.message}
            </div>
          ) : (
            <div />
          )}
          <button
            type={"button"}
            className={["btn btn-primary"]}
            disabled={this.state.form.state ? false : true}
            onClick={() => this.submit()}
          >
            Create
          </button>
          <button
            type={"button"}
            onClick={() => this.reset()}
            className={["btn btn-primary", "reset"].join(" ")}
          >
            Reset
          </button>
        </form>
      </div>
    );
  }
}
