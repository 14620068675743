import React, { Component } from "react";

import { Route, Switch } from "react-router-dom";

import { Helmet } from "react-helmet";

import "./style.scss";
import logo from "./images/logo.png";

import LoginForm from "./views/LoginForm";
import ForgotForm from "./views/ForgotForm";

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: "Login" + " | " + "Chimera Solutions | Insights",
      routes: [
        // component Loader
        {
          name: "Login",
          path: "/login",
          component: LoginForm,
        },
        {
          name: "Forgot Password",
          path: "/forgot",
          component: ForgotForm,
        },
      ],
    };
  }

  renderTitle = () => (
    <Helmet>
      <title>{this.state.title}</title>
    </Helmet>
  );

  render() {
    return (
      <div className={["container-fluid", "login"].join(" ")}>
        {this.renderTitle()}
        <div className={"row"}>
          <div className={["col-sm-12", "col-md-5", "rightCol"].join(" ")}>
            <div className={["view"].join(" ")}>
              <img
                className={["logo"]}
                src={logo}
                alt={"Chimera Insights Logo"}
              />
              <Switch>
                <Route
                  exact
                  path={"/login"}
                  component={(routeProps) => (
                    <LoginForm
                      {...routeProps}
                      chimeraserv={this.props.chimeraserv}
                    />
                  )}
                />

                {this.state.routes.map((route, key) => (
                  <Route
                    exact
                    key={key}
                    path={this.props.match.path + route.path}
                    render={(routerProps) => (
                      <route.component
                        {...routerProps}
                        chimeraserv={this.props.chimeraserv}
                      />
                    )}
                  />
                ))}

                <Route component={this.props.components.setting[0].component} />
              </Switch>
              <div className={["formFooter"]}>
                <p>Do you want to register?</p>
                <p>Visit our website to learn more</p>
                <a
                  href={"https://www.chimerasol.com/"}
                  rel="noopener noreferrer"
                  target={"_blank"}
                >
                  www.chimerasol.com
                </a>
              </div>
            </div>
          </div>
          <div
            className={["d-none", "d-md-block", "col-md-7", "leftCol"].join(
              " "
            )}
          />
        </div>
      </div>
    );
  }
}
