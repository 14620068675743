export const formatDate = {
  month: (timeStamp) => {
    const date = new Date(timeStamp * 1000);

    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const monthIndex = date.getMonth();

    return monthNames[monthIndex];
  },

  short: (timeStamp) => {
    const date = new Date(timeStamp * 1000);

    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    return monthNames[monthIndex] + " " + year;
  },

  long: (timeStamp) => {
    const date = new Date(timeStamp * 1000);

    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    const time = new Date(timeStamp * 1000).toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    });

    return monthNames[monthIndex] + " " + day + ", " + year + " " + time;
  },

  longShort: (timeStamp) => {
    const date = new Date(timeStamp * 1000);

    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    return monthNames[monthIndex] + " " + day + ", " + year;
  },
};
