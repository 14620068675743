import React, { Component } from "react";

import { Helmet } from "react-helmet";

import Window from "../../../../elements/window";

import { formatDate } from "../../../../../formatDate";

import request from "request";

import Slide1 from "./views/slide1";
import Slide2 from "./views/slide2";
import Slide3 from "./views/slide3";
import Slide4 from "./views/slide4";
import Slide5 from "./views/slide5";
import Slide7 from "./views/slide7";
import Slide8 from "./views/slide8";
import Slide9 from "./views/slide9";
import Slide10 from "./views/slide10";
import Slide11 from "./views/slide11";
import ExpirinOutletsModal from "./expiringOutletsModal/";

import "../../style.scss";

export default class User extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: "Dashboard" + " | " + "Chimera Solutions | Insights",
      fields: {
        sales: {
          data: [],
          state: false,
        },
        session: {
          data: [],
          state: false,
        },
        brands: {
          data: [],
          state: false,
        },
        chains: {
          data: [],
          state: false,
        },
        payType: {
          data: [],
          state: false,
        },
        revCent: {
          data: [],
          state: false,
        },
        group: {
          data: [],
          state: false,
        },
        currency: {
          base: {},
          state: false,
        },
        load: false,
      },
    };
  }

  getExpiringChains = (chains) => {
    const today = new Date();

    today.setHours(0, 0, 0);

    const oneMonthLaterDate = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      today.getDate()
    );

    const todayTS = parseInt(today.setDate(today.getDate()) / 1000);
    const oneMonthLaterTS = parseInt(
      oneMonthLaterDate.setDate(oneMonthLaterDate.getDate()) / 1000
    );

    const chainsWithExpiration = chains?.filter(
      (item) => item?.outletExp && item.outletExp <= oneMonthLaterTS
    );

    if (chainsWithExpiration.length == 0) {
      return [];
    }

    const chainsWithExpNote = chainsWithExpiration?.map((item) => {
      if (item.outletExp <= todayTS) {
        return {
          ...item,
          status: "expired",
          message: `${
            this.state?.fields?.brands?.data?.find(
              (brandItem) => brandItem.braKey == item.braKey
            )?.name
          } - ${item.name} : subscription has been expired since ${new Date(
            item.outletExp * 1000
          ).toDateString()}`,
        };
      }
      if (item.outletExp >= todayTS && item.outletExp <= oneMonthLaterTS) {
        return {
          ...item,
          status: "expiring",
          message: `${
            this.state?.fields?.brands?.data?.find(
              (brandItem) => brandItem.braKey == item.braKey
            )?.name
          } - ${item.name} : subscription will expire on ${new Date(
            item.outletExp * 1000
          ).toDateString()}`,
        };
      }
    });

    return chainsWithExpNote;
  };

  renderTitle = () => (
    <Helmet>
      <title>{this.state.title}</title>
    </Helmet>
  );

  getCurrency(headers, callback) {
    request(
      {
        method: "GET",
        url: this.props.chimeraserv.urlGet + "/group",
        headers: headers,
      },
      (error, response, body) => {
        let data = [];

        if (Object.keys(JSON.parse(body)).length) {
          data = JSON.parse(body);
        }

        callback(data);
      }
    );
  }

  getBrand(headers, callback) {
    request(
      {
        method: "GET",
        url: this.props.chimeraserv.urlGet + "/brand",
        headers: headers,
      },
      (error, response, body) => {
        let data = [];

        if (Object.keys(JSON.parse(body)).length) {
          data = JSON.parse(body);
        }

        callback(data);
      }
    );
  }

  getChain(headers, callback) {
    request(
      {
        method: "GET",
        url: this.props.chimeraserv.urlGet + "/chain",
        headers: headers,
      },
      (error, response, body) => {
        let data = [];

        if (Object.keys(JSON.parse(body)).length) {
          data = JSON.parse(body);
        }

        callback(data);
      }
    );
  }

  getPaytype(headers, callback) {
    request(
      {
        method: "GET",
        url: this.props.chimeraserv.urlGet + "/paytype",
        headers: headers,
      },
      (error, response, body) => {
        let data = [];

        if (Object.keys(JSON.parse(body)).length) {
          data = JSON.parse(body);
        }

        callback(data);
      }
    );
  }

  getRevcent(headers, callback) {
    request(
      {
        method: "GET",
        url: this.props.chimeraserv.urlGet + "/revcent",
        headers: headers,
      },
      (error, response, body) => {
        let data = [];

        if (Object.keys(JSON.parse(body)).length) {
          data = JSON.parse(body);
        }

        callback(data);
      }
    );
  }

  getGroup(headers, callback) {
    request(
      {
        method: "GET",
        url: this.props.chimeraserv.urlGet + "/item",
        headers: headers,
      },
      (error, response, body) => {
        let data = [];

        if (Object.keys(JSON.parse(body)).length) {
          data = JSON.parse(body);
        }

        callback(data);
      }
    );
  }

  getSales(headers, callback) {
    request(
      {
        method: "GET",
        url: this.props.chimeraserv.urlGet + "/sales/today",
        headers: headers,
      },
      (error, response, body) => {
        //sessions returned are of status:0 already
        const { groups, branches, chains, sessions, sales } = JSON.parse(body);
        let allSales = [];
        let data = [];
        for (const branch of branches) {
          for (const chain of chains) {
            const openSessions = sessions.filter((session) => {
              if (
                String(session.braKey) === String(branch) &&
                String(session.chaKey) === String(chain)
              ) {
                return session;
              }
            });

            const sessionNumberList = openSessions.map(
              (openSession) => openSession.sesNo
            );

            const openSessionSales = sales.filter((sale) => {
              if (
                String(sale.braKey) === String(branch) &&
                String(sale.chaKey) === String(chain) &&
                sessionNumberList.includes(sale.sesNo)
              ) {
                return sale;
              }
            });

            allSales = allSales.concat(openSessionSales);
          } //end of chain for loop
        } //end of branch for loop

        if (Object.keys(allSales).length) {
          data = allSales;
        }

        callback(data);
      }
    );
  }

  getSessions(headers, callback) {
    request(
      {
        method: "GET",
        url: this.props.chimeraserv.urlGet + "/session/all/today",
        headers: headers,
      },
      (error, response, body) => {
        let data = [];

        if (Object.keys(JSON.parse(body)).length) {
          data = JSON.parse(body);
        }

        callback(data);
      }
    );
  }

  UNSAFE_componentWillMount() {
    if (this.props.user.data) {
      const headers = {
        Authorization: this.props.user.data.usrKey,
        "Content-Type": "application/json",
        Accept: "application/json",
      };

      this.getCurrency(headers, (data) => {
        const array = [];

        const currency = data
          .map((group) => group.baseCurr)
          .filter((currency) => !array.includes(currency));

        this.setState((prevState) => ({
          fields: {
            ...prevState.fields,
            currency: {
              base: currency[0] ? currency[0]?.toUpperCase() : "QAR",
              state: true,
            },
          },
        }));
      });

      this.getBrand(headers, (data) => {
        this.setState((prevState) => ({
          fields: {
            ...prevState.fields,
            brands: {
              data: data,
              state: true,
            },
          },
        }));
      });

      this.getChain(headers, (data) => {
        this.setState((prevState) => ({
          fields: {
            ...prevState.fields,
            chains: {
              data: data,
              state: true,
            },
          },
        }));
      });

      this.getRevcent(headers, (data) => {
        this.setState((prevState) => ({
          fields: {
            ...prevState.fields,
            revCent: {
              data: data,
              state: true,
            },
          },
        }));
      });

      this.getPaytype(headers, (data) => {
        this.setState((prevState) => ({
          fields: {
            ...prevState.fields,
            payType: {
              data: data,
              state: true,
            },
          },
        }));
      });

      this.getSales(headers, (data) => {
        this.setState((prevState) => ({
          fields: {
            ...prevState.fields,
            sales: {
              data: data,
              state: true,
            },
          },
        }));
      });

      this.getSessions(headers, (data) => {
        this.setState((prevState) => ({
          fields: {
            ...prevState.fields,
            session: {
              data: data,
              state: true,
            },
          },
        }));
      });

      this.getGroup(headers, (data) => {
        this.setState((prevState) => ({
          fields: {
            ...prevState.fields,
            group: {
              data: data,
              state: true,
            },
          },
        }));
      });
    }
  }

  blockFilter() {
    if (
      !this.state.fields.load &&
      this.state.fields.session.state &&
      this.state.fields.payType.state &&
      this.state.fields.revCent.state &&
      this.state.fields.chains.state &&
      this.state.fields.brands.state &&
      this.state.fields.group.state &&
      this.state.fields.sales.state &&
      this.state.fields.currency.state
    ) {
      this.setState((prevState) => ({
        fields: {
          ...prevState.fields,
          load: true,
        },
      }));
    }
  }

  render() {
    const date = new Date().getTime();
    return (
      <section>
        {this.renderTitle()}
        <div className={["viewTitle"]}>
          Dashboard <span className={"italic"}>(Today)</span>{" "}
          <span>(updated last: {formatDate.long(date / 1000)})</span>
          {this.getExpiringChains(this.state?.fields?.chains?.data)?.length >
            0 && (
            <ExpirinOutletsModal
              expiringChains={this.getExpiringChains(
                this.state?.fields?.chains?.data
              )}
            />
          )}
        </div>
        <div className={["viewBody"]}>
          <div className={["report"]}>
            {this.blockFilter()}
            <Window
              user={this.props.user}
              chimeraserv={this.props.chimeraserv}
              title={"Sales Summary"}
            >
              <Slide1
                data={this.state.fields.load ? this.state.fields : null}
              />
            </Window>
            <div className={["contentContainer"]}>
              <div className={["contentRow"]} style={{ width: "32.75%" }}>
                <Window
                  user={this.props.user}
                  chimeraserv={this.props.chimeraserv}
                  title={"Total Customers"}
                  horizontal
                >
                  <Slide7
                    data={this.state.fields.load ? this.state.fields : null}
                  />
                </Window>
              </div>
              <div className={["contentRow"]} style={{ width: "32.75%" }}>
                <Window
                  user={this.props.user}
                  chimeraserv={this.props.chimeraserv}
                  title={"Total Checks"}
                  horizontal
                >
                  <Slide8
                    data={this.state.fields.load ? this.state.fields : null}
                  />
                </Window>
              </div>
              <div className={["contentRow"]} style={{ width: "32.75%" }}>
                <Window
                  user={this.props.user}
                  chimeraserv={this.props.chimeraserv}
                  title={"Total Sales"}
                  horizontal
                >
                  <Slide9
                    data={this.state.fields.load ? this.state.fields : null}
                  />
                </Window>
              </div>
            </div>
            <div className={["contentContainer"]}>
              <div className={["contentRow"]} style={{ width: "49.55%" }}>
                <Window
                  user={this.props.user}
                  chimeraserv={this.props.chimeraserv}
                  title={"Revenue Center"}
                  horizontal
                >
                  <Slide2
                    data={this.state.fields.load ? this.state.fields : null}
                  />
                </Window>
              </div>
              <div className={["contentRow"]} style={{ width: "49.55%" }}>
                <Window
                  user={this.props.user}
                  chimeraserv={this.props.chimeraserv}
                  title={"Payment Type"}
                  horizontal
                >
                  <Slide3
                    data={this.state.fields.load ? this.state.fields : null}
                  />
                </Window>
              </div>
            </div>
            <Window
              user={this.props.user}
              chimeraserv={this.props.chimeraserv}
              title={"Sales by Group Items"}
            >
              <Slide10
                data={this.state.fields.load ? this.state.fields : null}
              />
            </Window>
            <div className={["contentContainer"]}>
              <div className={["contentRow"]} style={{ width: "32.75%" }}>
                <Window
                  user={this.props.user}
                  chimeraserv={this.props.chimeraserv}
                  title={"Sales Statistics"}
                  horizontal
                >
                  <Slide4
                    data={this.state.fields.load ? this.state.fields : null}
                  />
                </Window>
              </div>
              <div className={["contentRow"]} style={{ width: "32.75%" }}>
                <Window
                  user={this.props.user}
                  chimeraserv={this.props.chimeraserv}
                  title={"Top 10 Selling Items"}
                  horizontal
                >
                  <Slide5
                    data={this.state.fields.load ? this.state.fields : null}
                  />
                </Window>
              </div>
              <div className={["contentRow"]} style={{ width: "32.75%" }}>
                <Window
                  user={this.props.user}
                  chimeraserv={this.props.chimeraserv}
                  title={"Hourly Sales"}
                  horizontal
                >
                  <Slide11
                    data={this.state.fields.load ? this.state.fields : null}
                  />
                </Window>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
