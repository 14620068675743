import React, { Component } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { exportPdf } from "../../exportPdf";
import { exportExcel } from "../../exportExcel";

export default class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reSize: false,
      style: {},
      data: {},
      export: new Date().getTime(),
      exportType: null,
    };
  }

  reSize = () => this.setState({ reSize: this.state.reSize ? false : true });

  UNSAFE_componentWillMount() {
    if (this.props.style) {
      this.setState({
        style: this.props.style,
      });
    }
  }

  pdfExport = () =>
    this.setState({
      export: new Date().getTime(),
      exportType: "pdf",
    });

  excelExport = () =>
    this.setState({
      export: new Date().getTime(),
      exportType: "excel",
    });

  getData = (data) =>
    this.setState(
      {
        data: data,
      },
      () => {
        if (this.state.exportType === "pdf") {
          switch (data.type) {
            case "image":
              exportPdf.image(this.state.data);
              break;
            case "table":
              exportPdf.table(this.state.data);
              break;
            default:
              break;
          }
        }
      }
    );

  renderdata = (data) => {
    this.setState({
      exportType: null,
    });

    return exportExcel(data);
  };

  render() {
    return (
      <div>
        {this.state.exportType === "excel" &&
        Object.entries(this.state.data).length
          ? this.renderdata(this.state.data)
          : null}
        <div
          className={[
            "window",
            this.props.horizontal ? "windowHorizontal" : "",
          ].join(" ")}
        >
          <div className={["header"]}>
            <div className={["heading"]}>
              <button onClick={() => this.reSize()}>
                <FontAwesomeIcon
                  className={["icon"].join(" ")}
                  icon={[
                    "fas",
                    this.state.reSize ? "plus-square" : "minus-square",
                  ]}
                />
              </button>
              <h2>{this.props.title}</h2>
            </div>
            <div className={"export"}>
              <button onClick={() => this.excelExport()}>
                <FontAwesomeIcon
                  className={["icon"].join(" ")}
                  icon={["fas", "file-excel"]}
                />
                Export to Excel
              </button>
              <button onClick={() => this.pdfExport()}>
                <FontAwesomeIcon
                  className={["icon"].join(" ")}
                  icon={["fas", "file-pdf"]}
                />
                Export to PDF
              </button>
            </div>
          </div>
          {this.props.horizontal ? (
            <div
              className={["winHori", this.state.reSize ? "hide" : ""].join(" ")}
            >
              <div className={["winBody"].join(" ")}>
                {React.cloneElement(this.props.children, {
                  export: this.state.export,
                  getData: this.getData,
                })}
              </div>
            </div>
          ) : (
            <div
              className={["winBody", this.state.reSize ? "hide" : ""].join(" ")}
            >
              {React.cloneElement(this.props.children, {
                export: this.state.export,
                getData: this.getData,
              })}
            </div>
          )}
        </div>
      </div>
    );
  }
}
