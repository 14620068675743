import React, { Component } from "react";
import ReactTable from "react-table-v6";
import { Link } from "react-router-dom";
import { formatDate } from "../../../../../../formatDate";
import commaNumber from "comma-number";
import Loader from "../../../../../../settings/loader";
import "react-table-v6/react-table.css";
import "./style.scss";

export default class Slider4 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        result: null,
        table: [],
      },
      load: 0,
    };
  }

  _initialized() {
    if (this.props.data && !this.state.load) {
      this.setState((prevstate) => ({
        data: {
          ...prevstate.data,
          result: {
            ...this.props.filter.data,
            ...this.props.data,
          },
        },
        load: 1,
      }));
    }
  }

  loadData() {
    if (this.state.load) {
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          table: this.state.data.result.deletion.data
            .filter((data) => data.items && data.items.length)
            .sort(this.sortSales)
            .map((data) => {
              let chain = this.state.data.result.chains.find(
                (chain) => chain.chaKey === data.chaKey
              );
              let brand = this.state.data.result.brands.find(
                (brand) => brand.braKey === data.braKey
              );

              return {
                ...data,
                businessName: brand.name + " - " + chain.name,
                currency: chain?.currency?.toUpperCase(),
              };
            }),
        },
        load: 2,
      }));
    }
  }

  sortSales(a, b) {
    if (parseInt(a.date) > parseInt(b.date)) {
      return -1;
    }

    if (parseInt(a.date) < parseInt(b.date)) {
      return 1;
    }

    return 0;
  }

  renderLink = (component) => (
    <Link
      to={{
        pathname: `/${this.props.location.pathname.split("/")[1]}/view/${
          this.props.location.pathname.split("/")[3]
        }/${component.data._id}`,
        prevUrl: this.props.location.pathname,
      }}
    >
      {component.value}
    </Link>
  );

  componentDidUpdate = (prevProps) => {
    if (prevProps.export !== this.props.export) {
      this.props.getData({
        type: "table",
        title: "Deletion Summary",

        filter: {
          brands: this.props.filter.data.brands.map((brand) => brand.name),
          chains: this.props.filter.data.chains.map((chain) => chain.name),
          revCent: this.props.filter.data.revCent.map(
            (revCent) => revCent.name
          ),
          payType: this.props.filter.data.payType.map(
            (payType) => payType.name
          ),
          date: {
            start: this.props.filter.data.date.start,
            end: this.props.filter.data.date.end,
          },
        },

        body: [
          {
            head: [["Bill No", "Item", "Total", "Date", "Business Name"]],
            body: this.state.data.table.map((value) => [
              value.billNo,
              value.items[0] ? value.items[0].name : "",
              `${value?.currency} ${commaNumber(value.total.toFixed(2))}`,
              formatDate.long(value.date),
              value.businessName,
            ]),
          },
        ],
      });
    }
  };

  render() {
    switch (this.state.load) {
      case 0:
        this._initialized();
        break;
      case 1:
        this.loadData();
        break;
      default:
        break;
    }

    let uniqueNames;

    if (this.state.data.table) {
      uniqueNames = [
        ...new Set(this.state.data.table.map((table) => table.businessName)),
      ];
    }

    const columns = [
      {
        Header: "Business Name",
        accessor: "businessName",
        filterMethod: (filter, row) => {
          if (filter.value === "all") {
            return true;
          }

          if (row[filter.id] === filter.value) {
            return row[filter.id];
          }
        },
        Filter: ({ filter, onChange }) => {
          return (
            <select
              onChange={(event) => onChange(event.target.value)}
              style={{ width: "100%" }}
              value={filter ? filter.value : "all"}
            >
              <option value="all">All</option>
              {uniqueNames.map((name) => (
                <option value={name}>{name}</option>
              ))}
            </select>
          );
        },
        Cell: (row) => {
          return <this.renderLink data={row.row._original} value={row.value} />;
        },
      },
      {
        Header: "Bill No.",
        accessor: "billNo",
        Cell: (row) => {
          return <this.renderLink data={row.row._original} value={row.value} />;
        },
      },
      {
        Header: "Item",
        accessor: "items",
        filterMethod: (filter, row) => {
          const id = filter.pivotId || filter.id;
          return row[id][0].name !== undefined
            ? String(row[id][0].name.toLowerCase()).includes(
                filter.value.toLowerCase()
              )
            : true;
        },
        Filter: ({ filter, onChange }) => {
          return (
            <input
              type="text"
              onChange={(event) => onChange(event.target.value)}
              style={{ width: "100%" }}
              value={filter ? filter.value : ""}
            />
          );
        },
        Cell: (row) => {
          return (
            <this.renderLink
              data={row.row._original}
              value={row.value[0] ? row.value[0].name : ""}
            />
          );
        },
      },
      {
        Header: "Total",
        accessor: "total",
        Cell: (row) => {
          return `${row?.original?.currency} ${commaNumber(
            row.value.toFixed(2)
          )}`;
        },
      },
      {
        Header: "Date",
        accessor: "date",
        Cell: (row) => {
          return (
            <this.renderLink
              data={row.row._original}
              value={formatDate.long(row.value)}
            />
          );
        },
      },
    ];
    return (
      <div>
        {!this.state.load ? (
          <Loader />
        ) : (
          <ReactTable
            className={["-striped", "-highlight"].join(" ")}
            data={this.state.data.table}
            columns={columns}
            sortable
            filterable
            getTdProps={() => ({
              style: {
                textAlign: "center",
              },
            })}
          />
        )}
      </div>
    );
  }
}
