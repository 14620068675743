import React, { Component } from "react";

import { Link } from "react-router-dom";

import { Helmet } from "react-helmet";

import request from "request";

import "../style.scss";

import { formatDate } from "../../../formatDate";
import Loader from "../../../settings/loader";

export default class Account extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: "My Account" + " | " + "Chimera Solutions | Insights",
      data: [],
      load: false,
    };

    this.headers = {
      Authorization: this.props.user.data.usrKey,
      "Content-Type": "application/json",
      Accept: "application/json",
    };
  }

  renderTitle = () => (
    <Helmet>
      <title>{this.state.title}</title>
    </Helmet>
  );

  loadData = () =>
    request(
      {
        method: "GET",
        url: this.props.chimeraserv.urlGet + "/user/" + this.props.user.data.id,
        headers: this.headers,
      },
      (error, response, body) => {
        let result = JSON.parse(body);

        this.setState((prevState) => ({
          data: result,
          load: true,
        }));
      }
    );

  UNSAFE_componentWillMount = () => {
    this._initial = this.loadData();
  };

  componentWillUnmount = () => {
    this.getData = null;
  };

  render() {
    return (
      <div className={["viewContainer", "account"].join(" ")}>
        <div className={["custForm", "custView"].join(" ")}>
          {this.renderTitle()}
          <div className={["viewTitle"]}>Account</div>

          {!this.state.load ? (
            <Loader />
          ) : (
            <div className={["account", "form"].join(" ")}>
              {this.state.data.map((data, key) => (
                <div>
                  <div className={["form-group"]}>
                    <label htmlFor={["exampleInputEmail1"]}>Username</label>
                    <div className={["field", "value"].join(" ")}>
                      {data.username}
                    </div>
                  </div>
                  <div className={["form-group"]}>
                    <label htmlFor={["exampleInputEmail1"]}>Email</label>
                    <div className={["field", "value"].join(" ")}>
                      {data.email}
                    </div>
                  </div>
                  <div className={["form-group"]}>
                    <label htmlFor={["exampleInputEmail1"]}>Modified</label>
                    <div className={["field"]}>
                      {data.modified
                        ? formatDate.long(data.modified / 1000)
                        : ""}
                    </div>
                  </div>
                  <Link
                    to={
                      this.props.match.path.split("/").splice(0, 2).join("/") +
                      "/user/edit-account/" +
                      data._id
                    }
                    className={["btn btn-primary"]}
                  >
                    Edit
                  </Link>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  }
}
