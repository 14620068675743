import React, { Component } from "react";

import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./style.scss";

export default class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menu: [
        {
          name: "Dashboard",
          icon: "tachometer-alt",
          path:
            (this.props.match.path === "/" ? "/app" : this.props.match.path) +
            "/dashboard",
        },
        // {
        // 	name: "Calendar",
        // 	icon: "table",
        // 	path: (this.props.match.path === "/" ? "/app" : this.props.match.path) + "/calendar",
        // 	level: [1,2,3,4,5,6,7,8,9],
        // 	auth: true,
        // 	user: ["demo", "user"],
        // 	items: {
        // 		state: false,
        // 		list: [{
        // 				name: "By Week",
        // 				icon: "calendar-week",
        // 				path: (this.props.match.path === "/" ? "/app" : this.props.match.path) + "/calendar/weekly",
        // 				level: [1,2,3,4,5,6,7,8,9],
        // 				auth: true,
        // 				user: ["demo", "user"],
        // 			},{
        // 				name: "By Month",
        // 				icon: "calendar-day",
        // 				path: (this.props.match.path === "/" ? "/app" : this.props.match.path) + "/calendar/monthly",
        // 				level: [1,2,3,4,5,6,7,8,9],
        // 				auth: true,
        // 				user: ["demo", "user"],
        // 			},{
        // 				name: "By Year",
        // 				icon: "calendar-alt",
        // 				path: (this.props.match.path === "/" ? "/app" : this.props.match.path) + "/calendar/yearly",
        // 				level: [1,2,3,4,5,6,7,8,9],
        // 				auth: true,
        // 				user: ["demo", "user"],
        // 			},
        // 		]
        // 	}
        // },
        {
          name: "Sales",
          icon: "funnel-dollar",
          path:
            (this.props.match.path === "/" ? "/app" : this.props.match.path) +
            "/sales",
          level: [1, 2, 3, 4, 5, 6, 7, 8, 9],
          auth: true,
          user: ["admin", "demo", "user"],
          items: {
            state: false,
            list: [
              {
                name: "Group by Sales Summary",
                icon: "dollar-sign",
                path:
                  (this.props.match.path === "/"
                    ? "/app"
                    : this.props.match.path) + "/sales/summary",
                level: [1, 2, 3, 4, 5, 6, 7, 8, 9],
                auth: true,
                user: ["demo", "user"],
              },
              {
                name: "Group by Session Summary",
                icon: "clock",
                path:
                  (this.props.match.path === "/"
                    ? "/app"
                    : this.props.match.path) + "/sales/session",
                level: [1, 2, 3, 4, 5, 6, 7, 8, 9],
                auth: true,
                user: ["demo", "user"],
              },
              {
                name: "Group by Revenue Center",
                icon: "comments-dollar",
                path:
                  (this.props.match.path === "/"
                    ? "/app"
                    : this.props.match.path) + "/sales/revcent",
                level: [1, 2, 3, 4, 5, 6, 7, 8, 9],
                auth: true,
                user: ["demo", "user"],
              },
              {
                name: "Group by Payment Type",
                icon: "money-bill",
                path:
                  (this.props.match.path === "/"
                    ? "/app"
                    : this.props.match.path) + "/sales/paytype",
                level: [1, 2, 3, 4, 5, 6, 7, 8, 9],
                auth: true,
                user: ["demo", "user"],
              },
              {
                name: "Group by Sales Checks",
                icon: "receipt",
                path:
                  (this.props.match.path === "/"
                    ? "/app"
                    : this.props.match.path) + "/sales/checks",
                level: [1, 2, 3, 4, 5, 6, 7],
                auth: true,
                user: ["demo", "user"],
              },
              {
                name: "Group Items by Sales",
                icon: "shopping-basket",
                path:
                  (this.props.match.path === "/"
                    ? "/app"
                    : this.props.match.path) + "/sales/item",
                level: [1, 2, 3, 4, 5, 6, 7, 8, 9],
                auth: true,
                user: ["demo", "user"],
              },
              {
                name: "Group Sales by Group Items",
                icon: "shopping-basket",
                path:
                  (this.props.match.path === "/"
                    ? "/app"
                    : this.props.match.path) + "/sales/group",
                level: [1, 2, 3, 4, 5, 6, 7, 8, 9],
                auth: true,
                user: ["demo", "user"],
              },
              {
                name: "Hourly Sales",
                icon: "clock",
                path:
                  (this.props.match.path === "/"
                    ? "/app"
                    : this.props.match.path) + "/sales/hourly",
                level: [1, 2, 3, 4, 5, 6, 7, 8, 9],
                auth: true,
                user: ["demo", "user"],
              },
            ],
          },
        },
        {
          name: "Audit",
          icon: "clipboard-list",
          path:
            (this.props.match.path === "/" ? "/app" : this.props.match.path) +
            "/audit",
          level: [1, 2, 3, 4, 5, 6, 7, 8, 9],
          auth: true,
          user: ["admin", "demo", "user"],
          items: {
            state: false,
            list: [
              {
                name: "Audit Summary",
                icon: "list-alt",
                path:
                  (this.props.match.path === "/"
                    ? "/app"
                    : this.props.match.path) + "/audit/logs",
                level: [1, 2, 3, 4, 5, 6, 7, 8, 9],
                auth: true,
                user: ["demo", "user"],
              },
              {
                name: "Void Summary",
                icon: "ban",
                path:
                  (this.props.match.path === "/"
                    ? "/app"
                    : this.props.match.path) + "/audit/voids",
                level: [1, 2, 3, 4, 5, 6, 7, 8, 9],
                auth: true,
                user: ["demo", "user"],
              },
              {
                name: "Deletion Summary",
                icon: "trash-alt",
                path:
                  (this.props.match.path === "/"
                    ? "/app"
                    : this.props.match.path) + "/audit/deletion",
                level: [1, 2, 3, 4, 5, 6, 7, 8, 9],
                auth: true,
                user: ["demo", "user"],
              },
            ],
          },
        },
        {
          name: "Manage Accounts",
          icon: "layer-group",
          path:
            (this.props.match.path === "/" ? "/app" : this.props.match.path) +
            "/group",
          auth: true,
          level: [1],
          user: ["admin"],
          items: {
            state: false,
            list: [
              {
                name: "Manage Groups",
                icon: "briefcase",
                path:
                  (this.props.match.path === "/"
                    ? "/app"
                    : this.props.match.path) + "/group/main",
                auth: true,
                level: [1],
                user: ["admin"],
              },
              {
                name: "Manage Brands",
                icon: "store",
                path:
                  (this.props.match.path === "/"
                    ? "/app"
                    : this.props.match.path) + "/group/brand",
                auth: true,
                level: [1],
                user: ["admin"],
              },
              {
                name: "Manage Outlets",
                icon: "tags",
                path:
                  (this.props.match.path === "/"
                    ? "/app"
                    : this.props.match.path) + "/group/outlet",
                auth: true,
                level: [1],
                user: ["admin"],
              },
            ],
          },
        },
        {
          name: "Manage Users",
          icon: "users",
          path:
            (this.props.match.path === "/" ? "/app" : this.props.match.path) +
            "/user",
          auth: true,
          level: [1],
          user: ["admin"],
        },
        {
          name: "PDF Reports",
          icon: "copy",
          path:
            (this.props.match.path === "/" ? "/app" : this.props.match.path) +
            "/reports",
          level: [1, 2, 3, 4, 5, 6, 7, 8, 9],
          auth: true,
          user: ["admin", "demo", "user"],
        },
      ],
      toggle: false,
      page: "Today",
    };
  }

  toggleSide() {
    this.setState({
      toggle: this.state.toggle ? false : true,
    });
  }

  toggleMenu(menu) {
    const initialMenu = this.state.menu.map((list) => {
      if (list.items) {
        return {
          ...list,
          items: {
            ...list.items,
            state: false,
          },
        };
      }

      return list;
    });

    this.setState({
      menu: initialMenu,
      page: menu.name,
    });
  }

  toggleItem(menu) {
    this.setState({
      page: menu.name,
    });
  }

  toggleSub(menu) {
    const initialMenu = this.state.menu.map((list) => {
      if (list.name === menu.name) {
        return {
          ...list,
          items: {
            ...list.items,
            state: list.items.state ? false : true,
          },
        };
      } else {
        if (list.items) {
          return {
            ...list,
            items: {
              ...list.items,
              state: false,
            },
          };
        }

        return list;
      }
    });

    this.setState((prevState) => ({
      menu: initialMenu,
      page: menu.name,
    }));
  }

  componentDidMount() {
    this.state.menu.map((menu) => {
      if (
        menu.path ===
        this.props.location.pathname.split("/").slice(0, 3).join("/")
      ) {
        this.setState({ page: menu.name });
      }

      return true;
    });
  }

  renderSubList = (menu, index) => {
    if (
      this.props.user.data.type === "admin" ||
      (menu.level &&
        menu.level.find((user) => user === this.props.user.data.level))
    ) {
      return (
        <Link
          to={menu.path}
          key={index}
          className={[
            "subLink",
            menu.name === this.state.page ? "subLinkSelected" : "",
          ].join(" ")}
          onClick={() => this.toggleItem(menu)}
        >
          <FontAwesomeIcon
            className={["icon"].join(" ")}
            icon={["fas", menu.icon]}
          />
          <div className={["text"]}>{menu.name}</div>
        </Link>
      );
    }

    return null;
  };

  renderList = (menu) => (
    <div
      className={[
        "link",
        menu.name === this.state.page || (menu.items && menu.items.state)
          ? "linkSelected"
          : "",
      ].join(" ")}
    >
      <div className={["menuContainer"]}>
        {menu.items ? (
          <div className={["dropDown"]}>
            <button onClick={() => this.toggleSub(menu)}>
              <div className={["dropCont"]}>
                <FontAwesomeIcon
                  className={["icon"].join(" ")}
                  icon={["fas", menu.icon]}
                />
                <div className={["text"]}>{menu.name}</div>
              </div>
              <FontAwesomeIcon
                className={["dropIcon"].join(" ")}
                icon={[
                  "fas",
                  menu.items && menu.items.state
                    ? "chevron-up"
                    : "chevron-down",
                ]}
              />
            </button>
          </div>
        ) : (
          <Link to={menu.path} onClick={() => this.toggleMenu(menu)}>
            <FontAwesomeIcon
              className={["icon"].join(" ")}
              icon={["fas", menu.icon]}
            />
            <div className={["text"]}>{menu.name}</div>
          </Link>
        )}
      </div>
      <div
        className={[
          "subList",
          menu.items && menu.items.state ? "subListActive" : "",
        ].join(" ")}
      >
        {menu.items && menu.items.state ? (
          menu.items.list.map((items, key) => this.renderSubList(items, key))
        ) : (
          <div />
        )}
      </div>
    </div>
  );

  render() {
    return (
      <nav className={["sidebar"]}>
        <div className={["toggle"]}>
          <div className={"title"}>
            <h1>POS Insights</h1>
          </div>
          <div className={[this.state.toggle ? "toggleSelect" : ""]}>
            <button
              onClick={() => {
                this.props.toggle();
                this.toggleSide();
              }}
            >
              <FontAwesomeIcon
                className={["icon"].join(" ")}
                icon={["fas", "bars"]}
              />
            </button>
          </div>
        </div>
        <ul className={["sidebarLink"]}>
          {this.state.menu.map((menu, key) => {
            if (menu.auth && this.props.user.data) {
              if (
                menu.user.find((user) => user === this.props.user.data.type)
              ) {
                if (
                  this.props.user.data.type === "admin" ||
                  (menu.level &&
                    menu.level.find(
                      (user) => user === this.props.user.data.level
                    ))
                ) {
                  return <li key={key}>{this.renderList(menu)}</li>;
                }
              }

              return <li key={key} />;
            }

            return <li key={key}>{this.renderList(menu)}</li>;
          })}
        </ul>
      </nav>
    );
  }
}
