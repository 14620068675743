import React, { Component } from "react";

import { formatDate } from "../../../../../../formatDate";

import request from "request";

import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class ListBrand extends Component {
  constructor(props) {
    super(props);

    this.state = {
      table: {
        head: [
          "Status",
          "Created At",
          "Last Update",
          "Group",
          "Brand",
          "Outlet",
          "Actions",
        ],
        data: false,
      },
    };
  }

  valueChange = (groupData, group, brand, chain) => {
    let groupIndex = groupData.brands
      .map((brands, index) => ({
        ...brands,
        chains: brands.chains
          .map((chains, key) => ({ ...chains, index: key }))
          .find((chains) => chains.chaKey === chain.chaKey),
        index: index,
      }))
      .find((brands) => brands.braKey === chain.brand.braKey);

    groupData.brands[groupIndex.index].chains[groupIndex.chains.index].status =
      groupData.brands[groupIndex.index].chains[groupIndex.chains.index]
        .status === 1
        ? 0
        : 1;

    let data = {
      ...groupData,
    };

    request(
      {
        method: "PUT",
        url: this.props.chimeraserv.urlPost + "/group/update",
        headers: {
          Authorization: this.props.user.data.usrKey,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: data,
        json: true,
      },
      (error, response, body) => false
    );
  };

  sort = (a, b) => {
    if (parseInt(a.info.status) < parseInt(b.info.status)) {
      return -1;
    }

    if (parseInt(a.info.status) > parseInt(b.info.status)) {
      return 1;
    }

    return 0;
  };

  renderData = () =>
    [].concat
      .apply(
        [],
        [].concat.apply(
          [],
          this.state.table.data.map((data, dataKey) =>
            data.brands.map((brand, braKey) =>
              brand.chains.map((chain) => ({
                data: data,
                group: {
                  index: dataKey,
                  grpKey: data.grpKey,
                  _id: data._id,
                  name: data.name,
                },
                brand: {
                  index: braKey,
                  braKey: brand.braKey,
                  name: brand.name,
                },
                ...chain,
              }))
            )
          )
        )
      )
      .sort(this.sort)
      .map((chain, key) => (
        <tr key={key}>
          <td>
            <div className={"name"}>
              <div
                className={["status", chain.info.status ? "" : "offline"].join(
                  " "
                )}
              />
              {chain.info.status ? "Online" : "Offline"}
            </div>
          </td>
          <td>{formatDate.long(chain.info.firstUpdate)}</td>
          <td>{formatDate.long(chain.info.lastUpdate)}</td>
          <td>{chain.group.name}</td>
          <td>{chain.brand.name}</td>
          <td>{chain.name}</td>
          <td className={["actions"]}>
            {this.props.user.data.level === 0 ? (
              <div className={["actions", "actionsAdmin"].join(" ")}>
                <div className={["custom-control", "custom-switch"].join(" ")}>
                  <input
                    type={"checkbox"}
                    className={"custom-control-input"}
                    id={chain.chaKey}
                    defaultChecked={chain.status ? true : false}
                    onChange={() =>
                      this.valueChange(
                        chain.data,
                        chain.group,
                        chain.brand,
                        chain,
                        key
                      )
                    }
                  />
                  <label
                    className={"custom-control-label"}
                    htmlFor={chain.chaKey}
                  />
                </div>
                <Link
                  to={
                    this.props.match.path.split("/").splice(0, 4).join("/") +
                    "/edit/" +
                    chain.chaKey
                  }
                >
                  <FontAwesomeIcon
                    className={["icon"].join(" ")}
                    icon={["fas", "cog"]}
                  />{" "}
                  Edit
                </Link>
              </div>
            ) : (
              <div />
            )}

            <Link
              to={
                this.props.match.path.split("/").splice(0, 4).join("/") +
                "/view/" +
                chain.chaKey
              }
            >
              <FontAwesomeIcon
                className={["icon"].join(" ")}
                icon={["fas", "eye"]}
              />{" "}
              View
            </Link>
          </td>
        </tr>
      ));

  componentDidMount() {
    if (this.props.user.data) {
      request(
        {
          method: "GET",
          url: this.props.chimeraserv.urlGet + "/group",
          headers: {
            Authorization: this.props.user.data.usrKey, //dyanmic this
            "Contet-Type": "application/json",
            Accept: "application/json",
          },
        },
        (error, response, body) => {
          let data = [];

          if (Object.keys(JSON.parse(body)).length) {
            data = JSON.parse(body);
          }

          this.setState((prevState) => ({
            table: {
              ...prevState.table,
              data: data,
            },
          }));
        }
      );
    }
  }

  render() {
    let Loader = this.props.components.setting[1].component;

    return (
      <div className={"custForm"}>
        <div className={["viewTitle"]}>List of Outlets</div>
        <div>
          {!this.state.table.data ? (
            <Loader />
          ) : (
            <table
              className={["table", "table-striped", "defaultTable"].join(" ")}
            >
              <thead>
                <tr>
                  {this.state.table.head.map((head, key) => (
                    <th scope={"col"} key={key}>
                      {head}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>{this.renderData()}</tbody>
            </table>
          )}
        </div>
      </div>
    );
  }
}
