import React, { Component } from "react";

import { Route, Switch } from "react-router-dom";

import GroupView from "./views/group";
import Brand from "./views/brand";
import Outlet from "./views/outlet";

import "../style.scss";

export default class Group extends Component {
  constructor(props) {
    super(props);

    this.state = {
      routes: [
        {
          name: "Group",
          path: "/main/:page?/:id?",
          component: GroupView,
        },
        {
          name: "Brand",
          path: "/brand/:page?/:id?",
          component: Brand,
        },
        {
          name: "Outlet",
          path: "/outlet/:page?/:id?",
          component: Outlet,
        },
      ],
    };
  }

  render() {
    return (
      <div className={"viewContainer"}>
        <Switch>
          <Route
            exact
            path={this.props.match.path}
            render={(routerProps) => (
              <GroupView
                {...routerProps}
                user={this.props.user}
                chimeraserv={this.props.chimeraserv}
                components={this.props.components}
              />
            )}
          />

          {this.state.routes.map((route, key) => (
            <Route
              key={key}
              path={this.props.match.path + route.path}
              render={(routerProps) => (
                <route.component
                  {...routerProps}
                  user={this.props.user}
                  chimeraserv={this.props.chimeraserv}
                  components={this.props.components}
                />
              )}
            />
          ))}

          <Route component={this.props.components.setting[0].component} />
        </Switch>
      </div>
    );
  }
}
