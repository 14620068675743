import React, { Component } from "react";

import Loader from "../../../../../../settings/loader";

import commaNumber from "comma-number";

import "./style.scss";

export default class Slider4 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        result: null,
        table: [],
      },
      load: 0,
    };
  }

  _initialized() {
    if (this.props.data && !this.state.load) {
      this.setState((prevstate) => ({
        data: {
          ...prevstate.data,
          result: this.props.data,
        },
        load: 1,
      }));
    }
  }

  topParser(tableData) {
    let qtyArray = [];

    tableData.forEach((data, index) => {
      qtyArray.push(data.total);
    });

    return {
      max: Math.max(...qtyArray),
      ave: qtyArray[Math.floor((qtyArray.length - 1) / 2)],
      min: Math.min(...qtyArray),
    };
  }

  loadData() {
    if (this.state.load) {
      const payment = this.state.data.result.payType.data.map((data) => ({
        ...data,
        total: 0,
        qty: 0,
      }));

      this.state.data.result.sales.data.forEach((data, index) => {
        data.payType.forEach((pay, index) => {
          const queryPatype = payment.find(
            (storedPay) => storedPay.name === pay.name
          );

          if (!queryPatype) {
            payment.push({
              ...pay,
              total: pay.amount,
              qty: 1,
            });
          } else {
            queryPatype.total = queryPatype.total + pay.amount;
            queryPatype.qty = queryPatype.qty + 1;
          }
        });
      });

      let topParser = this.topParser(payment);

      let topTen = payment
        .filter(
          (data) => data.total <= topParser.max && data.total >= topParser.ave
        )
        .map((data, index) => data);

      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          table: topTen.filter((data) => data.total).sort(this.sortSales),
        },
        load: 2,
      }));
    }
  }

  sortSales(a, b) {
    if (parseInt(a.total) > parseInt(b.total)) {
      return -1;
    }

    if (parseInt(a.total) < parseInt(b.total)) {
      return 1;
    }

    return 0;
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.export !== this.props.export) {
      this.props.getData({
        type: "table",
        title: "Monthly - Payment Type",
        body: [
          {
            head: [["Payment Type", "Total Amount", "Qty"]],
            body: this.state.data.table.map((value) => [
              value.name,
              `${this.props.data.currency.base} ${commaNumber(
                value.total.toFixed(2)
              )}`,
              commaNumber(value.qty),
            ]),
          },
        ],
      });
    }
  };

  render() {
    switch (this.state.load) {
      case 0:
        this._initialized();
        break;
      case 1:
        this.loadData();
        break;
      default:
        break;
    }

    return (
      <div>
        {!this.state.load ? (
          <Loader />
        ) : (
          <table
            className={["table", "table-striped", "defaultTable"].join(" ")}
          >
            <thead>
              <tr>
                <th scope={"col"}>Payment Type</th>
                <th scope={"col"}>Amount</th>
                <th scope={"col"}>Qty</th>
              </tr>
            </thead>
            <tbody>
              {this.state.data.table.map((data, key) => (
                <tr key={key}>
                  <td>
                    {String(data.name)
                      .toLowerCase()
                      .split(/(?: - )|(?:-)/)
                      .map((string) =>
                        string
                          .split(/ /)
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.substring(1)
                          )
                          .join(" ")
                      )
                      .join(" ")}
                  </td>
                  <td>
                    {this.props.fields.currency.base}{" "}
                    {commaNumber(data.total.toFixed(2))}
                  </td>
                  <td>{commaNumber(data.qty)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    );
  }
}
