import React, { Component } from "react";

import { Route, Switch } from "react-router-dom";

import { Helmet } from "react-helmet";

import Monthly from "./views/monthly";
import Yearly from "./views/yearly";
import Weekly from "./views/weekly";

import { formatDate } from "../../../formatDate";

import "../../style.scss";

export default class Calendar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: null,
      routes: [
        {
          name: "Month",
          path: "/monthly",
          component: Monthly,
        },
        {
          name: "Year",
          path: "/yearly",
          component: Yearly,
        },
        {
          name: "Week",
          path: "/weekly",
          component: Weekly,
        },
      ],
    };
  }

  setFilter = (filters) => {
    this.setState({
      filter: null,
    });

    setTimeout(() => {
      this.setState({
        filter: filters,
        page: this.props.location.pathname,
      });
    }, 500);
  };

  checkPage() {
    if (this.props.location.pathname !== this.state.page) {
      setTimeout(() => {
        this.setState({
          filter: null,
          page: this.props.location.pathname,
        });
      }, 500);
    }
  }

  UNSAFE_componentWillMount() {
    this.setState({
      page: this.props.location.pathname,
    });
  }

  renderTitle = (title) => {
    let name = "Sales";

    if (title) {
      name = title.name + " | Sales";

      if (this.props.location.pathname.split("/").length === 3) {
        name = "Sales";
      }
    }

    return (
      <Helmet>
        <title>{name + " | Chimera Solutions | Insights"}</title>
      </Helmet>
    );
  };

  render() {
    const date = new Date().getTime();

    this.checkPage();

    let title = this.state.routes.find(
      (route) => route.path === "/" + this.props.location.pathname.split("/")[3]
    );

    if (this.props.location.pathname.split("/").length === 3) {
      title = { name: "Month" };
    }

    return (
      <section>
        {this.renderTitle(title)}
        <div className={["viewTitle"]}>
          {title ? title.name : <div />}{" "}
          <span>(last updated: {formatDate.longShort(date / 1000)})</span>
        </div>
        <div className={["calendar"]}>
          <div className={["report"]}>
            <Switch>
              <Route
                exact
                path={this.props.match.path}
                render={(routerProps) => (
                  <section>
                    <Monthly
                      user={this.props.user}
                      chimeraserv={this.props.chimeraserv}
                      filter={this.state.filter}
                    />
                  </section>
                )}
              />

              {this.state.routes.map((route, key) => (
                <Route
                  key={key}
                  path={this.props.match.path + route.path}
                  render={(routerProps) => (
                    <section>
                      <route.component
                        user={this.props.user}
                        chimeraserv={this.props.chimeraserv}
                        filter={this.state.filter}
                      />
                    </section>
                  )}
                />
              ))}

              <Route component={this.props.components.setting[0].component} />
            </Switch>
          </div>
        </div>
      </section>
    );
  }
}
