import React, { Component } from "react";

import commaNumber from "comma-number";

import Loader from "../../../../../../settings/loader";

import "./style.scss";

export default class Slider4 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        result: null,
        table: [],
      },
      load: 0,
    };
  }

  _initialized() {
    if (this.props.data && !this.state.load) {
      this.setState((prevstate) => ({
        data: {
          ...prevstate.data,
          result: this.props.data,
        },
        load: 1,
      }));
    }
  }

  topParser(tableData) {
    let qtyArray = [];

    tableData.forEach((data, index) => {
      qtyArray.push(data.qty);
    });

    return {
      max: Math.max(...qtyArray),
      ave: qtyArray[Math.floor((qtyArray.length - 1) / 2)],
      min: Math.min(...qtyArray),
    };
  }

  loadData() {
    if (this.state.load) {
      let items = [];

      this.state.data.result.sales.data.forEach((data, index) => {
        data.items.forEach((item, index) => {
          const queryItem = items.find(
            (storedItem) =>
              storedItem.refNo === item.refNo && storedItem.name === item.name
          );

          const chain = this.props.data.chains.data.find(
            (chain) => chain.chaKey === data.chaKey
          );
          const brand = this.props.data.brands.data.find(
            (brand) => brand.braKey === data.braKey
          );

          let qty = 1;

          if (item.qty) {
            qty = item.qty;
          }

          if (!queryItem) {
            items.push({
              ...item,
              qty: qty,
              total: item.amount,
              businessName: `${brand.name} - ${chain.name}`,
              currency: chain.currency?.toUpperCase(),
            });
          } else {
            queryItem.qty = queryItem.qty + qty;
            queryItem.total = queryItem.total + item.amount;
            queryItem.businessName = `${brand.name} - ${chain.name}`;
            queryItem.currency = chain.currency?.toUpperCase();
          }
        });
      });

      let topParser = this.topParser(items);

      let topTen = items
        .filter(
          (data) => data.qty <= topParser.max && data.qty >= topParser.ave
        )
        .map((data, index) => data);

      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          table: topTen.sort(this.sortSales).slice(0, 10),
        },
        load: 2,
      }));
    }
  }

  sortSales(a, b) {
    if (parseInt(a.qty) > parseInt(b.qty)) {
      return -1;
    }

    if (parseInt(a.qty) < parseInt(b.qty)) {
      return 1;
    }

    return 0;
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.export !== this.props.export) {
      this.props.getData({
        type: "table",
        title: "Total Checks",
        body: [
          {
            head: [["Item No.", "Name", "Qty.", "Sales", "Business Name"]],
            body: this.state.data.table.map((value) => [
              value.refNo,
              String(value.name)
                .toLowerCase()
                .split(/(?: - )|(?:-)/)
                .map((string) =>
                  string
                    .split(/ /)
                    .map(
                      (word) =>
                        word.charAt(0)?.toUpperCase() + word.substring(1)
                    )
                    .join(" ")
                )
                .join(" "),
              commaNumber(value.qty),
              `${value?.currency} ${commaNumber(value.total.toFixed(2))}`,
              value.businessName,
            ]),
          },
        ],
      });
    }
  };

  render() {
    switch (this.state.load) {
      case 0:
        this._initialized();
        break;
      case 1:
        this.loadData();
        break;
      default:
        break;
    }

    console.log("this.state.data.table", this.state.data.table);

    return (
      <div>
        {!this.state.load ? (
          <Loader />
        ) : (
          <table className={["table", "table-striped"].join(" ")}>
            <thead>
              <tr>
                <th scope={"col"}>Item No</th>
                <th scope={"col"}>Name</th>
                <th scope={"col"}>Qty.</th>
                <th scope={"col"}>Sales</th>
                <th scope={"col"}>Business Name</th>
              </tr>
            </thead>
            <tbody>
              {this.state.data.table.map((data, key) => (
                <tr key={key}>
                  <td>{data.refNo}</td>
                  <td>
                    {String(data.name)
                      .toLowerCase()
                      .split(/(?: - )|(?:-)/)
                      .map((string) =>
                        string
                          .split(/ /)
                          .map(
                            (word) =>
                              word.charAt(0)?.toUpperCase() + word.substring(1)
                          )
                          .join(" ")
                      )
                      .join(" ")}
                  </td>
                  <td>{data.qty}</td>
                  <td>
                    {data?.currency} {data?.amount.toFixed(2) * data?.qty}
                  </td>
                  <td>{data.businessName}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    );
  }
}
