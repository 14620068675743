import React, { Component } from "react";

import commaNumber from "comma-number";

import Loader from "../../../../../../settings/loader";

import "./style.scss";

export default class Slider4 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        result: null,
        table: [],
      },
      load: 0,
    };
  }

  _initialized() {
    if (this.props.data && !this.state.load) {
      this.setState((prevstate) => ({
        data: {
          ...prevstate.data,
          result: this.props.data,
          legend: this.props.data.chains.data.map((data) => ({
            name:
              this.props.data.brands.data.find(
                (brand) => brand.braKey === data.braKey
              ).name +
              " | " +
              data.name,
            chaKey: data.chaKey,
          })),
        },
        load: 1,
      }));
    }
  }

  loadData() {
    if (this.state.load) {
      let items = [];

      this.state.data.result.sales.data.forEach((data, index) => {
        data.items.forEach((item, index) => {
          const queryItem = items.find(
            (storedItem) =>
              storedItem.refNo === item.refNo && storedItem.name === item.name
          );

          const chain = this.props.data.chains.data.find(
            (chain) => chain.chaKey === data.chaKey
          );
          const brand = this.props.data.brands.data.find(
            (brand) => brand.braKey === data.braKey
          );

          let qty = 1;

          if (item.qty) {
            qty = item.qty;
          }

          if (!queryItem) {
            items.push({
              ...item,
              qty: qty,
              total: item.amount,
              businessName: `${brand.name} - ${chain.name}`,
            });
          } else {
            queryItem.qty = queryItem.qty + qty;
            queryItem.total = queryItem.total + item.amount;
            queryItem.businessName = `${brand.name} - ${chain.name}`;
          }
        });
      });
      //total checks - length
      //total customers just map by customers
      const chains = this.state.data.legend.map((legend) => legend.chaKey);
      const sales = this.state.data.result.sales.data.filter((sale) =>
        chains.includes(sale.chaKey)
      );

      const groups = sales.reduce((groups, sale) => {
        const hour = new Date(sale.date * 1000).getHours();

        if (!groups[hour]) {
          groups[hour] = [];
        }
        groups[hour].push(sale);

        return groups;
      }, {});

      const totalSales = sales.reduce(
        (accumulator, sale) => sale.total + accumulator,
        0
      );

      for (let i = 0; i < 24; i++) {
        if (!groups[i]) {
          groups[i] = [];
        }
      }

      const groupArrays = Object.keys(groups).map((hour) => {
        return {
          hour,
          info: groups[hour],
        };
      });

      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          table: groupArrays,
          totalSales: totalSales,
        },
        load: 2,
      }));
    }
  }

  sortSales(a, b) {
    if (parseInt(a.total) > parseInt(b.total)) {
      return -1;
    }

    if (parseInt(a.total) < parseInt(b.total)) {
      return 1;
    }

    return 0;
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.export !== this.props.export) {
      this.props.getData({
        type: "table",
        title: "Hourly Sales",
        filter: {
          brands: this.props?.filter.data.brands.map((brand) => brand.name),
          chains: this.props?.filter.data.chains.map((chain) => chain.name),
          revCent: this.props?.filter.data.revCent.map(
            (revCent) => revCent.name
          ),
          payType: this.props?.filter.data.payType.map(
            (payType) => payType.name
          ),
          date: {
            start: this.props?.filter.data.date.start,
            end: this.props?.filter.data.date.end,
          },
        },
        body: [
          {
            head: [["Hour", "Checks", "Customers", "Sales", "Percentage"]],
            body: this.state.data.table.map((value) => {
              const { hour, info } = value;

              const totalChecks = info.length;
              const totalCustomers = info.reduce(
                (acc, info) => info.customers + acc,
                0
              );
              const hourlySales = info.reduce(
                (acc, info) => info.total + acc,
                0
              );
              const percentage =
                (hourlySales / this.state.data.totalSales) * 100;
              return [
                `${hour}:00 ${hour < 12 ? "AM" : "PM"}`,
                totalChecks,
                totalCustomers,
                `${this.props.data.currency.base.toUpperCase()} ${commaNumber(
                  hourlySales.toFixed(2)
                )}`,
                `${commaNumber(percentage.toFixed(2))} %`,
              ];
            }),
          },
        ],
      });
    }
  };

  render() {
    switch (this.state.load) {
      case 0:
        this._initialized();
        break;
      case 1:
        this.loadData();
        break;
      default:
        break;
    }

    return (
      <div>
        {!this.state.load ? (
          <Loader />
        ) : (
          <table className={["table", "table-striped"].join(" ")}>
            <thead>
              <tr>
                <th scope={"col"}>Hour</th>
                <th scope={"col"}>Checks</th>
                <th scope={"col"}>Customers</th>
                <th scope={"col"}>Sales</th>
                <th scope={"col"}>Percentage</th>
              </tr>
            </thead>
            <tbody>
              {this.state.data.table.map((sale, key) => {
                const { hour, info } = sale;

                const totalChecks = info.length;
                const totalCustomers = info.reduce(
                  (acc, info) => info.customers + acc,
                  0
                );
                const hourlySales = info.reduce(
                  (acc, info) => info.total + acc,
                  0
                );
                const percentage =
                  (hourlySales / this.state.data.totalSales) * 100;

                return (
                  <tr key={key}>
                    <td>
                      {hour}:00 {hour < 12 ? "AM" : "PM"}
                    </td>
                    <td>{totalChecks}</td>
                    <td>{totalCustomers}</td>
                    <td>
                      {this.props.data.currency.base.toUpperCase()}{" "}
                      {hourlySales.toFixed(2)}{" "}
                    </td>
                    <td>{percentage.toFixed(2)} %</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    );
  }
}
