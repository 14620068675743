import React, { Component } from "react";

import request from "request";

import Window from "../../../../elements/window";

import Loader from "../../../../../settings/loader";

import Slide1 from "./slides/slide1";

export default class sales extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        audit: {
          data: [],
          state: false,
        },
        state: false,
      },
      load: 0,
    };
  }

  getData(headers, callback) {
    let { base64 } = this.props.filter;

    base64 = JSON.parse(atob(base64));
    delete base64.revCent;
    base64 = btoa(JSON.stringify(base64));

    request(
      {
        method: "GET",
        url: this.props.chimeraserv.urlGet + "/audit/set/0/" + base64,
        headers: headers,
      },
      (error, response, body) => {
        callback(JSON.parse(body));
      }
    );
  }

  UNSAFE_componentWillMount() {
    if (this.props.user.data) {
      const headers = {
        Authorization: this.props.user.data.usrKey,
        "Content-Type": "application/json",
        Accept: "application/json",
      };

      this.getData(headers, (data) => {
        this.setState((prevState) => ({
          data: {
            ...prevState.data,
            audit: {
              data: data,
              state: true,
            },
          },
        }));
      });
    }
  }

  render() {
    if (this.state.data.audit.state && !this.state.load) {
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          state: true,
        },
        load: 1,
      }));
    }

    return (
      <section>
        {!this.state.load ? (
          <Loader />
        ) : (
          <div>
            <Window
              user={this.props.user}
              chimeraserv={this.props.chimeraserv}
              title={"Audits"}
            >
              <Slide1
                {...this.props}
                user={this.props.user}
                chimeraserv={this.props.chimeraserv}
                filter={this.props.filter}
                data={this.state.data}
              />
            </Window>
          </div>
        )}
      </section>
    );
  }
}
