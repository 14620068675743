import React, { Component } from "react";

import { Route, Switch } from "react-router-dom";

import { Helmet } from "react-helmet";

import Filter from "../../elements/filter";
import ReportSummary from "../../elements/reportSummary";

import SalesSummary from "./views/salesSummary";
import SessionSummary from "./views/sessionSummary";
import Revcent from "./views/revcent";
import Paytype from "./views/paytype";
import Sales from "./views/sales";
import Item from "./views/item";
import Group from "./views/group";

import hourlySales from "./views/hourlySales";

import "../style.scss";

export default class sales extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: null,
      page: null,
      routes: [
        {
          name: "Group by Sales Summary",
          path: "/summary",
          component: SalesSummary,
        },
        {
          name: "Group by Session Summary",
          path: "/session",
          component: SessionSummary,
        },
        {
          name: "Group by Revenue Center",
          path: "/revcent",
          component: Revcent,
        },
        {
          name: "Group by Payment Type",
          path: "/paytype",
          component: Paytype,
        },
        {
          name: "Group Sales by Checks",
          path: "/checks",
          component: Sales,
        },
        {
          name: "Group Item by Sales",
          path: "/item",
          component: Item,
        },
        {
          name: "Group Sales by Group Items",
          path: "/group",
          component: Group,
        },
        {
          name: "Hourly Sales",
          path: "/hourly",
          component: hourlySales,
        },
      ],
    };
  }

  setFilter = (filters) => {
    this.setState({
      filter: null,
    });

    setTimeout(() => {
      this.setState({
        filter: filters,
        page: this.props.location.pathname,
      });
    }, 500);
  };

  checkPage() {
    if (this.props.location.pathname !== this.state.page) {
      setTimeout(() => {
        this.setState({
          filter: null,
          page: this.props.location.pathname,
        });
      }, 500);
    }
  }

  UNSAFE_componentWillMount() {
    this.setState({
      page: this.props.location.pathname,
    });
  }

  renderTitle = (title) => {
    let name = "Sales";

    if (title) {
      name = title.name + " | Sales";

      if (this.props.location.pathname.split("/").length === 3) {
        name = "Sales";
      }
    }

    return (
      <Helmet>
        <title>{name + " | Chimera Solutions | Insights"}</title>
      </Helmet>
    );
  };

  render() {
    this.checkPage();

    let title = this.state.routes.find(
      (route) => route.path === "/" + this.props.location.pathname.split("/")[3]
    );

    if (this.props.location.pathname.split("/").length === 3) {
      title = { name: "Group by Sales Summary" };
    }

    return (
      <section>
        {this.renderTitle(title)}
        <Filter
          location={this.props.location.pathname}
          user={this.props.user}
          chimeraserv={this.props.chimeraserv}
          setFilter={this.setFilter}
        />
        <div className={["report"]}>
          <div className={["viewTitle"]}>{title ? title.name : <div />}</div>
          <Switch>
            <Route
              exact
              path={this.props.match.path}
              render={(routerProps) =>
                this.state.filter ? (
                  <section>
                    <ReportSummary
                      location={this.props.location.pathname}
                      filter={this.state.filter}
                    />
                    <SalesSummary
                      {...routerProps}
                      user={this.props.user}
                      chimeraserv={this.props.chimeraserv}
                      filter={this.state.filter}
                    />
                  </section>
                ) : (
                  <div />
                )
              }
            />

            {this.state.routes.map((route, key) => (
              <Route
                key={key}
                path={this.props.match.path + route.path}
                render={(routerProps) =>
                  this.state.filter ? (
                    <section>
                      <ReportSummary
                        location={this.props.location.pathname}
                        filter={this.state.filter}
                      />
                      <route.component
                        {...routerProps}
                        user={this.props.user}
                        chimeraserv={this.props.chimeraserv}
                        filter={this.state.filter}
                      />
                    </section>
                  ) : (
                    <div />
                  )
                }
              />
            ))}

            <Route component={this.props.components.setting[0].component} />
          </Switch>
        </div>
      </section>
    );
  }
}
