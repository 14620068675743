import React, { Component } from "react";

import { Helmet } from "react-helmet";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import request from "request";

import "../../style.scss";

export default class CreateClient extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reset: new Date().getTime(),
      title: "Create User" + " | " + "Chimera Solutions | Insights",
      form: {
        state: null,
        message: "",
      },
      data: null,
      field: {
        username: {
          value: null,
          message: "",
          state: null,
        },
        password: {
          value: null,
          state: null,
        },
        rePassword: {
          value: null,
          message: "",
          state: null,
        },
        email: {
          value: null,
          message: "",
          state: null,
        },
      },
      load: false,
    };

    this.valueChanged = this.valueChanged.bind(this);

    this.headers = {
      Authorization: this.props.user.data.usrKey,
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    this.time = null;
  }

  renderTitle = () => (
    <Helmet>
      <title>{this.state.title}</title>
    </Helmet>
  );

  dateChange = (date) => {
    this.setState((prevState) => ({
      field: {
        ...prevState.field,
        accntExp: date,
      },
    }));
  };

  validateFields = () => {
    this.setState((prevState) => ({
      form: {
        state: false,
      },
    }));

    if (
      this.state.field.username.state &&
      this.state.field.rePassword.state &&
      this.state.field.email.state
    ) {
      this.setState((prevState) => ({
        form: {
          state: true,
        },
      }));
    }
  };

  checkField = (data) => {
    clearTimeout(this.time);
    this.time = setTimeout(() => {
      switch (data.id) {
        case "username":
          if (data.value.length) {
            request(
              {
                method: "GET",
                url: this.props.chimeraserv.urlGet + "/user",
                headers: this.headers,
              },
              (error, response, body) => {
                let state = true;
                let message = "";

                if (data.value.length <= 3) {
                  state = false;
                  message = "username should be minimum of 4 characters";
                }

                if (
                  JSON.parse(body).find(
                    (result) => result.username === data.value
                  ) &&
                  this.state.data.username !== data.value
                ) {
                  state = false;
                  message = "username already exist";
                }

                this.setState((prevState) => ({
                  field: {
                    ...prevState.field,
                    [data.id]: {
                      ...prevState.field[data.id],
                      state: state,
                      message: message,
                    },
                  },
                }));
              }
            );
          }
          break;
        case "password":
          if (data.value.length) {
            let state = false;
            let message = "";

            if (data.value.length <= 7) {
              message = "password should be minimum of 8 characters";
            } else {
              state = true;
              message = "";
            }

            this.setState((prevState) => ({
              field: {
                ...prevState.field,
                [data.id]: {
                  ...prevState.field[data.id],
                  state: state,
                  message: message,
                },
              },
            }));
          }
          break;
        case "rePassword":
          if (data.value.length) {
            let state = false;
            let message = "";

            if (
              this.state.field.password.value !==
              this.state.field.rePassword.value
            ) {
              message = "passwords did not match";
            } else {
              state = true;
              message = "";
            }

            this.setState((prevState) => ({
              field: {
                ...prevState.field,
                [data.id]: {
                  ...prevState.field[data.id],
                  state: state,
                  message: message,
                },
              },
            }));
          }
          break;
        case "email":
          if (data.value.length) {
            request(
              {
                method: "GET",
                url: this.props.chimeraserv.urlGet + "/user",
                headers: this.headers,
              },
              (error, response, body) => {
                let state = true;
                let message = "";

                // eslint-disable-next-line
                const regex =
                  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

                if (!regex.test(String(data.value).toLowerCase())) {
                  state = false;
                  message = "invalid email address";
                }

                if (
                  JSON.parse(body).find(
                    (result) => result.email === data.value
                  ) &&
                  this.state.data.email !== data.value
                ) {
                  state = false;
                  message = "email address already exist";
                }

                this.setState((prevState) => ({
                  field: {
                    ...prevState.field,
                    [data.id]: {
                      ...prevState.field[data.id],
                      state: state,
                      message: message,
                    },
                  },
                }));
              }
            );
          }
          break;
        default:
          break;
      }

      this.validateFields();
    }, 500);
  };

  valueChanged = (event) => {
    const element = event.target;

    this.setState((prevState) => ({
      field: {
        ...prevState.field,
        [element.id]: {
          ...prevState.field[element.id],
          value: element.value,
          message: "",
          state: null,
        },
      },
      form: {
        state: false,
      },
    }));

    this.checkField(element);
  };

  reset = () =>
    this.setState({
      reset: new Date().getTime(),
    });

  loadData = () =>
    request(
      {
        method: "GET",
        url:
          this.props.chimeraserv.urlGet +
          "/user/" +
          this.props.match.params.uid,
        headers: this.headers,
      },
      (error, response, body) =>
        error && !JSON.parse(body).length
          ? null
          : this.setState((prevState) => {
              const data = JSON.parse(body)[0];

              return {
                data: data,
                load: 1,
                field: {
                  ...prevState.field,
                  username: {
                    ...prevState.field,
                    value: data.username,
                    state: true,
                  },
                  email: {
                    ...prevState.field,
                    value: data.email,
                    state: true,
                  },
                },
              };
            })
    );

  UNSAFE_componentWillMount = () => {
    this._initial = this.loadData();
  };

  checker(state) {
    const check = {
      icon: "check-circle",
      style: "null",
    };

    switch (state) {
      case true:
        check.icon = "check-circle";
        check.style = "correct";
        break;
      case false:
        check.icon = "times-circle";
        check.style = "wrong";
        break;
      default:
        break;
    }

    return check;
  }

  submit = () => {
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        state: false,
        message: "",
      },
    }));

    request(
      {
        method: "PUT",
        url: this.props.chimeraserv.urlPost + "/user/update",
        headers: {
          Authorization: this.props.user.data.usrKey,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: {
          ...this.state.data,
          username: this.state.field.username.value,
          password: this.state.field.password.value,
          email: this.state.field.email.value,
        },
        json: true,
      },
      (error, response, body) => {
        if (!error) {
          if (!body.__v) {
            this.reset();

            this.setState((prevState) => ({
              form: {
                ...prevState.form,
                message: "Account Updated",
              },
            }));
          }
        } else {
          this.setState((prevState) => ({
            form: {
              ...prevState.form,
              message: JSON.stringify(error),
            },
          }));
        }
      }
    );
  };

  genPass = () => {
    const length = 8;
    const charset = `!@#$%^&*()-=abcdefghijklmnopqrstuvwxyz0123456789`;
    const retVal = [];

    for (let i = 0, n = charset.length; i < length; ++i) {
      let char = charset.charAt(Math.floor(Math.random() * n));

      if (Math.round(Math.random())) {
        retVal.push(char.toUpperCase());
      } else {
        retVal.push(char.toLowerCase());
      }
    }

    const password = String(retVal.join(""));

    this.setState(
      (prevState) => ({
        field: {
          ...prevState.field,
          password: {
            ...prevState.field.password,
            message: `Generated new password: ${password}`,
            value: password,
            state: true,
          },
          rePassword: {
            ...prevState.field.rePassword,
            value: password,
            state: true,
          },
        },
      }),
      () => this.validateFields()
    );
  };

  render() {
    let Loader = this.props.components.setting[1].component;

    return (
      <div className={"custForm"}>
        {this.renderTitle()}
        <div className={["viewTitle"]}>Edit My Account</div>
        {!this.state.load ? (
          <Loader />
        ) : (
          <form className={"form"}>
            <div className={["form-group"]}>
              <label htmlFor={["exampleInputEmail1"]}>
                Username <span>*</span>
              </label>
              <FontAwesomeIcon
                className={[
                  "icon",
                  this.checker(this.state.field.username.state).style,
                ].join(" ")}
                icon={[
                  "fas",
                  this.checker(this.state.field.username.state).icon,
                ]}
              />
              <div className={["field"]}>
                <input
                  type={"text"}
                  className={["form-control"]}
                  id={"username"}
                  placeholder={"username"}
                  value={this.state.field.username.value}
                  onChange={this.valueChanged}
                />
                <span>{this.state.field.username.message}</span>
              </div>
            </div>
            <div className={["form-group"]}>
              <label htmlFor={["exampleInputEmail1"]}>
                Password <span>*</span>
              </label>
              <FontAwesomeIcon
                className={[
                  "icon",
                  this.checker(this.state.field.password.state).style,
                ].join(" ")}
                icon={[
                  "fas",
                  this.checker(this.state.field.password.state).icon,
                ]}
              />
              <div className={["field"]}>
                <div className={["password"].join(" ")}>
                  <input
                    type={"password"}
                    className={["form-control"]}
                    id={"password"}
                    placeholder={"password"}
                    value={this.state.field.password.value}
                    onChange={this.valueChanged}
                  />
                  <button type={"button"} onClick={() => this.genPass()}>
                    <FontAwesomeIcon
                      className={["icon"].join(" ")}
                      icon={"sync-alt"}
                    />
                  </button>
                </div>
                <span>{this.state.field.password.message}</span>
              </div>
            </div>
            <div className={["form-group"]}>
              <label htmlFor={["exampleInputEmail1"]}>
                Re-type Password <span>*</span>
              </label>
              <FontAwesomeIcon
                className={[
                  "icon",
                  this.checker(this.state.field.rePassword.state).style,
                ].join(" ")}
                icon={[
                  "fas",
                  this.checker(this.state.field.rePassword.state).icon,
                ]}
              />
              <div className={["field"]}>
                <input
                  type={"password"}
                  className={["form-control"]}
                  id={"rePassword"}
                  placeholder={"re-type password"}
                  value={this.state.field.rePassword.value}
                  onChange={this.valueChanged}
                />
                <span>{this.state.field.rePassword.message}</span>
              </div>
            </div>
            <div className={["form-group"]}>
              <label htmlFor={["exampleInputEmail1"]}>
                Email Address <span>*</span>
              </label>
              <FontAwesomeIcon
                className={[
                  "icon",
                  this.checker(this.state.field.email.state).style,
                ].join(" ")}
                icon={["fas", this.checker(this.state.field.email.state).icon]}
              />
              <div className={["field"]}>
                <input
                  type={"text"}
                  className={["form-control"]}
                  id={"email"}
                  placeholder={"email address"}
                  value={this.state.field.email.value}
                  onChange={this.valueChanged}
                />
                <span>{this.state.field.email.message}</span>
              </div>
            </div>
            {this.state.form.message ? (
              <div className={["message", "form-group"].join(" ")}>
                <FontAwesomeIcon
                  className={["icon", "correct"].join(" ")}
                  icon={"check-circle"}
                />
                {this.state.form.message}
              </div>
            ) : (
              <div />
            )}
            <button
              type={"button"}
              className={["btn btn-primary"]}
              disabled={this.state.form.state ? false : true}
              onClick={() => this.submit()}
            >
              Update
            </button>
          </form>
        )}
      </div>
    );
  }
}
