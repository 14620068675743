import React, { Component } from "react";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Config from "./config";

import Login from "./components/login";
import Home from "./components/home";

/* COMPONENTS HERE */

import Dashboard from "./components/home/views/dashboard";
import Calendar from "./components/home/views/calendar";
import Sales from "./components/home/views/sales";
import Audit from "./components/home/views/audit";
import PDFReports from "./components/home/views/reports";

import Account from "./components/home/views/account";
import Group from "./components/home/views/group";
import User from "./components/home/views/user";

import ErrorPage from "./components/settings/error";
import Loader from "./components/settings/loader";
import View from "./components/home/elements/view1";

import ReactGA from "react-ga4";

const TRACKING_ID = "G-3CVRJ7EBZ9";

ReactGA.initialize(TRACKING_ID);

export default class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      routes: [
        {
          name: "Home",
          path: "/app",
          component: Home,
        },
        {
          name: "Login",
          path: "/login",
          component: Login,
        },
      ],
      components: {
        page: [
          // Page / Menu / Route / Component Loader
          {
            name: "Dashboard",
            path: process.env.PUBLIC_URL + "/dashboard",
            component: Dashboard,
          },
          {
            name: "Calendar",
            path: process.env.PUBLIC_URL + "/calendar",
            component: Calendar,
          },
          {
            name: "Sales",
            path: process.env.PUBLIC_URL + "/sales",
            component: Sales,
          },
          {
            name: "Audit",
            path: process.env.PUBLIC_URL + "/audit",
            component: Audit,
          },
          {
            name: "Account",
            path: process.env.PUBLIC_URL + "/account",
            component: Account,
          },
          {
            name: "PDF Reports",
            path: process.env.PUBLIC_URL + "/reports",
            component: PDFReports,
          },
          {
            name: "Group",
            path: process.env.PUBLIC_URL + "/group",
            component: Group,
            auth: true,
          },
          {
            name: "User",
            path: process.env.PUBLIC_URL + "/user",
            component: User,
            auth: true,
          },
          {
            name: "View",
            path: process.env.PUBLIC_URL + "/view/:type/:id",
            component: View,
            auth: true,
          },
        ],
        setting: [
          // Settings Component Loader
          {
            name: "Error",
            component: ErrorPage,
          },
          {
            name: "Loader",
            component: Loader,
          },
        ],
      },
      chimeraserv: {
        urlGet: Config.get,
        urlPost: Config.post,
      },
    };
  }

  render() {
    return (
      <Router basename={Config.base}>
        <Switch>
          <Route
            exact
            path="/"
            render={(routerProps) => (
              <Home
                {...routerProps}
                chimeraserv={this.state.chimeraserv}
                components={this.state.components}
              />
            )}
          />

          {this.state.routes.map((route, key) => (
            <Route
              path={route.path}
              key={key}
              render={(routerProps) => (
                <route.component
                  {...routerProps}
                  chimeraserv={this.state.chimeraserv}
                  components={this.state.components}
                />
              )}
            />
          ))}

          <Route component={this.state.components.setting[0].component} />
        </Switch>
      </Router>
    );
  }
}
