import React, { Component } from "react";

import "../../style.scss";

import Window from "../../../../elements/window";

import Slide1 from "./views/slide1";
import Slide2 from "./views/slide2";

export default class User extends Component {
  render() {
    return (
      <section className={"admin"}>
        <div className={"viewTitle"}>
          Dashboard <span>(Admin Panel)</span>
        </div>
        <div className={"viewBody"}>
          <Window
            user={this.props.user}
            chimeraserv={this.props.chimeraserv}
            title={"List of Accounts"}
          >
            <Slide1 {...this.props} />
          </Window>

          <Window
            user={this.props.user}
            chimeraserv={this.props.chimeraserv}
            title={"All Outlets"}
          >
            {<Slide2 {...this.props} />}
          </Window>
        </div>
      </section>
    );
  }
}
