import React, { useEffect, useState, useRef } from "react";

import CanvasJSReact from "../../../../../../../lib/canvasjs/canvasjs.react";

import "./style.scss";

const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

CanvasJS.addColorSet("barChart4", [
  "#FFA32E",
  "#E38029",
  "#FA8039",
  "#E6693C",
  "#FA684B",
]);

const addSymbols = (e) => {
  const suffixes = ["", "K", "M", "B"];
  let order = Math.max(Math.floor(Math.log(e.value) / Math.log(1000)), 0);
  if (order > suffixes.length - 1) order = suffixes.length - 1;
  const suffix = suffixes[order];
  return CanvasJS.formatNumber(e.value / Math.pow(1000, order)) + suffix;
};

function Slide1(props) {
  const mounted = useRef();

  const [data, setData] = useState([]);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      if (data.length > 0) {
        props.getData({
          type: "image",
          title: "Sales By Sales Summary",
          filter: {
            brands: props?.filter.data.brands.map((brand) => brand.name),
            chains: props?.filter.data.chains.map((chain) => chain.name),
            revCent: props?.filter.data.revCent.map((revCent) => revCent.name),
            payType: props?.filter.data.payType.map((payType) => payType.name),
            date: {
              start: props?.filter.data.date.start,
              end: props?.filter.data.date.end,
            },
          },
          body: {
            table: [
              {
                head: [["Reataurant name", "Total"]],
                body: [
                  ...data?.map((item) => [
                    item?.label,
                    `${item?.currency} ${item?.y?.toFixed(2)}`,
                  ]),
                ],
              },
            ],
            id: "exportPdf_Slide1",
          },
        });
      }
    }
  }, [props.export]);

  useEffect(() => {
    // const slide1Data = Object.values(
    //   props.slideData.reduce(
    //     (a, c) => (
    //       (a[c.restaurant_name] = a[c.restaurant_name]
    //         ? ((a[c.restaurant_name].sessionTotal += c.sessionTotal),
    //           a[c.restaurant_name])
    //         : c),
    //       a
    //     ),
    //     {}
    //   )
    // );

    const slide1DataF = props.slideData?.map((item) => {
      return {
        y: item.totalOpenSales + item.totalClosedSales,
        label: item.restaurant_name,
        toolTipContent: `{label} : {y} {currency}`,
        currency: item?.currency,
      };
    });

    setData(slide1DataF);
  }, [props.slideData]);

  const chainsCurrencies = props?.filter?.data?.chains?.map((item) =>
    item?.currency?.toLowerCase()
  );

  const isEqualCurr = chainsCurrencies?.every((val, i, arr) => val === arr[0]);

  const options = {
    colorSet: "barChart4",
    axisX: {
      interval: 1,
      interlacedColor: "#f0f8ff",
    },
    axisY: {
      labelFormatter: addSymbols,

      prefix: isEqualCurr
        ? `${
            chainsCurrencies[0]?.toUpperCase() ||
            props?.filter?.data?.groups[0]?.baseCurr.toUpperCase()
          }`
        : "",

      gridColor: "#d6d6d6",

      gridThickness: 1,
    },
    data: [
      {
        type: "column",
        // indexLabel: "{y}",
        // yValueFormatString: `${this.props.data.currency.base} #,##0.00`,
        yValueFormatString: `#,##0.00`,

        dataPoints: data,
      },
    ],
  };

  if (data && data.length <= 2) {
    delete options.axisX.interval;
  }

  return (
    <div id={"exportPdf_Slide1"}>
      <CanvasJSChart options={options} />
    </div>
  );
}

export default Slide1;

// import React, { Component } from "react";

// import CanvasJSReact from "../../../../../../../lib/canvasjs/canvasjs.react";

// import Loader from "../../../../../../settings/loader";

// import commaNumber from "comma-number";

// import "./style.scss";

// const CanvasJS = CanvasJSReact.CanvasJS;
// const CanvasJSChart = CanvasJSReact.CanvasJSChart;
// CanvasJS.addColorSet("barChart4", [
//   "#FFA32E",
//   "#E38029",
//   "#FA8039",
//   "#E6693C",
//   "#FA684B",
// ]);

// export default class page extends Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       data: {
//         result: null,
//         cols: [],
//         chart: [],
//       },
//       load: 0,
//     };
//   }

//   _initialized() {
//     if (this.props.data.state && !this.state.load) {
//       this.setState((prevstate) => ({
//         data: {
//           ...prevstate.data,
//           result: this.props.data,
//         },
//         load: 1,
//       }));
//     }
//   }

//   loadData() {
//     const data = this.state.data.result.chains.data.map((chain) => {
//       const totalSales = this.state.data.result.sales.data
//         .filter((sale) => sale.chaKey === chain.chaKey)
//         .map((sale) => sale.total);

//       let total = 0;

//       if (totalSales.length) {
//         total = totalSales.reduce((a, b) => a + b);
//       }

//       return {
//         y: total,
//         label:
//           this.state.data.result.brands.data.find(
//             (brand) => brand.braKey === chain.braKey
//           ).name +
//           " | " +
//           chain.name,

//         toolTipContent: `{label} : {y} {currency}`,
//         currency: chain?.currency?.toUpperCase(),
//       };
//     });

//     if (this.state.load) {
//       this.setState((prevState) => ({
//         data: {
//           ...prevState.data,
//           chart: data.filter((res) => res.y),
//         },
//         load: 2,
//       }));
//     }
//   }

//   componentDidUpdate = (prevProps) => {
//     if (prevProps.export !== this.props.export) {
//       this.props.getData({
//         type: "image",
//         title: "Sales by Sales Summary",
//         filter: {
//           brands: this.props.filter.data.brands.map((brand) => brand.name),
//           chains: this.props.filter.data.chains.map((chain) => chain.name),
//           revCent: this.props.filter.data.revCent.map(
//             (revCent) => revCent.name
//           ),
//           payType: this.props.filter.data.payType.map(
//             (payType) => payType.name
//           ),
//           date: {
//             start: this.props.filter.data.date.start,
//             end: this.props.filter.data.date.end,
//           },
//         },
//         body: {
//           table: [
//             {
//               head: [["Business Name", "Total"]],
//               body: [
//                 ...this.state.data.chart.map((data) => [
//                   data.label,
//                   `${data?.currency} ${commaNumber(data.y.toFixed(2))}`,
//                 ]),
//               ],
//             },
//           ],
//           id: "exportPdf_Slide1",
//         },
//       });
//     }
//   };

//   render() {
//     switch (this.state.load) {
//       case 0:
//         this._initialized();
//         break;
//       case 1:
//         this.loadData();
//         break;
//       default:
//         break;
//     }

//     const chainsCurrencies = this.state?.data?.chart?.map((item) =>
//       item?.currency?.toLowerCase()
//     );

//     const isEqualCurr = chainsCurrencies?.every(
//       (val, i, arr) => val === arr[0]
//     );

//     const options = {
//       colorSet: "barChart4",
//       axisY: {
//         labelFormatter: this.addSymbols,
//         // prefix: `${this.props.data.currency.base} `,
//         prefix: isEqualCurr
//           ? `${
//               chainsCurrencies[0]?.toUpperCase() ||
//               this.props.data.currency.base.toUpperCase()
//             }`
//           : "",
//       },
//       axisX: {
//         interval: 1,
//         interlacedColor: "#f0f8ff",
//       },
//       data: [
//         {
//           type: "column",
//           // indexLabel: "{y}",
//           // yValueFormatString: `${this.props.data.currency.base} #,##0.00`,
//           yValueFormatString: `#,##0.00`,
//           dataPoints: this.state.data.chart,
//         },
//       ],
//     };

//     if (
//       this.state.data.result &&
//       this.state.data.result.chains.data.length <= 2
//     ) {
//       delete options.axisX.interval;
//     }

//     return (
//       <div id={"exportPdf_Slide1"}>
//         {!this.state.load ? <Loader /> : <CanvasJSChart options={options} />}
//       </div>
//     );
//   }
// }
