import React, { Component } from "react";

import Loader from "../../../../../../settings/loader";

import commaNumber from "comma-number";

import "./style.scss";

const color = [
  "#8F2DFB",
  "#29D1E3",
  "#90FA39",
  "#E39F29",
  "#FB502D",
  "#E3B029",
  "#90FA39",
  "#29E3C6",
  "#494CFF",
  "#B36F69",
  "#F7FFC9",
  "#FFB7B0",
  "#789FCC",
  "#7290B3",
  "#5DB363",
  "#C9B8FF",
  "#9EFFA5",
  "#CC986A",
  "#B38A66",
  "#ab6120",
  "#ab2220",
  "#9bab20",
  "#2037ab",
  "#ab208d",
  "#de7e7e",
  "#7ede90",
  "#ac7ede",
  "#de7eb4",
  "#3efb2d",
  "#fb2d2d",
  "#eb4034",
  "#ebb434",
  "#eb3483",
  "#56eb34",
  "#eb34e2",
  "#34eb40",
  "#9c34eb",
  "#34eb71",
  "#b134eb",
  "#34ebc9",
  "#7434eb",
  "#c334eb",
];

export default class Slider1 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        result: null,
        groups: [],
      },
      load: 0,
    };
  }

  _initialized() {
    if (this.props.data && !this.state.load) {
      this.setState((prevstate) => ({
        data: {
          ...prevstate.data,
          result: this.props.data,
          groups: this.props.data.group.data.map((data, key) => ({
            name: data.name,
            key: key,
            legend: this.props.data.chains.data.map((data) => ({
              name:
                this.props.data.brands.data.find(
                  (brand) => brand.braKey === data.braKey
                ).name +
                " | " +
                data.name,
              chaKey: data.chaKey,
              total: 0,
            })),
            total: 0,
          })),
        },
        load: 1,
      }));
    }
  }

  loadData() {
    let groups = this.state.data.groups.map((group) => ({
      ...group,
      qty: 0,
      total: 0,
    }));

    this.state.data.result.sales.data.forEach((sales) => {
      sales.items.forEach((item, itemKey) => {
        const itemTotal = item.amount;
        const itemGroup = item.group;

        groups.forEach((group, key) => {
          const item = itemGroup.find((item) => item.type === group.name);

          if (item) {
            groups[key].qty = groups[key].qty + 1;
            groups[key].total = groups[key].total + itemTotal;

            groups[key].legend.forEach((legend, index) => {
              if (legend.chaKey === sales.chaKey) {
                groups[key].legend[index].total =
                  groups[key].legend[index].total + itemTotal;
              }
            });
          }
        });
      });
    });

    if (this.state.load) {
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          groups: groups.filter((data) => data.total),
        },
        load: 2,
      }));
    }
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.export !== this.props.export) {
      let bodyData = [];

      for (let i = 0; i < this.state.data.groups.length; i++) {
        for (let j = 0; j < this.state.data.groups[i].legend.length; j++) {
          bodyData.push([
            this.state.data.groups[i].name,
            this.state.data.groups[i].legend[j].name,
            this.state.data.groups[i].legend[j].total.toFixed(2),
          ]);
        }
      }
      console.log("bodyData", bodyData);

      this.props.getData({
        type: "table",
        title: "Sales by Group Items",
        body: [
          {
            head: [["Group Name", " Brand Name", "Total"]],
            body: [...[...bodyData]],
          },
        ],

        // body: [].concat.apply(
        //   [],
        //   this.state.data.groups.map((data) =>
        //     [].concat(
        //       {
        //         head: [["Group Name", " Brand Name", "Qty", "Total"]],
        //         body: [
        //           [
        //             data.name,
        //             data.qty,
        //             `${this.props.data.currency.base.toUpperCase()} ${commaNumber(
        //               data.total.toFixed(2)
        //             )}`,
        //           ],
        //         ],
        //       },
        //       {
        //         head: [["Outlet", "Total"]],
        //         body: data.legend.map((value) => [
        //           value.name,
        //           `${this.props?.data?.chains?.data
        //             ?.filter((item) => item.chaKey == value.chaKey)[0]
        //             .currency?.toUpperCase()} ${commaNumber(
        //             value.total.toFixed(2)
        //           )}`,
        //         ]),
        //       }
        //     )
        //   )
        // ),
      });
    }
  };

  render() {
    switch (this.state.load) {
      case 0:
        this._initialized();
        break;
      case 1:
        this.loadData();
        break;
      default:
        break;
    }

    return (
      <div>
        {!this.state.load ? (
          <Loader />
        ) : (
          <div className={["divider slider10"].join(" ")}>
            <div className={["custRow"].join(" ")}>
              {this.state.data.groups
                .filter((data) => data.total)
                .map((data, key) => (
                  <div key={key}>
                    <h1 style={{ color: color[key] }}>{data.name}</h1>
                    <h2>
                      <span className={"currency"}>
                        {this.props.data.currency.base.toUpperCase()}
                      </span>{" "}
                      {commaNumber(data.total.toFixed(2))}
                    </h2>
                    <table
                      className={[
                        "table",
                        "table-striped",
                        "defaultTable",
                      ].join(" ")}
                    >
                      <tbody>
                        {data.legend.map((datas, index) => (
                          <tr key={index}>
                            <td>{datas.name}</td>
                            <td>
                              <span className={"currency"}>
                                {this.props?.data?.chains?.data
                                  ?.filter(
                                    (item) => item.chaKey == datas.chaKey
                                  )[0]
                                  .currency?.toUpperCase()}
                              </span>{" "}
                              {commaNumber(datas.total.toFixed(2))}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    );
  }
}
