import React, { Component } from "react";

import { Helmet } from "react-helmet";

import { Link } from "react-router-dom";

import request from "request";

export default class ViewOutlet extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: "Outlet Info" + " | " + "Chimera Solutions | Insights",
      data: [],
      load: false,
    };

    this.headers = {
      Authorization: this.props.user.data.usrKey,
      "Content-Type": "application/json",
      Accept: "application/json",
    };
  }

  outletChange = (groupData, group, brand, chain) => {
    let groupIndex = groupData.brands
      .map((brands, index) => ({
        ...brands,
        chains: brands.chains
          .map((chains, key) => ({ ...chains, index: key }))
          .find((chains) => chains.chaKey === chain.chaKey),
        index: index,
      }))
      .find((brands) => brands.braKey === chain.brand.braKey);

    groupData.brands[groupIndex.index].chains[groupIndex.chains.index].status =
      groupData.brands[groupIndex.index].chains[groupIndex.chains.index]
        .status === 1
        ? 0
        : 1;

    let data = {
      ...groupData,
    };

    request(
      {
        method: "PUT",
        url: this.props.chimeraserv.urlPost + "/group/update",
        headers: {
          Authorization: this.props.user.data.usrKey,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: data,
        json: true,
      },
      (error, response, body) => false
    );
  };

  valueChange = (data, key) => {
    data.status = data.status === 1 ? 0 : 1;

    request(
      {
        method: "PUT",
        url: this.props.chimeraserv.urlPost + "/group/update",
        headers: {
          Authorization: this.props.user.data.usrKey,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: data,
        json: true,
      },
      (error, response, body) => {
        this.setState((prevState) => ({
          load: false,
        }));

        this.setState((prevState) => ({
          data: [data],
          load: true,
        }));
      }
    );
  };

  renderTitle = () => (
    <Helmet>
      <title>{this.state.title}</title>
    </Helmet>
  );

  loadData = () =>
    request(
      {
        method: "GET",
        url: this.props.chimeraserv.urlGet + "/group/",
        headers: this.headers,
      },
      (error, response, body) =>
        this.setState((prevState) => ({
          data: JSON.parse(body)
            .filter((group) =>
              group.brands.find((brand) =>
                brand.chains.find(
                  (chain) => chain.chaKey === this.props.match.params.id
                )
              )
            )
            .map((group) => ({
              ...group,
              brands: group.brands
                .filter((brand) =>
                  brand.chains.find(
                    (chain) => chain.chaKey === this.props.match.params.id
                  )
                )
                .map((brand) =>
                  brand.chains.filter(
                    (chain) => chain.chaKey === this.props.match.params.id
                  )
                ),
            })),
          load: true,
        }))
    );

  UNSAFE_componentWillMount = () => {
    this._initial = this.loadData();
  };

  componentWillUnmout = () => {
    this._initial = null;
  };

  render() {
    let Loader = this.props.components.setting[1].component;

    return (
      <div className={["custForm", "custView"].join(" ")}>
        {this.renderTitle()}
        <div className={["viewTitle"]}>Outlet Info</div>
        {!this.state.load ? (
          <Loader />
        ) : (
          <div>
            <div className={["form", "fullWidth"].join(" ")}>
              {this.state.data.map((data) =>
                data.brands.map((brand, key) =>
                  brand.map((chain) => (
                    <div>
                      <div className={["form-group"]}>
                        <label htmlFor={["exampleInputEmail1"]}>
                          Brand Name
                        </label>
                        <div className={["field", "value"].join(" ")}>
                          {chain.name}
                        </div>
                      </div>
                      <div className={["form-group"]}>
                        <label htmlFor={["exampleInputEmail1"]}>Status</label>
                        <div className={["field", "value"].join(" ")}>
                          {chain.status ? "Active" : "Inactive"}
                        </div>
                      </div>
                      <div className={["form-group"]}>
                        <label htmlFor={["exampleInputEmail1"]}>
                          Chain Key
                        </label>
                        <div className={["field", "value"].join(" ")}>
                          {chain.chaKey}
                        </div>
                      </div>
                      {this.props.user.data.level === 0 ? (
                        <div className={["actions", "actionsAdmin"].join(" ")}>
                          <Link
                            to={
                              this.props.match.path
                                .split("/")
                                .splice(0, 3)
                                .join("/") +
                              "/outlet/edit/" +
                              chain.chaKey
                            }
                            className={["btn btn-primary"]}
                          >
                            Edit
                          </Link>
                          <button
                            type={"button"}
                            // onClick={() => this.valueChange(data, key)}
                            className={["btn", "btn-primary", "disable"].join(
                              " "
                            )}
                          >
                            {data.status ? "Disable" : "Enable"}
                          </button>
                        </div>
                      ) : (
                        <div />
                      )}
                    </div>
                  ))
                )
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}
