import React from "react";
import ReactExport from "react-data-export";
import { formatDate } from "../components/formatDate";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const date = new Date();

export function exportExcel(data) {
  const docData = {
    title: "none",
    type: "none",
    body: [],
    ...date,
  };

  let dataSet = [
    {
      head: [],
      body: [],
    },
  ];

  let filterSheetTable = {};

  if (Object.entries(data).length) {
    let table = data.body;

    if (data.body.table) {
      table = [
        {
          head: data.body.table[0].head,
          body: data.body.table[0].body.map((body) => {
            const object = {};

            data.body.table[0].head[0]
              .map((head, key) => body[key])
              .forEach((head, key) => {
                object[`column${key}`] = head;
              });

            return object;
          }),
        },
      ];
    } else if (!Array.isArray(table)) {
      table = [
        {
          head: data.body.head,
          body: data.body.body.map((body) => {
            const object = {};

            data.body.head[0]
              .map((head, key) => body[key])
              .forEach((head, key) => {
                object[`column${key}`] = head;
              });

            return object;
          }),
        },
      ];
    } else {
      table = table.map((data, index) => ({
        ...data,
        body: [].concat.apply(
          [],
          data.body.map((body) => {
            const object = {};

            data.head[0]
              .map((head, key) => body[key])
              .forEach((head, key) => {
                object[`column${key}`] = head;
              });

            return object;
          })
        ),
      }));
    }

    if (data?.filter) {
      const filterHead = ["filter type", "filter value"];
      let filterBody = [];
      for (const i in data?.filter) {
        if (i !== "payType") {
          filterBody.push({
            column0: i,
            column1:
              i === "date"
                ? `from: ${formatDate.long(
                    data?.filter[i].start
                  )} to ${formatDate.long(data?.filter[i].end)}`
                : Array(data?.filter[i]).join(""),
          });
        }
      }
      filterSheetTable.head = [filterHead];
      filterSheetTable.body = filterBody;
      filterSheetTable.name = "filter";
    }

    dataSet = table;
    if (data?.filter && filterSheetTable?.body) {
      dataSet = [...dataSet, filterSheetTable];
    }
  }

  return (
    <ExcelFile
      filename={`insightsExport_${data.title
        .split(" ")
        .join("_")}_Report_${new Date().getTime()}`}
      hideElement={true}
    >
      {dataSet.map((data, index) => (
        <ExcelSheet
          data={data.body}
          key={index}
          name={data?.name ? data?.name : `sheet_${index}`}
        >
          {data.head[0].map((value, key) => (
            <ExcelColumn label={value} key={key} value={`column${key}`} />
          ))}
        </ExcelSheet>
      ))}

      {/* <ExcelSheet data={filterSheetTable?.body} name={`filters sheet`}>
        {filterSheetTable?.head.map((value, key) => (
          <ExcelColumn label={value} key={key} value={`column${key}`} />
        ))}
      </ExcelSheet> */}
    </ExcelFile>
  );
}
