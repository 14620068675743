import React, { Component } from "react";

import { Helmet } from "react-helmet";

import Window from "../../../../elements/window";

import request from "request";

import Slide1 from "./views/slide1";
import Slide2 from "./views/slide2";
import Slide3 from "./views/slide3";
import Slide4 from "./views/slide4";
import Slide5 from "./views/slide5";

import btoa from "btoa";

import "../../../../style.scss";

export default class Month extends Component {
  constructor(props) {
    super(props);

    this.state = {
      params: "",
      fields: {
        sales: {
          data: [],
          state: false,
        },
        session: {
          data: [],
          state: false,
        },
        brands: {
          data: [],
          state: false,
        },
        chains: {
          data: [],
          state: false,
        },
        payType: {
          data: [],
          state: false,
        },
        revCent: {
          data: [],
          state: false,
        },
        group: {
          data: [],
          state: false,
        },
        currency: {
          base: {},
          state: false,
        },
        load: false,
      },
    };
  }

  renderTitle = () => (
    <Helmet>
      <title>{this.state.title}</title>
    </Helmet>
  );

  getCurrency(headers, callback) {
    request(
      {
        method: "GET",
        url: this.props.chimeraserv.urlGet + "/group",
        headers: headers,
      },
      (error, response, body) => {
        let data = [];

        if (Object.keys(JSON.parse(body)).length) {
          data = JSON.parse(body);
        }

        callback(data);
      }
    );
  }

  getGroup(headers, callback) {
    request(
      {
        method: "GET",
        url: this.props.chimeraserv.urlGet + "/item",
        headers: headers,
      },
      (error, response, body) => {
        let data = [];

        if (Object.keys(JSON.parse(body)).length) {
          data = JSON.parse(body);
        }

        callback(data);
      }
    );
  }

  getBrand(headers, callback) {
    request(
      {
        method: "GET",
        url: this.props.chimeraserv.urlGet + "/brand",
        headers: headers,
      },
      (error, response, body) => {
        let data = [];

        if (Object.keys(JSON.parse(body)).length) {
          data = JSON.parse(body);
        }

        callback(data);
      }
    );
  }

  getChain(headers, callback) {
    request(
      {
        method: "GET",
        url: this.props.chimeraserv.urlGet + "/chain",
        headers: headers,
      },
      (error, response, body) => {
        let data = [];

        if (Object.keys(JSON.parse(body)).length) {
          data = JSON.parse(body);
        }

        callback(data);
      }
    );
  }

  getPaytype(headers, callback) {
    request(
      {
        method: "GET",
        url: this.props.chimeraserv.urlGet + "/paytype",
        headers: headers,
      },
      (error, response, body) => {
        let data = [];

        if (Object.keys(JSON.parse(body)).length) {
          data = JSON.parse(body);
        }

        callback(data);
      }
    );
  }

  getRevcent(headers, callback) {
    request(
      {
        method: "GET",
        url: this.props.chimeraserv.urlGet + "/revcent",
        headers: headers,
      },
      (error, response, body) => {
        let data = [];

        if (Object.keys(JSON.parse(body)).length) {
          data = JSON.parse(body);
        }

        callback(data);
      }
    );
  }

  encode = () => {
    const date = new Date();

    const firstDayMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    firstDayMonth.setHours(0, 0, 0, 0);

    const lastDayMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1);
    lastDayMonth.setHours(23, 59, 59, 59);

    const params = {
      grpKey: {
        $in: [],
      },
      braKey: {
        $in: [],
      },
      chaKey: {
        $in: [],
      },
      date: {
        $gt: parseInt(firstDayMonth.setDate(firstDayMonth.getDate()) / 1000),
        $lt: parseInt(lastDayMonth.setDate(lastDayMonth.getDate()) / 1000),
      },
    };

    this.state.fields.chains.data
      .map((data) => ({
        braKey: data.braKey,
        chaKey: data.chaKey,
        grpKey: data.grpKey,
      }))
      .forEach((data, key) => {
        if (!params.grpKey["$in"].includes(data.grpKey)) {
          params.grpKey["$in"].push(data.grpKey);
        }
        if (!params.chaKey["$in"].includes(data.chaKey)) {
          params.chaKey["$in"].push(data.chaKey);
        }
        if (!params.braKey["$in"].includes(data.braKey)) {
          params.braKey["$in"].push(data.braKey);
        }
      });

    return btoa(JSON.stringify(params));
  };

  getSales(headers, callback) {
    request(
      {
        method: "GET",
        url: `${this.props.chimeraserv.urlGet}/sales/set/0/${this.encode()}`,
        headers: headers,
      },
      (error, response, body) => {
        let data = [];

        if (Object.keys(JSON.parse(body)).length) {
          data = JSON.parse(body);
        }

        callback(data);
      }
    );
  }

  getSessions(headers, callback) {
    request(
      {
        method: "GET",
        url: `${this.props.chimeraserv.urlGet}/session/set/0/${this.encode()}`,
        headers: headers,
      },
      (error, response, body) => {
        let data = [];

        if (Object.keys(JSON.parse(body)).length) {
          data = JSON.parse(body);
        }

        callback(data);
      }
    );
  }

  UNSAFE_componentWillMount() {
    if (this.props.user.data) {
      const headers = {
        Authorization: this.props.user.data.usrKey,
        "Content-Type": "application/json",
        Accept: "application/json",
      };

      this.getCurrency(headers, (data) => {
        const array = [];

        const currency = data
          .map((group) => group.baseCurr)
          .filter((currency) => !array.includes(currency));

        this.setState((prevState) => ({
          fields: {
            ...prevState.fields,
            currency: {
              base: currency[0] ? currency[0]?.toUpperCase() : "QAR",
              state: true,
            },
          },
        }));
      });

      this.getBrand(headers, (data) => {
        this.setState((prevState) => ({
          fields: {
            ...prevState.fields,
            brands: {
              data: data,
              state: true,
            },
          },
        }));
      });

      this.getChain(headers, (data) => {
        this.setState((prevState) => ({
          fields: {
            ...prevState.fields,
            chains: {
              data: data,
              state: true,
            },
          },
        }));
      });

      this.getRevcent(headers, (data) => {
        this.setState((prevState) => ({
          fields: {
            ...prevState.fields,
            revCent: {
              data: data,
              state: true,
            },
          },
        }));
      });

      this.getPaytype(headers, (data) => {
        this.setState((prevState) => ({
          fields: {
            ...prevState.fields,
            payType: {
              data: data,
              state: true,
            },
          },
        }));
      });

      this.getGroup(headers, (data) => {
        this.setState((prevState) => ({
          fields: {
            ...prevState.fields,
            group: {
              data: data,
              state: true,
            },
          },
        }));
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.fields.chains.state !== this.state.fields.chains.state) {
      const headers = {
        Authorization: this.props.user.data.usrKey,
        "Content-Type": "application/json",
        Accept: "application/json",
      };

      this.getSales(headers, (data) => {
        this.setState((prevState) => ({
          fields: {
            ...prevState.fields,
            sales: {
              data: data,
              state: true,
            },
          },
        }));
      });

      this.getSessions(headers, (data) => {
        this.setState((prevState) => ({
          fields: {
            ...prevState.fields,
            session: {
              data: data,
              state: true,
            },
          },
        }));
      });
    }
  }

  blockFilter() {
    if (
      !this.state.fields.load &&
      this.state.fields.session.state &&
      this.state.fields.payType.state &&
      this.state.fields.revCent.state &&
      this.state.fields.chains.state &&
      this.state.fields.group.state &&
      this.state.fields.brands.state &&
      this.state.fields.sales.state &&
      this.state.fields.currency.state
    ) {
      this.setState((prevState) => ({
        fields: {
          ...prevState.fields,
          load: true,
        },
      }));
    }
  }

  render() {
    return (
      <section>
        <div className={["report"]}>
          {this.blockFilter()}
          <Window
            user={this.props.user}
            chimeraserv={this.props.chimeraserv}
            title={"Monthly Sales"}
          >
            <Slide1
              {...this.state}
              user={this.props.user.data}
              data={this.state.fields.load ? this.state.fields : null}
            />
          </Window>
          <div className={["contentContainer"]}>
            <div className={["contentRow"]} style={{ width: "49.55%" }}>
              <Window
                user={this.props.user}
                chimeraserv={this.props.chimeraserv}
                title={"Revenue Center"}
                horizontal
              >
                <Slide2
                  {...this.state}
                  data={this.state.fields.load ? this.state.fields : null}
                />
              </Window>
            </div>
            <div className={["contentRow"]} style={{ width: "49.55%" }}>
              <Window
                user={this.props.user}
                chimeraserv={this.props.chimeraserv}
                title={"Payment Type"}
                horizontal
              >
                <Slide3
                  {...this.state}
                  data={this.state.fields.load ? this.state.fields : null}
                />
              </Window>
            </div>
          </div>
          <div className={["contentContainer"]}>
            <div className={["contentRow"]} style={{ width: "49.55%" }}>
              <Window
                user={this.props.user}
                chimeraserv={this.props.chimeraserv}
                title={"Group by Items"}
                horizontal
              >
                <Slide4
                  {...this.state}
                  data={this.state.fields.load ? this.state.fields : null}
                />
              </Window>
            </div>
            <div className={["contentRow"]} style={{ width: "49.55%" }}>
              <Window
                user={this.props.user}
                chimeraserv={this.props.chimeraserv}
                title={"Monthly Sessions"}
                horizontal
              >
                <Slide5
                  {...this.state}
                  data={this.state.fields.load ? this.state.fields : null}
                />
              </Window>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
