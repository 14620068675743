import React, { Component } from "react";

import CanvasJSReact from "../../../../../../../lib/canvasjs/canvasjs.react";

import Loader from "../../../../../../settings/loader";

import commaNumber from "comma-number";

import "./style.scss";

const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;
CanvasJS.addColorSet("barChart4", [
  "#FFA32E",
  "#E38029",
  "#FA8039",
  "#E6693C",
  "#FA684B",
]);

export default class page extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        result: null,
        cols: [],
        chart: [],
      },
      load: 0,
    };
  }

  _initialized() {
    if (this.props.data && !this.state.load) {
      this.setState((prevstate) => ({
        data: {
          ...prevstate.data,
          result: this.props.data,
        },
        load: 1,
      }));
    }
  }

  loadData() {
    const data = this.state.data.result.chains.data.map((chain) => {
      const totalSales = this.state.data.result.sales.data
        .filter((sale) => sale.chaKey === chain.chaKey)
        .map((sale) => sale.total);

      let total = 0;

      if (totalSales.length) {
        total = totalSales.reduce((a, b) => a + b);
      }

      return {
        y: total,
        label:
          this.state.data.result.brands.data.find(
            (brand) => brand.braKey === chain.braKey
          ).name +
          " | " +
          chain.name,
        toolTipContent: `{label} : {y} {currency}`,
        currency: chain?.currency?.toUpperCase(),
      };
    });

    if (this.state.load) {
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          chart: data,
        },
        load: 2,
      }));
    }
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.export !== this.props.export) {
      this.props.getData({
        type: "image",
        title: "Sales by Sales Summary",
        body: {
          table: [
            {
              head: [["Business Name", "Total"]],
              body: [
                ...this.state.data.chart.map((data) => [
                  data.label,
                  `${data?.currency?.toUpperCase()} ${commaNumber(
                    data.y.toFixed(2)
                  )}`,
                ]),
              ],
            },
          ],
          id: "exportPdf_Slide1",
        },
      });
    }
  };

  render() {
    switch (this.state.load) {
      case 0:
        this._initialized();
        break;
      case 1:
        this.loadData();
        break;
      default:
        break;
    }

    const options = {};

    const chainsCurrencies = this.state?.data?.result?.chains?.data.map(
      (item) => item?.currency?.toLowerCase()
    );

    const isEqualCurr = chainsCurrencies?.every(
      (val, i, arr) => val === arr[0]
    );

    if (this.state.load) {
      options.colorSet = "barChart4";
      options.axisY = {
        prefix: isEqualCurr
          ? `${this.props.data.currency.base.toUpperCase()}`
          : "",
      };
      options.axisX = {
        interval: 1,
      };
      options.data = [
        {
          type: "column",
          yValueFormatString: `#,##0.00`,
          dataPoints: this.state.data.chart,
        },
      ];
    }

    if (
      this.state.data.result &&
      this.state.data.result.chains.data.length <= 2
    ) {
      delete options.axisX.interval;
    }

    return (
      <div id={"exportPdf_Slide1"}>
        {!this.state.load ? <Loader /> : <CanvasJSChart options={options} />}
      </div>
    );
  }
}
