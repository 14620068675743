import React, { Component } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./style.scss";

export default class LoginForm extends Component {
  render() {
    return (
      <div className={["loader"]}>
        <FontAwesomeIcon
          className={["icon"].join(" ")}
          icon={["fas", "circle-notch"]}
        />
      </div>
    );
  }
}
