import React, { Component } from "react";

import { Link } from "react-router-dom";

import { Helmet } from "react-helmet";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ListGroup from "./slides/listGroup";
import CreateGroup from "./slides/createGroup";
import ViewGroup from "./slides/viewGroup";
import EditGroup from "./slides/editGroup";

import "../../../style.scss";

export default class Brand extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: "Manage Groups" + " | " + "Chimera Solutions | Insights",
      routes: [
        {
          name: "list",
          path: "/list",
          component: ListGroup,
        },
        {
          name: "create",
          path: "/create",
          auth: true,
          level: [0, 1],
          component: CreateGroup,
        },
        {
          name: "edit",
          path: "/edit",
          auth: true,
          level: [0, 1],
          component: EditGroup,
        },
        {
          name: "view",
          path: "/view",
          component: ViewGroup,
        },
      ],
    };
  }

  renderTitle = () => (
    <Helmet>
      <title>{this.state.title}</title>
    </Helmet>
  );

  render() {
    let params = false;

    let Error = this.props.components.setting[0].component;
    let Component = <Error />;

    if (this.props.match.params.page) {
      let route = this.state.routes.find((route) => {
        if (route.name === this.props.match.params.page) {
          if (route.auth) {
            if (
              route.level.filter(
                (level) => level === this.props.user.data.level
              ).length
            ) {
              return true;
            } else {
              return false;
            }
          } else {
            return true;
          }
        }

        return false;
      });

      params = true;

      if (route) {
        Component = (
          <route.component
            match={this.props.match}
            user={this.props.user}
            chimeraserv={this.props.chimeraserv}
            components={this.props.components}
          />
        );
      }
    }

    return (
      <div className={"viewContainer"}>
        {this.renderTitle()}
        <div className={["heading"]}>
          {[0, 1].includes(this.props.user.data.level) ? (
            <Link
              to={`${this.props.location.pathname
                .split("/")
                .slice(0, 3)
                .join("/")}/main/create`}
            >
              <FontAwesomeIcon
                className={["icon"].join(" ")}
                icon={["fas", "plus"]}
              />{" "}
              Create New Group
            </Link>
          ) : (
            <div />
          )}
          <Link
            to={`${this.props.location.pathname
              .split("/")
              .slice(0, 3)
              .join("/")}/main/list`}
          >
            List of Groups
          </Link>
        </div>
        <div className={["viewBody"]}>
          {params ? (
            Component
          ) : (
            <ListGroup
              match={this.props.match}
              user={this.props.user}
              chimeraserv={this.props.chimeraserv}
              components={this.props.components}
            />
          )}
        </div>
      </div>
    );
  }
}
