import React, { Component } from "react";

import commaNumber from "comma-number";

import Loader from "../../../../../../settings/loader";

import "./style.scss";

export default class Slider4 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        result: null,
        table: [
          {
            period: "Breakfast",
            sales: 0,
            customer: 0,
            custspend: 0,
            data: [],
            time: {
              start: 0,
              end: 0,
            },
          },
          {
            period: "Lunch",
            sales: 0,
            customer: 0,
            custspend: 0,
            data: [],
            time: {
              start: 0,
              end: 0,
            },
          },
          {
            period: "Dinner",
            sales: 0,
            customer: 0,
            custspend: 0,
            data: [],
            time: {
              start: 0,
              end: 0,
            },
          },
          {
            period: "Others",
            sales: 0,
            customer: 0,
            custspend: 0,
            data: [],
            time: {
              start: 0,
              end: 0,
            },
          },
        ],
      },
      load: 0,
    };
  }

  _initialized() {
    if (this.props.data && !this.state.load) {
      const table = this.state.data.table.map((period, index) => {
        let startTime = 0;
        let endTime = 0;

        switch (index) {
          case 0:
            startTime = 6; //start.getTime();
            endTime = 11; //end.getTime();
            break;
          case 1:
            startTime = 12; //start.getTime();
            endTime = 17; //end.getTime();
            break;
          case 2:
            startTime = 18; //start.getTime();
            endTime = 23; //end.getTime();
            break;
          default:
            break;
        }

        return {
          ...period,
          time: {
            start: startTime,
            end: endTime,
          },
        };
      });

      this.setState((prevstate) => ({
        data: {
          ...prevstate.data,
          result: this.props.data,
          table: table,
        },
        load: 1,
      }));
    }
  }

  loadData() {
    if (this.state.load) {
      let salesStat = this.state.data.table;

      this.state.data.result.sales.data.forEach((data, index) => {
        const date = new Date(data.date * 1000);

        salesStat.forEach((slsStat, key) => {
          if (
            slsStat.time.start <= date.getHours() &&
            date.getHours() <= slsStat.time.end
          ) {
            salesStat[key].sales = salesStat[key].sales + data.total;
            salesStat[key].customer = salesStat[key].customer + data.customers;
          }
        });
      });

      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          table: salesStat
            .filter((sales) => sales.sales)
            .map((sales) => ({
              ...sales,
              custspend: sales.sales
                ? (sales.sales / sales.customer).toFixed(2)
                : (0).toFixed(2),
            })),
        },
        load: 2,
      }));
    }
  }

  sortSales(a, b) {
    if (parseInt(a.total) > parseInt(b.total)) {
      return -1;
    }

    if (parseInt(a.total) < parseInt(b.total)) {
      return 1;
    }

    return 0;
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.export !== this.props.export) {
      this.props.getData({
        type: "table",
        title: "Total Checks",
        body: [
          {
            head: [["Period", "Sales", "Customer", "Avg. Cust Spend"]],
            body: this.state.data.table.map((value) => [
              value.period,
              `${this.props.data.currency.base.toUpperCase()} ${commaNumber(
                value.sales.toFixed(2)
              )}`,
              value.customer,
              `${this.props.data.currency.base.toUpperCase()} ${commaNumber(
                value.custspend
              )}`,
            ]),
          },
        ],
      });
    }
  };

  render() {
    switch (this.state.load) {
      case 0:
        this._initialized();
        break;
      case 1:
        this.loadData();
        break;
      default:
        break;
    }

    return (
      <div>
        {!this.state.load ? (
          <Loader />
        ) : (
          <table className={["table", "table-striped"].join(" ")}>
            <thead>
              <tr>
                <th scope={"col"}>Period</th>
                <th scope={"col"}>Sales</th>
                <th scope={"col"}>Customer</th>
                <th scope={"col"}>Avg Cust Spend.</th>
              </tr>
            </thead>
            <tbody>
              {this.state.data.table.map((data, key) => (
                <tr key={key}>
                  <td>{data.period}</td>
                  <td>
                    {this.props.data.currency.base.toUpperCase()}{" "}
                    {commaNumber(data.sales.toFixed(2))}
                  </td>
                  <td>{data.customer}</td>
                  <td>
                    {this.props.data.currency.base.toUpperCase()}{" "}
                    {commaNumber(data.custspend)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    );
  }
}
