import React, { useEffect, useState } from "react";

import Window from "../../../../elements/window";

import Slide1 from "./slides/slide1";

import Loader from "../../../../../settings/loader";

function SessionSummary(props) {
  const [sales, setSales] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        if (props?.user?.data) {
          await fetchData();
        }
      } catch (err) {
        console.log("Error occured when fetching");
      }
    })();
  }, []);

  const fetchData = async () => {
    const getSales = await fetch(
      props.chimeraserv.urlGet + "/by-session-v2/" + props.filter.base64,
      {
        headers: {
          Authorization: props.user.data.usrKey,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );

    const jsonRes = await getSales.json();

    const chains = props?.filter?.data?.chains;

    const withNames = jsonRes?.map((item) => {
      return {
        ...item,
        currency: `${chains
          ?.find((chainItem) => chainItem?.chaKey == item?.chaKey)
          ?.currency?.toUpperCase()}`,
        restaurant_name: `${
          chains?.find((chainItem) => chainItem?.chaKey == item?.chaKey)?.name
        }`,
      };
    });

    await setSales(withNames);
  };

  return sales ? (
    <div className={["contentContainer"]}>
      <div className={["contentRow"]} style={{ width: "100%" }}>
        <Window
          user={props.user}
          chimeraserv={props.chimeraserv}
          title={"Opend and Closed sessions"}
          horizontal
        >
          <Slide1
            {...props}
            slideData={JSON.parse(JSON.stringify(sales))}
            //striginfy to store as value not as reference so no original array changes when modify the copy
          />
        </Window>
      </div>
    </div>
  ) : (
    <Loader />
  );
}

export default SessionSummary;

// import React, { Component } from "react";

// import request from "request";

// import Window from "../../../../elements/window";

// import Loader from "../../../../../settings/loader";

// import Slide1 from "./slides/slide1";

// export default class sales extends Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       data: null,
//       currency: {
//         base: {},
//         state: false,
//       },
//       load: false,
//     };
//   }

//   getData(headers) {
//     let { base64 } = this.props.filter;

//     base64 = JSON.parse(atob(base64));
//     delete base64.revCent;
//     base64 = btoa(JSON.stringify(base64));

//     request(
//       {
//         method: "GET",
//         url: this.props.chimeraserv.urlGet + "/session/set/0/" + base64,
//         headers: headers,
//       },
//       (error, response, body) => {
//         this.setState({
//           data: JSON.parse(body),
//         });
//       }
//     );
//   }

//   getCurrency(headers, callback) {
//     request(
//       {
//         method: "GET",
//         url: this.props.chimeraserv.urlGet + "/group",
//         headers: headers,
//       },
//       (error, response, body) => {
//         let data = [];

//         if (Object.keys(JSON.parse(body)).length) {
//           data = JSON.parse(body);
//         }

//         callback(data);
//       }
//     );
//   }

//   UNSAFE_componentWillMount() {
//     if (this.props.user.data) {
//       const headers = {
//         Authorization: this.props.user.data.usrKey,
//         "Content-Type": "application/json",
//         Accept: "application/json",
//       };

//       this.getData(headers);

//       this.getCurrency(headers, (data) => {
//         const array = [];

//         const currency = data
//           .map((group) => group.baseCurr)
//           .filter((currency) => !array.includes(currency));

//         this.setState((prevState) => ({
//           currency: {
//             base: currency[0] ? currency[0]?.toUpperCase() : "QAR",
//             state: true,
//           },
//         }));
//       });
//     }
//   }

//   blockFilter() {
//     if (!this.state.load && this.state.data && this.state.currency.state) {
//       this.setState((prevState) => ({
//         load: true,
//       }));
//     }
//   }

//   render() {
//     return (
//       <section>
//         {this.blockFilter()}
//         {!this.state.load ? (
//           <Loader />
//         ) : (
//           <div>
//             <Window
//               user={this.props.user}
//               chimeraserv={this.props.chimeraserv}
//               title={"Open and Closed sales"}
//             >
//               <Slide1
//                 {...this.state}
//                 {...this.props}
//                 user={this.props.user}
//                 chimeraserv={this.props.chimeraserv}
//                 filter={this.props.filter}
//                 data={this.state.data}
//               />
//             </Window>
//           </div>
//         )}
//       </section>
//     );
//   }
// }
