import React, { Component } from "react";

import { Link } from "react-router-dom";

import { Helmet } from "react-helmet";

import request from "request";

export default class ViewClient extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: "User",
      data: [],
      load: false,
    };

    this.headers = {
      Authorization: this.props.user.data.usrKey,
      "Content-Type": "application/json",
      Accept: "application/json",
    };
  }

  loadData = () =>
    request(
      {
        method: "GET",
        url:
          this.props.chimeraserv.urlGet +
          "/user/" +
          this.props.match.params.uid,
        headers: this.headers,
      },
      (error, response, body) =>
        this.setState((prevState) => ({ data: JSON.parse(body), load: true }))
    );

  UNSAFE_componentWillMount = () => {
    this._initial = this.loadData();
  };

  componentWillUnmout = () => {
    this._initial = null;
  };

  renderTitle = () => (
    <Helmet>
      <title>{this.state.title}</title>
    </Helmet>
  );

  valueChange = (data, key) => {
    data.status = data.status === 1 ? 0 : 1;

    request(
      {
        method: "PUT",
        url: this.props.chimeraserv.urlPost + "/user/update",
        headers: {
          Authorization: this.props.user.data.usrKey,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: data,
        json: true,
      },
      (error, response, body) => {
        this.setState((prevState) => ({
          load: false,
        }));

        this.setState((prevState) => ({
          data: [data],
          load: true,
        }));
      }
    );
  };

  render() {
    let Loader = this.props.components.setting[1].component;

    return (
      <div className={["custForm", "custView"].join(" ")}>
        {this.renderTitle()}
        <div className={["viewTitle"]}>View User</div>
        {!this.state.load ? (
          <Loader />
        ) : (
          <div className={"form"}>
            {this.state.data.map((data, key) => (
              <div>
                <div className={["form-group"]}>
                  <label htmlFor={["exampleInputEmail1"]}>Username</label>
                  <div className={["field", "value"].join(" ")}>
                    {data.username}
                  </div>
                </div>
                {this.props.user.data.level === 0 ? (
                  <div className={["form-group"]}>
                    <label htmlFor={["exampleInputEmail1"]}>Password</label>
                    <div className={["field", "value"].join(" ")}>
                      {data.password}
                    </div>
                  </div>
                ) : (
                  <div />
                )}
                <div className={["form-group"]}>
                  <label htmlFor={["exampleInputEmail1"]}>Email</label>
                  <div className={["field", "value"].join(" ")}>
                    {data.email}
                  </div>
                </div>
                <div className={["form-group"]}>
                  <label htmlFor={["exampleInputEmail1"]}>Group</label>
                  <div className={["field", "value"].join(" ")}>
                    {data.group}
                  </div>
                </div>
                <div className={["form-group"]}>
                  <label htmlFor={["exampleInputEmail1"]}>Status</label>
                  <div className={["field", "value"].join(" ")}>
                    {data.status ? "Active" : "Inactive"}
                  </div>
                </div>
                {this.props.user.data.level === 0 ? (
                  <div className={["actions", "actionsAdmin"].join(" ")}>
                    <Link
                      to={
                        this.props.match.path
                          .split("/")
                          .splice(0, 3)
                          .join("/") +
                        "/edit/" +
                        data._id
                      }
                      className={["btn btn-primary"]}
                    >
                      Edit
                    </Link>
                    <button
                      type={"button"}
                      onClick={() => this.valueChange(data, key)}
                      className={["btn", "btn-primary", "disable"].join(" ")}
                    >
                      {data.status ? "Disable" : "Enable"}
                    </button>
                  </div>
                ) : (
                  <div />
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}
