import React, { Component } from "react";

import { Link, Redirect } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./style.scss";

export default class LoginForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      field: {
        email: "",
      },
      login: false,
      alert: {
        state: false,
        data: null,
      },
    };

    this.valueChanged = this.valueChanged.bind(this);
    this.validate = this.validate.bind(this);
  }

  getData = () => {
    if (localStorage.getItem("user") || sessionStorage.getItem("user")) {
      this.setState((prevState) => ({
        login: true,
      }));
    } else {
      this.setState((prevState) => ({
        user: {
          ...prevState.user,
          state: false,
        },
      }));
    }
  };

  valueChanged = (event) => {
    const element = event.target;

    if (this.validateField(element)) {
      this.setState((prevState) => ({
        field: {
          ...prevState.field,
          [element.id]:
            element.id === "store" ? element.checked : element.value,
        },
      }));
    }
  };

  validateField(field) {
    return true;
  }

  validateLogin() {
    if (this.state.login) {
      return <Redirect to="/app" />;
    }
  }

  validate = (event) => {
    event.preventDefault();

    this.setState({
      alert: {
        state: false,
        data: null,
      },
    });

    setTimeout(() => {
      this.setState({
        alert: {
          state: true,
          data: {
            status: "#d62424",
            message: "Checking...",
          },
        },
      });
    }, 500);

    setTimeout(() => {
      this.setState({
        alert: {
          state: true,
          data: {
            status: "#d62424",
            message: "Incorrect Email Address.",
          },
        },
      });
    }, 1500);
  };

  componentDidMount() {
    this.getData();
  }

  render() {
    return (
      <div>
        {this.validateLogin()}

        <form onSubmit={this.validate}>
          <div className={["form-group"]}>
            <FontAwesomeIcon
              className={["icon"].join(" ")}
              icon={["fas", "at"]}
            />

            <input
              type={"text"}
              className={["form-control"]}
              id={"email"}
              value={this.state.field.email}
              placeholder={"email address"}
              onChange={this.valueChanged}
            />
          </div>

          <div
            className={["form-group", "statusContainer"].join(" ")}
            style={
              this.state.alert.data
                ? { color: this.state.alert.data.status }
                : {}
            }
          >
            {this.state.alert.state ? this.state.alert.data.message : <div />}
          </div>

          <button
            type={"submit"}
            className={["btn", "btn-primary"].join(" ")}
            disabled={this.state.field.email ? false : true}
          >
            Forgot Password
          </button>
        </form>

        <Link
          className={["formLink"].join(" ")}
          to={
            this.props.location.prevUrl ? this.props.location.prevUrl : "/login"
          }
        >
          Go Back
        </Link>
      </div>
    );
  }
}
