import React, { useState, useEffect } from "react";
import ReactTable from "react-table-v6";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Helmet } from "react-helmet";

import "react-table-v6/react-table.css";
import "./style.scss";

const openPdfFile = (url) => {
  window.open(url, "_blank");
};

const Reports = (props) => {
  const [groups, setGroups] = useState([]);
  const [brands, setBrands] = useState([]);
  const [chains, setChains] = useState([]);

  const [reports, setReports] = useState([]);

  const [hideFilter, setHideFilter] = useState(false);
  const [filters, setFilters] = useState({
    groups: [],
    brands: [],
    chains: [],
  });

  const headers = new Headers({
    Authorization: props.user.data.usrKey,
    "Content-Type": "application/json",
    Accept: "application/json",
  });

  const chainsLookup =
    chains &&
    chains.reduce((chns, chn) => {
      chns[chn.chaKey] = chn.name;

      return chns;
    }, {});

  const columns = [
    {
      Header: "Outlet",
      accessor: "chaKey",
      Cell: ({ value }) => chainsLookup[value] || "Invalid Outlet",
    },
    {
      Header: "Report Name",
      accessor: "name",
    },
    {
      id: "action",
      Header: "Action",
      accessor: (report) =>
        // <i
        //   onClick={printReport}
        //   disabled={report.toPrint}
        //   name={`${report._id}-${report.reportId}`}
        // >
        //   {report.toPrint ? "Preparing Report..." : "download Report"}
        // </i>

        _generateReportOption(report),
      sortable: false,
    },
  ];

  //-----------FUNCTIONS-------------

  function reSize() {
    const shouldHide = hideFilter ? false : true;

    setHideFilter(shouldHide);
  }

  function changeFilter(event) {
    const data = event.target;

    let filter = [];
    let brandOptions = [];
    let chainOptions = [];
    let obj = {};

    switch (data.name) {
      case "groups":
        filter = filters.groups.map((option) => {
          if (option.grpKey === data.id) {
            return {
              ...option,
              status: option.status ? false : true,
            };
          }

          return { ...option };
        });

        if (filter.filter((group) => group.status).length) {
          let data = filter
            .filter((group) => group.status)
            .map((group) => group.grpKey);

          brandOptions = brands.filter((brand) => data.includes(brand.grpKey));
          chainOptions = chains.filter((chain) => data.includes(chain.grpKey));
        } else {
          brandOptions = brands;
          chainOptions = chains;
        }
        break;
      case "brands":
        filter = filters.brands.map((option) => {
          if (option.braKey === data.id) {
            return {
              ...option,
              status: option.status ? false : true,
            };
          }

          return { ...option };
        });

        if (filter.filter((brand) => brand.status).length) {
          let data = filter
            .filter((group) => group.status)
            .map((group) => group.braKey);

          chainOptions = chains.filter((chain) => data.includes(chain.braKey));
        } else {
          chainOptions = chains;
        }
        break;
      case "chains":
        filter = filters.chains.map((option) => {
          if (option.chaKey === data.id) {
            return {
              ...option,
              status: option.status ? false : true,
            };
          }

          return { ...option };
        });
        break;
      default:
        break;
    }

    if (brandOptions.length) {
      obj.brands = brandOptions;
    }

    if (chainOptions.length) {
      obj.chains = chainOptions;
    }

    setFilters((filters) => ({
      ...filters,
      [data.name]: filter,
      ...obj,
    }));
  }

  function generateReports() {
    const filterGroups = filters.groups.filter((group) => group.status);
    const filterBrands = filters.brands.filter((brand) => brand.status);
    const filterChains = filters.chains.filter((chain) => chain.status);

    let groupList = groups;
    let brandsList = brands;
    let chainsList = chains;

    if (filterGroups.length) {
      groupList = filterGroups.filter((group) => group.status);
      brandsList = brands.filter((branch) =>
        groupList.find((group) => group.grpKey === branch.grpKey)
      );
      chainsList = chains.filter((chain) =>
        brandsList.find((branch) => branch.braKey === chain.braKey)
      );

      if (filterBrands.length) {
        brandsList = filterBrands;
      }

      if (filterChains.length) {
        chainsList = filterChains;
      }
    }

    if (filterGroups.length && filterBrands.length) {
      brandsList = filterBrands.filter((brand) => brand.status);
      groupList = groups.filter((group) =>
        brandsList.find((brand) => brand.grpKey === group.grpKey)
      );
      chainsList = chains.filter((chain) =>
        brandsList.find((brand) => brand.braKey === chain.braKey)
      );

      if (filterChains.length) {
        chainsList = filterChains;
      }
    }

    if (filterGroups.length && filterBrands.length && filterChains.length) {
      chainsList = filterChains.filter((chain) => chain.status);
      groupList = groups.filter((group) =>
        chainsList.find((chain) => group.grpKey === chain.grpKey)
      );
      brandsList = brands.filter((brand) =>
        chainsList.find((chain) => brand.braKey === chain.braKey)
      );
    }

    const base64filter = btoa(
      JSON.stringify({
        grpKey: { $in: groupList.map((group) => group.grpKey) },
        braKey: { $in: brandsList.map((brand) => brand.braKey) },
        chaKey: { $in: chainsList.map((chain) => chain.chaKey) },
      })
    );

    getReports(base64filter);
  }

  async function getReports(base64filter) {
    const response = await fetch(
      new Request(`${props.chimeraserv.urlGet}/reports/${base64filter}`, {
        headers: headers,
      })
    );
    const reports = await response.json();

    const allReportsArrays = reports.map((report) =>
      report.report.map((r) => {
        return {
          _id: report._id,
          grpKey: report.grpKey,
          braKey: report.braKey,
          chaKey: report.chaKey,
          status: report.status,
          ...r,
        };
      })
    );

    const allReports = allReportsArrays?.flat();

    const preparingRerports = allReports?.filter((item) => item?.toPrint);

    const readyRerports = allReports?.filter((item) => item?.status);

    const otherRerports = allReports?.filter(
      (item) => !item?.status && !item?.toPrint
    );

    setReports([...preparingRerports, ...readyRerports, ...otherRerports]);
  }

  async function printReport(event) {
    const [_id, reportId] = event.target.name.split("-");
    const body = JSON.stringify({
      mode: "print",
      reportIds: [parseInt(reportId)],
    });

    const preparingRerports = reports.filter(
      (item) => !item.status && item.toPrint
    );
    if (preparingRerports.length >= 2) {
      alert("please wait until finishing your current preparing reports");
    } else {
      try {
        alert("Request sent sucessfully, please refresh in few minutes");
        const response = await fetch(
          new Request(`${props.chimeraserv.urlPost}/reports/${_id}`, {
            headers: headers,
            method: "PUT",
            body: body,
          })
        );

        if (response.status !== 200) {
          alert("something wrong!");
        }

        const reportResponse = await response.json();

        await generateReports();
      } catch (error) {
        alert(error.message);
      }
    }
  }

  //--------------UPON MOUNTING---------

  useEffect(() => {
    async function getGroups() {
      const response = await fetch(
        new Request(`${props.chimeraserv.urlGet}/group`, { headers: headers })
      );
      const groups = await response.json();

      setGroups(groups);
      setFilters((filters) => ({ ...filters, groups: groups }));
    }
    getGroups();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    async function getBrands() {
      const response = await fetch(
        new Request(`${props.chimeraserv.urlGet}/brand`, { headers: headers })
      );
      const brands = await response.json();

      setBrands(brands);
      setFilters((filters) => ({ ...filters, brands: brands }));
    }
    getBrands();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    async function getChains() {
      const response = await fetch(
        new Request(`${props.chimeraserv.urlGet}/chain`, { headers: headers })
      );
      const body = await response.json();

      const chains = body.map((chain) => {
        return { ...chain, name: `${chain.name}` };
      });

      setChains(chains);
      setFilters((filters) => ({ ...filters, chains: chains }));
    }
    getChains();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const _generateReportOption = (report) => {
    const { url, status, toPrint } = report;

    return status && !toPrint && url ? (
      <div>
        <button
          style={{ marginRight: "20px" }}
          onClick={printReport}
          disabled={report.toPrint}
          name={`${report._id}-${report.reportId}`}
        >
          Re-Generate
        </button>

        <button
          onClick={() => openPdfFile(report?.url)}
          name={`${report._id}-${report.reportId}`}
        >
          Open as PDF
        </button>
      </div>
    ) : !status && toPrint ? (
      <span>Generating...</span>
    ) : (
      <button
        style={{ marginRight: "20px" }}
        onClick={printReport}
        disabled={report.toPrint}
        name={`${report._id}-${report.reportId}`}
      >
        Generate Report
      </button>
    );
  };

  //------------END OF MOUNTING------------------
  return (
    <section>
      <Helmet>
        <title>{`PDF Reports | Chimera Solutions | Insights`}</title>
      </Helmet>

      <div className={["viewBody"]}>
        <section className={"filter"}>
          <div className={["params", hideFilter ? "hide" : ""].join(" ")}>
            <div>
              <h2>
                <FontAwesomeIcon
                  className={["icon"].join(" ")}
                  icon={["fas", "layer-group"]}
                />
                Group
              </h2>
              <div className={["paramBody"]}>
                <div
                  className={["custom-control", "custom-checkbox"].join(" ")}
                >
                  <div>
                    {filters.groups.map((data, key) => {
                      return (
                        <div key={key}>
                          <input
                            type={"checkbox"}
                            className={["custom-control-input"]}
                            name="groups"
                            id={data.grpKey}
                            onChange={changeFilter}
                            checked={data.status}
                          />
                          <label
                            className={"custom-control-label"}
                            htmlFor={data.grpKey}
                          >
                            {data.name}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <h2>
                <FontAwesomeIcon
                  className={["icon"].join(" ")}
                  icon={["fas", "store"]}
                />
                Brand
              </h2>
              <div className={["paramBody"]}>
                <div
                  className={["custom-control", "custom-checkbox"].join(" ")}
                >
                  <div>
                    <div
                      className={["custom-control", "custom-checkbox"].join(
                        " "
                      )}
                    >
                      {filters.brands.map((data, key) => {
                        return (
                          <div key={key}>
                            <input
                              type={"checkbox"}
                              className={["custom-control-input"]}
                              name="brands"
                              id={data.braKey}
                              onChange={changeFilter}
                              checked={data.status}
                            />
                            <label
                              className={"custom-control-label"}
                              htmlFor={data.braKey}
                            >
                              {data.name}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <h2>
                <FontAwesomeIcon
                  className={["icon"].join(" ")}
                  icon={["fas", "tags"]}
                />
                Outlet
              </h2>
              <div className={["paramBody"]}>
                <div
                  className={["custom-control", "custom-checkbox"].join(" ")}
                >
                  <div>
                    {filters.chains.map((data, key) => {
                      return (
                        <div key={key}>
                          <input
                            type={"checkbox"}
                            className={["custom-control-input"]}
                            name="chains"
                            id={data.chaKey}
                            onChange={changeFilter}
                            checked={data.status}
                          />
                          <label
                            className={"custom-control-label"}
                            htmlFor={data.chaKey}
                          >
                            {data.name}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <h2>
                <FontAwesomeIcon
                  className={["icon"].join(" ")}
                  icon={["fas", "ellipsis-h"]}
                />
              </h2>
              <div className={["paramBody"]}>
                <div
                  className={["custom-control", "custom-checkbox"].join(" ")}
                ></div>
              </div>
            </div>
            <div>
              <h2>
                <FontAwesomeIcon
                  className={["icon"].join(" ")}
                  icon={["fas", "calendar-alt"]}
                />
              </h2>
              <div className={["paramBody"]}>
                <div
                  className={["custom-control", "custom-checkbox"].join(" ")}
                ></div>
              </div>
            </div>
          </div>
          <div className={["footer"]}>
            <div className={["footerHead"]}>
              <button onClick={() => reSize()}>
                <FontAwesomeIcon
                  className={["icon"].join(" ")}
                  icon={["fas", hideFilter ? "plus-square" : "minus-square"]}
                />
              </button>
              <h2>Insights Report</h2>
            </div>
            <button
              className={[hideFilter ? "hide" : ""]}
              onClick={generateReports}
            >
              Get Reports
            </button>
          </div>
        </section>
        <section className={"report"}>
          <div className={["window", "windowHorizontal"].join(" ")}>
            <div className={["header"]}>
              <div className={["heading"]}>
                <button>
                  <FontAwesomeIcon
                    className={["icon"].join(" ")}
                    icon={["fas", "plus-square"]}
                  />
                </button>
                <h2>Reports</h2>
              </div>
            </div>
            <div className={["winBody"]}>
              <ReactTable
                className={["-striped", "-highlight"].join(" ")}
                data={reports}
                columns={columns}
                sortable
                getTdProps={() => ({
                  style: {
                    textAlign: "center",
                  },
                })}
              />
            </div>
          </div>
        </section>
      </div>
    </section>
  );
};

export default Reports;
