import React, { Component } from "react";
import ReactTable from "react-table-v6";
import Loader from "../../../../../../settings/loader";
import commaNumber from "comma-number";
import "react-table-v6/react-table.css";
import "./style.scss";

const getChainAbbreviation = (businessName) => {
  const businessNameArr = businessName.split(" - ");

  if (businessNameArr.length === 1) {
    return businessName;
  }

  const chainArr = businessNameArr[0].split(" ");
  if (chainArr.length > 1) {
    let abbr = "";
    chainArr.forEach((elem) => {
      if (elem) {
        abbr += elem[0];
      }
    });
    return `${abbr} - ${businessNameArr[businessNameArr.length - 1]}`;
  }
  return businessName;
};

export default class Slider4 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        result: null,
        table: [],
      },
      load: 0,
    };
  }

  _initialized() {
    if (this.props.data && !this.state.load) {
      this.setState((prevstate) => ({
        data: {
          ...prevstate.data,
          result: {
            ...this.props.filter.data,
            ...this.props.data,
          },
        },
        load: 1,
      }));
    }
  }

  topParser(tableData) {
    let qtyArray = [];

    tableData.forEach((data, index) => {
      qtyArray.push(data.qty);
    });

    return {
      max: Math.max(...qtyArray),
      ave: qtyArray[Math.floor((qtyArray.length - 1) / 2)],
      min: Math.min(...qtyArray),
    };
  }

  loadData() {
    if (this.state.load) {
      let items = [];

      this.state.data.result.sales.data.forEach((data, index) => {
        data.items.forEach((item, index) => {
          const queryItem = items.find(
            (storedItem) =>
              storedItem.refNo === item.refNo && storedItem.name === item.name
          );

          const brand = this.props.data.brands.data.find(
            (brand) => brand.braKey === data.braKey
          );
          const chain = this.props.data.chains.data.find(
            (chain) => chain.chaKey === data.chaKey
          );

          let qty = 1;

          if (item.qty) {
            qty = item.qty;
          }

          const amount =
            (item.amount - (item.discount ? item.discount.discountAmt : 0)) *
            qty;

          if (!queryItem) {
            items.push({
              ...item,
              qty: qty,
              total: amount,
              businessName: [
                {
                  name: `${brand.name} - ${chain.name}`,
                  amount: amount,
                  currency: chain?.currency?.toUpperCase(),
                },
              ],
            });
          } else {
            queryItem.qty = queryItem.qty + qty;
            queryItem.total = queryItem.total + amount;

            const businessName = queryItem.businessName.find(
              (businessName) =>
                businessName.name === `${brand.name} - ${chain.name}`
            );

            if (businessName) {
              queryItem.businessName.map((businessName) => {
                if (businessName.name === `${brand.name} - ${chain.name}`) {
                  businessName.amount = businessName.amount + amount;
                }

                return businessName;
              });
            } else {
              queryItem.businessName.push({
                name: `${brand.name} - ${chain.name}`,
                amount: amount,
                currency: chain?.currency?.toUpperCase(),
              });
            }
          }
        });
      });

      let topParser = this.topParser(items);

      let topTen = items
        .filter(
          (data) =>
            data.total && data.qty <= topParser.max && data.qty >= topParser.ave
        )
        .map((data, index) => data);

      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          table: items.sort(this.sortSales),
        },
        load: 2,
      }));
    }
  }

  sortSales(a, b) {
    if (parseInt(a.qty) > parseInt(b.qty)) {
      return -1;
    }

    if (parseInt(a.qty) < parseInt(b.qty)) {
      return 1;
    }

    return 0;
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.export !== this.props.export) {
      this.props.getData({
        type: "table",
        title: "Sales by Items",
        filter: {
          brands: this.props.filter.data.brands.map((brand) => brand.name),
          chains: this.props.filter.data.chains.map((chain) => chain.name),
          revCent: this.props.filter.data.revCent.map(
            (revCent) => revCent.name
          ),
          payType: this.props.filter.data.payType.map(
            (payType) => payType.name
          ),
          date: {
            start: this.props.filter.data.date.start,
            end: this.props.filter.data.date.end,
          },
        },
        body: [
          {
            head: [
              [
                "Item No.",
                "Name",
                "Qty",
                "Price",
                "Discount",
                "Sales",
                "Total Sales Per Outlet",
              ],
            ],
            body: this.state.data.table.map((value) => {
              return [
                value.refNo,
                String(value.name)
                  .toLowerCase()
                  .split(/(?: - )|(?:-)/)
                  .map((string) =>
                    string
                      .split(/ /)
                      .map(
                        (word) =>
                          word.charAt(0).toUpperCase() + word.substring(1)
                      )
                      .join(" ")
                  )
                  .join(" "),
                commaNumber(value.qty),
                `${commaNumber(value.amount.toFixed(2))} ${
                  value?.businessName[0]?.currency
                }`,

                `${commaNumber(
                  value.discount ? value.discount.discountAmt : 0
                )} ${value?.businessName[0]?.currency}`,

                `${commaNumber(value.total.toFixed(2))} ${
                  value?.businessName[0]?.currency
                }`,

                `${value?.businessName
                  .map(
                    (item) =>
                      `${getChainAbbreviation(item?.name)}` +
                      (value.amount != 0
                        ? ` | Qty ${(item.amount / value.amount).toFixed(0)}`
                        : "") +
                      ` | ${item.amount} ${item.currency}`
                  )
                  .join("\n")}`,
              ];
            }),
          },
        ],
      });
    }
  };

  render() {
    switch (this.state.load) {
      case 0:
        this._initialized();
        break;
      case 1:
        this.loadData();
        break;
      default:
        break;
    }

    const columns = [
      {
        Header: "Item No",
        accessor: "refNo",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Qty.",
        accessor: "qty",
      },
      {
        Header: "Price",
        accessor: "amount",
      },
      {
        id: "discount",
        Header: "Discount",
        accessor: (data) => {
          const dis = data.discount ? data.discount.discountAmt : 0;

          return parseFloat(dis);
        },
      },
      {
        Header: "Sales",
        accessor: "total",
      },
      {
        id: "sales",
        Header: "Total Sales Per Outlet",
        width: 400,
        accessor: (data) => {
          const totalPayment = data.businessName.reduce(
            (total, businessName) => {
              return total + businessName.amount;
            },
            0
          );

          return parseFloat(totalPayment);
        },
        Cell: (row) => {
          return row.row._original.businessName.map((businessName, index) => {
            console.log("businessNames", businessName);
            return (
              <div key={index}>
                {`${businessName.name}` +
                  (row.row._original.amount != 0
                    ? ` | Qty ${(
                        businessName.amount / row.row._original.amount
                      ).toFixed(1)}`
                    : "") +
                  ` | ${commaNumber(businessName.amount.toFixed(2))} ${
                    businessName?.currency
                  }`}
              </div>
            );
          });
        },
      },
    ];

    return (
      <div>
        {!this.state.load ? (
          <Loader />
        ) : (
          <ReactTable
            className={["-striped", "-highlight"].join(" ")}
            data={this.state.data.table}
            columns={columns}
            sortable
            filterable
            getTdProps={() => ({
              style: {
                textAlign: "center",
              },
            })}
          />
        )}
      </div>
    );
  }
}
