import React, { Component } from "react";

import CanvasJSReact from "../../../../../../../lib/canvasjs/canvasjs.react";

import Loader from "../../../../../../settings/loader";

import commaNumber from "comma-number";

import "./style.scss";

const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;
CanvasJS.addColorSet("pieChart1", [
  "#8F2DFB",
  "#29D1E3",
  "#90FA39",
  "#E39F29",
  "#FB502D",
  "#E3B029",
  "#90FA39",
  "#29E3C6",
  "#494CFF",
  "#B36F69",
  "#F7FFC9",
  "#FFB7B0",
  "#789FCC",
  "#7290B3",
  "#5DB363",
  "#C9B8FF",
  "#9EFFA5",
  "#CC986A",
  "#B38A66",
  "#ab6120",
  "#ab2220",
  "#9bab20",
  "#2037ab",
  "#ab208d",
  "#de7e7e",
  "#7ede90",
  "#ac7ede",
  "#de7eb4",
  "#3efb2d",
  "#fb2d2d",
  "#eb4034",
  "#ebb434",
  "#eb3483",
  "#56eb34",
  "#eb34e2",
  "#34eb40",
  "#9c34eb",
  "#34eb71",
  "#b134eb",
  "#34ebc9",
  "#7434eb",
  "#c334eb",
]);

export default class Slider2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        result: null,
        chart: [],
      },
      load: 0,
    };
  }

  _initialized() {
    if (this.props.data && !this.state.load) {
      this.setState((prevstate) => ({
        data: {
          ...prevstate.data,
          result: this.props.data,
        },
        load: 1,
      }));
    }
  }

  loadData() {
    const data = this.state.data.result.revCent.data
      .map((revCent) => {
        const totalSales = this.state.data.result.sales.data
          .filter((sale) => sale.revCent === revCent.name)
          .map((sale) => sale.total);

        let total = 0;

        if (totalSales.length) {
          total = totalSales.reduce((a, b) => a + b);
        }

        return {
          y: total,
          name: revCent.name,
        };
      })
      .filter((data) => data.y);

    if (this.state.load) {
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          chart: data,
        },
        load: 2,
      }));
    }
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.export !== this.props.export) {
      this.props.getData({
        type: "image",
        title: "Revenue Center",
        body: {
          table: [
            {
              head: [["Revenue Center", "Total"]],
              body: this.state.data.chart.map((data) => [
                data.name,
                `${this.props.data.currency.base.toUpperCase()} ${commaNumber(
                  data.y.toFixed(2)
                )}`,
              ]),
            },
          ],
          id: "exportPdf_Slide2",
        },
      });
    }
  };

  render() {
    switch (this.state.load) {
      case 0:
        this._initialized();
        break;
      case 1:
        this.loadData();
        break;
      default:
        break;
    }

    const options = {};

    if (this.state.load) {
      options.colorSet = "pieChart1";
      options.legend = {
        cursor: "pointer",
      };
      options.data = [
        {
          type: "pie",
          showInLegend: true,
          indexLabel: "{name}: {y}",
          yValueFormatString: `${this.props.data.currency.base.toUpperCase()} #,##0.00`,
          dataPoints: this.state.data.chart,
        },
      ];
    }

    return (
      <div id={"exportPdf_Slide2"}>
        {!this.state.load ? <Loader /> : <CanvasJSChart options={options} />}
      </div>
    );
  }
}
