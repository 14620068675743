import React, { useState, useEffect } from "react";
import { Route, Switch, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import commaNumber from "comma-number";
import { formatTitle } from "../../formatTitle";
import { formatDate } from "../../formatDate";
import "./style.scss";

export default (props) => {
  const [views] = useState(["sales", "void", "deletion", "session", "audit"]);
  const [data, setData] = useState([]);
  const [currency, setCurrency] = useState({
    base: {},
    state: false,
  });
  const [load, setLoad] = useState(false);

  const { user, components, location, chimeraserv } = props;

  const headers = new Headers({
    Authorization: user.data.usrKey,
    "Content-Type": "application/json",
    Accept: "application/json",
  });

  //-------------FUNCTIONS -------------------

  function renderData() {
    const Loader = components.setting[1].component;

    const obj = data.length
      ? Object.entries(data[0]).filter(
          (d) =>
            !["_id", "status", "braKey", "chaKey", "grpKey", "__v"].includes(
              d[0]
            )
        )
      : [];

    return (
      <div className={["custForm", "custView"].join(" ")}>
        <div className={["viewTitle"]}>
          <Link
            to={
              location.prevUrl
                ? location.prevUrl
                : `/app/${props.match.params.type}`
            }
          >
            <FontAwesomeIcon
              className={["iconBack"].join(" ")}
              icon={["fas", "caret-left"]}
            />
          </Link>
          View {formatTitle(props.match.params.type)}
        </div>
        {!load ? (
          <Loader />
        ) : (
          <div className={"form"}>
            {obj.map((objData, dataKey) => (
              <div className={["form-group"]}>
                <label htmlFor={["exampleInputEmail1"]}>
                  {formatTitle(objData[0])}
                </label>
                <div className={["field", "value"].join(" ")}>
                  {renderField(objData)}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }

  function renderField(objData) {
    let dataValue = "";

    switch (typeof objData[1]) {
      case "string":
        dataValue = formatTitle(objData[1]);
        break;
      case "object":
        if (
          !["items", "payType", "emp", "empSig", "type"].includes(objData[0])
        ) {
          break;
        }

        dataValue = objData[1].map((obj) => {
          return (
            <div>
              {Object.entries(obj)
                .filter((object) => {
                  return true;
                })
                .map((object) => {
                  let value = "";

                  switch (typeof object[1]) {
                    case "string":
                      value = ` - ${formatTitle(object[1])}`;
                      break;
                    case "number":
                      switch (object[0]) {
                        case "total":
                        case "amount":
                          value = ` - ${currency?.base} ${commaNumber(
                            object[1].toFixed(2)
                          )}`;
                          break;
                        case "date":
                          value = ` - ${formatDate.long(object[1])}`;
                          break;
                        default:
                          value = ` - ${commaNumber(object[1])}`;
                          break;
                      }
                      break;
                    default:
                      break;
                  }

                  return value;
                })}
            </div>
          );
        });
        break;
      case "number":
        switch (objData[0]) {
          case "total":
            dataValue = `${currency?.base} ${commaNumber(
              objData[1].toFixed(2)
            )}`;
            break;
          case "date":
            dataValue = formatDate.long(objData[1]);
            break;
          default:
            if (["billNo", "delId"].includes(objData[0])) {
              dataValue = objData[1];
            } else {
              dataValue = commaNumber(objData[1]);
            }
            break;
        }
        break;
      default:
        break;
    }

    return dataValue;
  }

  //------------ UPON MOUNTING ---------------

  useEffect(() => {
    async function loadData() {
      let type = props.match.params.type;

      if (type === "void") {
        type = "sales";
      }

      const [viewResponse, groupResponse] = await Promise.all([
        fetch(
          new Request(
            `${chimeraserv.urlGet}/${type}/view/0/${props.match.params.id}`,
            { headers: headers }
          )
        ),
        fetch(new Request(`${chimeraserv.urlGet}/group`, { headers: headers })),
      ]);

      const [view, group] = await Promise.all([
        viewResponse.json(),
        groupResponse.json(),
      ]);

      const chainCurr = group[0].brands
        .filter((brandItem) => brandItem?.braKey == view[0]?.braKey)[0]
        ?.chains?.filter(
          (chainItem) => chainItem?.chaKey == view[0]?.chaKey
        )[0]?.currency;

      const array = [];

      // const newCurrency = group
      //   .map((g) => g.baseCurr)
      //   .filter((c) => !array.includes(c));

      setData(view);
      setLoad(true);
      setCurrency({
        base: chainCurr?.toUpperCase() || group[0]?.baseCurr?.toUpperCase(),
        state: true,
      });
    }

    loadData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className={"viewContainer"}>
        <div className={["viewBody"]}>
          <Switch>
            {views.find((view) => view === props.match.params.type) ? (
              renderData()
            ) : (
              <Route component={components.setting[0].component} />
            )}
          </Switch>
        </div>
      </div>
    </>
  );
};
