import React, { Component } from "react";

import { Helmet } from "react-helmet";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import request from "request";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "../../../../style.scss";

import { currencies } from "../../../../../../../static/currencies";

export default class CreateClient extends Component {
  constructor(props) {
    super(props);

    this.newLevelRef = React.createRef();

    this.state = {
      selectedCurrency: currencies[0],
      reset: new Date().getTime(),
      title: "Create Client" + " | " + "Chimera Solutions | Insights",
      form: {
        state: null,
        message: "",
      },
      field: {
        name: {
          value: "",
          message: "",
          state: null,
        },
        levels: {
          value: [
            {
              id: 1,
              name: "Admin",
              state: true,
            },
          ],
          message: "",
          state: true,
        },
        accntExp: {
          value: new Date(
            new Date().getFullYear() + 1,
            new Date().getMonth(),
            new Date().getDate()
          ),
          message: "",
          state: true,
        },
      },
    };

    this.valueChanged = this.valueChanged.bind(this);

    this.headers = {
      Authorization: this.props.user.data.usrKey,
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    this.time = null;
  }

  dateChange = (date) => {
    this.setState((prevState) => ({
      field: {
        ...prevState.field,
        accntExp: {
          ...prevState.field.accntExp,
          value: new Date(),
          state: true,
        },
      },
    }));
  };

  validateFields = () => {
    this.setState((prevState) => ({
      form: {
        state: false,
      },
    }));

    if (this.state.field.name.state && this.state.field.levels.state) {
      this.setState((prevState) => ({
        form: {
          state: true,
        },
      }));
    }
  };

  valdidateLevelStates = () => {
    const states = this.state.field.levels.value.filter(
      (data) => data.state
    ).length;

    if (this.state.field.levels.value.length === states) {
      this.setState(
        (prevState) => ({
          field: {
            ...prevState.field,
            levels: {
              ...prevState.field.levels,
              state: true,
            },
          },
        }),
        () => this.validateFields()
      );
    }
  };

  valueChanged = (event) => {
    const element = event.target;

    if (element.getAttribute("data-field")) {
      const field = element.getAttribute("data-field");
      const key = element.getAttribute("data-key");

      this.setState((prevState) => {
        const value = prevState.field[field].value.map((data, index) => {
          let newValue = element.value;

          if (element.id === "id") {
            newValue = parseInt(element.value);
          }

          if (index === key) {
            return {
              ...data,
              [element.id]: newValue,
            };
          }

          return data;
        });

        return {
          field: {
            ...prevState.field,
            [field]: {
              ...prevState.field[field],
              value: value,
              message: "",
              state: null,
            },
          },
          form: {
            state: false,
          },
        };
      });
    } else {
      this.setState((prevState) => ({
        field: {
          ...prevState.field,
          [element.id]: {
            ...prevState.field[element.id],
            value: element.value,
            message: "",
            state: null,
          },
        },
        form: {
          state: false,
        },
      }));
    }

    this.checkField(element);
  };

  renderTitle = () => (
    <Helmet>
      <title>{this.state.title}</title>
    </Helmet>
  );

  handleChange = (date) => {
    this.setState((prevState) => ({
      field: {
        ...prevState.field,
        accntExp: date,
      },
    }));
  };

  reset = () =>
    this.setState({
      reset: new Date().getTime(),
    });

  checkField = (data) => {
    clearTimeout(this.time);
    this.time = setTimeout(() => {
      if (data.getAttribute("data-field")) {
        const field = data.getAttribute("data-field");
        const key = data.getAttribute("data-key");

        switch (field) {
          case "levels":
            let state = true;
            let message = "";

            if (
              data.id === "name" &&
              this.state.field.levels.value[key].name.length <= 3
            ) {
              state = false;
              message = "Group name should be minimum of 4 characters";
            }

            // eslint-disable-next-line
            if (
              data.id === "id" &&
              !this.state.field.levels.value[key].id &&
              this.state.field.levels.value[key].id < 1 &&
              this.state.field.levels.value[key].id === 0 &&
              typeof this.state.field.levels.value[key].id !== "int"
            ) {
              state = false;
              message = "user level should be on or between level 1 - 9";
            }

            // eslint-disable-next-line
            if (
              data.id === "id" &&
              !this.state.field.levels.value[key].id &&
              this.state.field.levels.value[key].id === 0 &&
              typeof this.state.field.levels.value[key].id !== "int"
            ) {
              state = false;
              message = "user level should be on or between level 1 - 9";
            }

            // eslint-disable-next-line
            if (
              data.id === "id" &&
              this.state.field.levels.value.filter(
                (value) => value.id === data.value
              ).length > 1 &&
              this.state.field.levels.value.length >= 2
            ) {
              state = false;
              message = "user level exist";
            }

            this.setState(
              (prevState) => {
                const value = prevState.field[field].value.map(
                  (value, index) => {
                    if (index === key) {
                      return {
                        ...value,
                        message: message,
                        state: state,
                      };
                    }

                    return value;
                  }
                );

                return {
                  field: {
                    ...prevState.field,
                    [field]: {
                      ...prevState.field[field],
                      value: value,
                      message: "",
                      state: null,
                    },
                  },
                };
              },
              () => this.valdidateLevelStates()
            );
            break;
          default:
            break;
        }
      } else {
        switch (data.id) {
          case "name":
            if (data.value.length) {
              request(
                {
                  method: "GET",
                  url: this.props.chimeraserv.urlGet + "/group",
                  headers: this.headers,
                },
                (error, response, body) => {
                  let state = true;
                  let message = "";

                  if (data.value.length <= 3) {
                    state = false;
                    message = "Group name should be minimum of 4 characters \n";
                  }

                  if (
                    JSON.parse(body).find(
                      (result) => result.name === data.value
                    )
                  ) {
                    state = false;
                    message = "client name already exist \n";
                  }

                  this.setState(
                    (prevState) => ({
                      field: {
                        ...prevState.field,
                        [data.id]: {
                          ...prevState.field[data.id],
                          value: data.value,
                          state: state,
                          message: message,
                        },
                      },
                    }),
                    () => this.validateFields()
                  );
                }
              );
            }
            break;
          default:
            break;
        }
      }
    }, 500);
  };

  checker(state) {
    let checker = {
      icon: "check-circle",
      style: "null",
    };

    switch (state) {
      case true:
        checker = {
          icon: "check-circle",
          style: "correct",
        };
        break;
      case false:
        checker = {
          icon: "times-circle",
          style: "wrong",
        };
        break;
      default:
        break;
    }

    return checker;
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.reset !== this.state.reset) {
      this.setState((prevState) => ({
        field: {
          ...prevState.field,
          name: {
            ...prevState.field.name,
            value: "",
            message: "",
            state: null,
          },
          levels: {
            ...prevState.field.levels,
            value: [
              {
                id: 1,
                name: "Admin",
                state: true,
              },
            ],
            message: "",
            state: true,
          },
          accntExp: {
            value: new Date(),
            message: "",
            state: true,
          },
        },
        form: {
          state: null,
          message: "",
        },
      }));
    }
  };

  add = (name) => {
    if (!name) {
      alert("enter a valid level name");
    } else {
      this.setState(
        (prevState) => ({
          field: {
            ...prevState.field,
            levels: {
              ...prevState.field.levels,
              value: [
                ...prevState.field.levels.value,
                {
                  id: parseInt(prevState.field.levels.value.length + 1),
                  name: name,
                  state: true,
                },
              ],
              message: "",
              state: true,
            },
          },
        }),
        this.valdidateLevelStates()
      );
    }
  };

  remove = (key) => {
    const newLevels = [].concat(
      [],
      this.state.field.levels.value.filter((vale, index) => key !== index)
    );

    this.setState(
      (prevState) => ({
        field: {
          ...prevState.field,
          levels: {
            ...prevState.field.levels,
            value: newLevels,
            message: "",
            state: null,
          },
        },
      }),
      () => this.valdidateLevelStates()
    );
  };

  submit = () => {
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        state: false,
        message: "",
      },
    }));

    request(
      {
        method: "POST",
        url: `${this.props.chimeraserv.urlPost}/group/create/${"group"}`,
        headers: {
          Authorization: this.props.user.data.usrKey,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: {
          name: this.state.field.name.value,
          levels: this.state.field.levels.value,
          accntExp: this.state.field.accntExp.value,
          baseCurr: this.state.selectedCurrency,
        },
        json: true,
      },
      (error, response, body) => {
        if (!error) {
          if (!body.__v) {
            this.reset();

            this.setState((prevState) => ({
              form: {
                ...prevState.form,
                message: "Group Created",
              },
            }));
          }
        } else {
          this.setState((prevState) => ({
            form: {
              ...prevState.form,
              message: JSON.stringify(error),
            },
          }));
        }
      }
    );
  };

  handleCurrencyChange = (e) => {
    this.setState({ selectedCurrency: e.target.value });
  };

  render() {
    return (
      <div className={"custForm"}>
        {this.renderTitle()}
        <div className={["viewTitle"]}>Create New Group</div>
        <form onSubmit={() => {}} className={["form"]}>
          <div className={["form-group"]}>
            <label>
              Group Name <span>*</span>
            </label>
            <FontAwesomeIcon
              className={[
                "icon",
                this.checker(this.state.field.name.state).style,
              ].join(" ")}
              icon={["fas", this.checker(this.state.field.name.state).icon]}
            />
            <div className={["field"]}>
              <input
                type={"text"}
                className={["form-control"]}
                id={"name"}
                placeholder={"enter group name"}
                value={this.state.field.name.value}
                onChange={this.valueChanged}
              />
              <span>{this.state.field.name.message}</span>
            </div>
          </div>

          <div className={["form-group"]}>
            <label htmlFor="group">
              Base currency <span>*</span>
            </label>
            <FontAwesomeIcon
              className={"icon correct"}
              icon={["fas", "check-circle"]}
            />
            <select
              className="form-control"
              id="group"
              onChange={this.handleCurrencyChange}
            >
              {currencies.map((item) => (
                <option value={item}>{item}</option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label>New level name</label>
            <div className="multipart">
              <div className={["field"]}>
                <div className={"part"}>
                  <input
                    ref={this.newLevelRef}
                    type={"text"}
                    className={["form-control", "custField"].join(" ")}
                    id={"name"}
                    placeholder={"enter level name"}
                  />

                  {
                    <button
                      type={"button"}
                      onClick={() => this.add(this.newLevelRef.current.value)}
                      // className={["add"]}
                      className={["custBtn", "add"].join(" ")}
                    >
                      <FontAwesomeIcon
                        className={[
                          "icon",
                          this.checker(this.state.field.levels.state).style,
                        ].join(" ")}
                        icon={"plus"}
                      />
                    </button>
                  }
                </div>
              </div>
            </div>
          </div>

          <div className={["form-group"]}>
            <label>
              User levels <span>*</span>
            </label>
            <div className={"multipart"}>
              {this.state.field.levels.value.map((data, key) => {
                return (
                  <div className={["field"]}>
                    <div className={"part"}>
                      <FontAwesomeIcon
                        className={[
                          "icon",
                          this.checker(data.state).style,
                        ].join(" ")}
                        icon={["fas", this.checker(data.state).icon]}
                      />
                      <input
                        type={"number"}
                        disabled
                        className={["form-control", "level"].join(" ")}
                        id={"id"}
                        placeholder={"level"}
                        value={data.id}
                        data-key={key}
                        onChange={this.valueChanged}
                        data-field={"levels"}
                      />
                      <input
                        type={"text"}
                        disabled
                        className={["form-control", "custField"].join(" ")}
                        id={"name"}
                        placeholder={"enter level name"}
                        value={data.name}
                        data-key={key}
                        onChange={this.valueChanged}
                        data-field={"levels"}
                      />

                      {key !== 0 && (
                        <button
                          type={"button"}
                          onClick={() => this.remove(key)}
                          className={["custBtn", "remove"].join(" ")}
                        >
                          <FontAwesomeIcon
                            className={[
                              "icon",
                              this.checker(this.state.field.levels.state).style,
                            ].join(" ")}
                            icon={"minus"}
                          />
                        </button>
                      )}
                    </div>
                    <span>{data.message}</span>
                  </div>
                );
              })}
            </div>
          </div>
          <div className={["form-group"]}>
            <label>
              Account Expiration <span>*</span>
            </label>
            <FontAwesomeIcon
              className={[
                "icon",
                this.checker(this.state.field.accntExp.state).style,
              ].join(" ")}
              icon={["fas", this.checker(this.state.field.accntExp.state).icon]}
            />
            <div className={["date"].join(" ")}>
              <DatePicker
                className={["form-control"]}
                selected={this.state.field.accntExp.value}
                onChange={this.dateChange}
              />
            </div>
          </div>
          {this.state.form.message ? (
            <div className={["message", "form-group"].join(" ")}>
              <FontAwesomeIcon
                className={["icon", "correct"].join(" ")}
                icon={"check-circle"}
              />
              {this.state.form.message}
            </div>
          ) : (
            <div />
          )}
          <button
            type={"button"}
            className={["btn btn-primary"]}
            disabled={this.state.form.state ? false : true}
            onClick={() => this.submit()}
          >
            Create
          </button>
          <button
            type={"button"}
            onClick={() => this.reset()}
            className={["btn btn-primary", "reset"].join(" ")}
          >
            Reset
          </button>
        </form>
      </div>
    );
  }
}
