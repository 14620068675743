import React, { Component } from "react";

import Loader from "../../../../../../settings/loader";

import commaNumber from "comma-number";

import { formatDate } from "../../../../../../formatDate";

import "./style.scss";

export default class Slider4 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        result: null,
        table: [],
      },
      load: 0,
    };
  }

  _initialized() {
    if (this.props.data && !this.state.load) {
      this.setState((prevstate) => ({
        data: {
          ...prevstate.data,
          result: {
            ...this.props.data,
          },
        },
        load: 1,
      }));
    }
  }

  loadData() {
    if (this.state.load) {
      const session = this.state.data.result.session.data.map((data, index) => {
        let chain = this.state.data.result.chains.data.find(
          (chain) => chain.chaKey === data.chaKey
        );
        let brand = this.state.data.result.brands.data.find(
          (brand) => brand.braKey === data.braKey
        );

        return {
          ...data,
          businessName: brand.name + " - " + chain.name,
        };
      });

      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          table: session.sort(this.sortSales),
        },
        load: 2,
      }));
    }
  }

  sortSales(a, b) {
    if (parseInt(a.date) > parseInt(b.date)) {
      return -1;
    }

    if (parseInt(a.date) < parseInt(b.date)) {
      return 1;
    }

    return 0;
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.export !== this.props.export) {
      this.props.getData({
        type: "table",
        title: "Weekly - Payment Type",
        body: [
          {
            head: [
              [
                "Ses Number",
                "Sales",
                "Customers",
                "Checks",
                "Date / Time",
                "Business Name",
              ],
            ],
            body: this.state.data.table.map((value) => [
              value.sesNo,
              `${this.props.fields.currency.base} ${commaNumber(
                value.sales.toFixed(2)
              )}`,
              commaNumber(value.customers),
              commaNumber(value.checks),
              formatDate.long(value.date),
              value.businessName,
            ]),
          },
        ],
      });
    }
  };

  render() {
    switch (this.state.load) {
      case 0:
        this._initialized();
        break;
      case 1:
        this.loadData();
        break;
      default:
        break;
    }

    return (
      <div>
        {!this.state.load ? (
          <Loader />
        ) : (
          <table
            className={["table", "table-striped", "defaultTable"].join(" ")}
          >
            <thead>
              <tr>
                <th scope={"col"}>Ses Number</th>
                <th scope={"col"}>Sales</th>
                <th scope={"col"}>Customers</th>
                <th scope={"col"}>Checks</th>
                <th scope={"col"}>Date / Time</th>
                <th scope={"col"}>Business Name</th>
              </tr>
            </thead>
            <tbody>
              {this.state.data.table.map((data, key) => (
                <tr key={key}>
                  <td>{data.sesNo}</td>
                  <td>
                    {this.props.fields.currency.base}{" "}
                    {commaNumber(data.sales.toFixed(2))}
                  </td>
                  <td>{commaNumber(data.customers)}</td>
                  <td>{commaNumber(data.checks)}</td>
                  <td>{formatDate.long(data.date)}</td>
                  <td>{data.businessName}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    );
  }
}
