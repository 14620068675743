import React, { useEffect, useRef } from "react";

import commaNumber from "comma-number";

import { RenderLink } from "./renderLink";
import { formatDate } from "../../../../../../formatDate";

import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import "./style.scss";

function Slide1(props) {
  const mounted = useRef();

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      if (props.slideData?.length > 0) {
        props.getData({
          type: "table",
          title: "Open and closed",

          filter: {
            brands: props?.filter.data.brands.map((brand) => brand.name),
            chains: props?.filter.data.chains.map((chain) => chain.name),
            // revCent: props?.filter.data.revCent.map((revCent) => revCent.name),
            payType: props?.filter.data.payType.map((payType) => payType.name),
            date: {
              start: props?.filter.data.date.start,
              end: props?.filter.data.date.end,
            },
          },

          body: [
            {
              head: [
                [
                  "Buisness name",
                  "Status",
                  "Session No.",
                  "Sales",
                  "Customers",
                  "Checks",
                  "Date / Time",
                ],
              ],
              body: props.slideData?.map((value) => [
                value.restaurant_name,
                `${value.status == 0 ? "open" : "closed"}`,
                `${value.sesNo}`,
                `${value.sessionSales || 0} ${value.currency}`,
                `${value.sessionCustomers || 0}`,
                `${value.sessionChecks || 0}`,
                `${formatDate.long(value.date)}`,
              ]),
            },
          ],
        });
      }
    }
  }, [props.export]);

  const columns = [
    {
      Header: "Business Name",
      accessor: "restaurant_name",
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }

        if (row[filter.id] === filter.value) {
          return row[filter.id];
        }
      },
      Filter: ({ filter, onChange }) => {
        return (
          <select
            onChange={(event) => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}
          >
            <option value="all">All</option>
            {[
              ...new Set(
                props?.slideData.map((table) => table.restaurant_name)
              ),
            ].map((name) => (
              <option value={name}>{name}</option>
            ))}
          </select>
        );
      },
      Cell: (row) => {
        return (
          <RenderLink
            location={props.location}
            data={row.row._original}
            value={row.value}
          />
        );
      },
    },
    {
      Header: "Status",
      accessor: "status",
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }

        if (filter.value === "Open") {
          return row[filter.id] === 0;
        }

        if (filter.value === "Closed") {
          return row[filter.id] === 1;
        }
      },
      Filter: ({ filter, onChange }) => {
        return (
          <select
            onChange={(event) => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}
          >
            <option value="all">All</option>
            <option value="Open">Open</option>
            <option value="Closed">Closed</option>
          </select>
        );
      },

      Cell: (row) => {
        return (
          <RenderLink
            location={props.location}
            data={row.row._original}
            value={
              <div className={"name"}>
                <div className={row.value ? "open" : ""} />
                {row.value ? "Closed" : "Open"}
              </div>
            }
          />
        );
      },
    },
    {
      Header: "Session No.",
      accessor: "sesNo",
      Cell: (row) => {
        return (
          <RenderLink
            location={props.location}
            data={row.row._original}
            value={row.value}
          />
        );
      },
    },
    {
      Header: "Sales",
      accessor: "sessionSales",
      Cell: (row) => {
        return (
          <RenderLink
            location={props.location}
            data={row.row._original}
            value={`${row?.original?.currency} ${commaNumber(
              row?.value?.toFixed(2) || 0
            )}`}
          />
        );
      },
    },
    {
      Header: "Customers",
      accessor: "sessionCustomers",
      Cell: (row) => {
        return (
          <RenderLink
            location={props.location}
            data={row.row._original}
            value={`${commaNumber(row.value) || 0}`}
          />
        );
      },
    },
    {
      Header: "Checks",
      accessor: "sessionChecks",
      Cell: (row) => {
        return (
          <RenderLink
            location={props.location}
            data={row.row._original}
            value={`${commaNumber(row.value) || 0}`}
          />
        );
      },
    },
    {
      Header: "Date / Time",
      accessor: "date",
      Cell: (row) => {
        return (
          <RenderLink
            location={props.location}
            data={row.row._original}
            value={formatDate.long(row.value)}
          />
        );
      },
    },
  ];

  // const columns = [
  //   {
  //     Header: "Business Name",
  //     accessor: "restaurant_name",
  //   },
  //   {
  //     Header: "Status",
  //     accessor: "status",
  //     Cell: (row) => {
  //       return `${row.value == 0 ? "Open" : "Closed"}`;
  //     },
  //   },
  //   {
  //     Header: "Session No.",
  //     accessor: "sesNo",
  //   },
  //   {
  //     Header: "Sales",
  //     accessor: "sessionSales",
  //     Cell: (row) => {
  //       return `${row?.original?.currency?.toUpperCase()} ${commaNumber(
  //         row.value
  //       )}`;
  //     },
  //   },

  //   {
  //     Header: "Customers",
  //     accessor: "sessionCustomers",
  //   },
  //   {
  //     Header: "Checks",
  //     accessor: "sessionChecks",
  //   },
  //   {
  //     Header: "Date / Time",
  //     accessor: "date",
  //   },
  // ];

  return (
    <div>
      <ReactTable
        className={["-striped", "-highlight"].join(" ")}
        data={props.slideData}
        columns={columns}
        sortable
        filterable
        getTdProps={() => ({
          style: {
            textAlign: "center",
          },
        })}
      />
    </div>
  );
}

export default Slide1;

// import React, { Component } from "react";
// import ReactTable from "react-table-v6";
// import { Link } from "react-router-dom";
// import { formatDate } from "../../../../../../formatDate";
// import commaNumber from "comma-number";
// import "react-table-v6/react-table.css";
// import "./style.scss";

// export default class page extends Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       data: {
//         result: null,
//         table: [],
//       },
//       load: 0,
//     };
//   }

//   _initialized() {
//     if (this.props.data && !this.state.load) {
//       this.setState((prevstate) => ({
//         data: {
//           ...prevstate.data,
//           result: {
//             ...this.props.filter.data,
//             session: this.props.data,
//           },
//         },
//         load: 1,
//       }));
//     }
//   }

//   loadData() {
//     if (this.state.load) {
//       const session = this.state.data.result.session.map((data, index) => {
//         let chain = this.state.data.result.chains.find(
//           (chain) => chain.chaKey === data.chaKey
//         );
//         let brand = this.state.data.result.brands.find(
//           (brand) => brand.braKey === data.braKey
//         );

//         return {
//           ...data,
//           businessName: brand.name + " - " + chain.name,
//           currency: chain?.currency?.toUpperCase(),
//         };
//       });

//       this.setState((prevState) => ({
//         data: {
//           ...prevState.data,
//           table: session.sort(this.sortSales),
//         },
//         load: 2,
//       }));
//     }
//   }

//   sortSales(a, b) {
//     if (parseInt(a.sesNo) > parseInt(b.sesNo)) {
//       return -1;
//     }

//     if (parseInt(a.sesNo) < parseInt(b.sesNo)) {
//       return 1;
//     }

//     return 0;
//   }

//   renderLink = (component) => (
//     <Link
//       to={{
//         pathname: `/${this.props.location.pathname.split("/")[1]}/view/${
//           this.props.location.pathname.split("/")[3]
//         }/${component.data._id}`,
//         prevUrl: this.props.location.pathname,
//       }}
//     >
//       {component.value}
//     </Link>
//   );

//   componentDidUpdate = (prevProps) => {
//     if (prevProps.export !== this.props.export) {
//       this.props.getData({
//         type: "table",
//         title: "Session Summary",
//         filter: {
//           brands: this.props.filter.data.brands.map((brand) => brand.name),
//           chains: this.props.filter.data.chains.map((chain) => chain.name),
//           revCent: this.props.filter.data.revCent.map(
//             (revCent) => revCent.name
//           ),
//           payType: this.props.filter.data.payType.map(
//             (payType) => payType.name
//           ),
//           date: {
//             start: this.props.filter.data.date.start,
//             end: this.props.filter.data.date.end,
//           },
//         },
//         body: [
//           {
//             head: [
//               [
//                 "Status",
//                 "Ses Number",
//                 "Sales",
//                 "Customers",
//                 "Checks",
//                 "Date / Time",
//                 "Business Name",
//               ],
//             ],
//             body: this.state.data.table.map((value) => [
//               value.status ? "Closed" : "Open",
//               value.sesNo,
//               `${value?.currency} ${commaNumber(value.sales.toFixed(2))}`,
//               commaNumber(value.customers),
//               commaNumber(value.checks),
//               formatDate.long(value.date),
//               value.businessName,
//             ]),
//           },
//         ],
//       });
//     }
//   };

//   render() {
//     switch (this.state.load) {
//       case 0:
//         this._initialized();
//         break;
//       case 1:
//         this.loadData();
//         break;
//       default:
//         break;
//     }

//     let uniqueNames;

//     if (this.state.data.table) {
//       uniqueNames = [
//         ...new Set(this.state.data.table.map((table) => table.businessName)),
//       ];
//     }

//     const columns = [
//       {
//         Header: "Business Name",
//         accessor: "businessName",
//         filterMethod: (filter, row) => {
//           if (filter.value === "all") {
//             return true;
//           }

//           if (row[filter.id] === filter.value) {
//             return row[filter.id];
//           }
//         },
//         Filter: ({ filter, onChange }) => {
//           return (
//             <select
//               onChange={(event) => onChange(event.target.value)}
//               style={{ width: "100%" }}
//               value={filter ? filter.value : "all"}
//             >
//               <option value="all">All</option>
//               {uniqueNames.map((name) => (
//                 <option value={name}>{name}</option>
//               ))}
//             </select>
//           );
//         },
//         Cell: (row) => {
//           return <this.renderLink data={row.row._original} value={row.value} />;
//         },
//       },
//       {
//         Header: "Status",
//         accessor: "status",
//         filterMethod: (filter, row) => {
//           if (filter.value === "all") {
//             return true;
//           }

//           if (filter.value === "Open") {
//             return row[filter.id] === 0;
//           }

//           if (filter.value === "Closed") {
//             return row[filter.id] === 1;
//           }
//         },
//         Filter: ({ filter, onChange }) => {
//           return (
//             <select
//               onChange={(event) => onChange(event.target.value)}
//               style={{ width: "100%" }}
//               value={filter ? filter.value : "all"}
//             >
//               <option value="all">All</option>
//               <option value="Open">Open</option>
//               <option value="Closed">Closed</option>
//             </select>
//           );
//         },

//         Cell: (row) => {
//           return (
//             <this.renderLink
//               data={row.row._original}
//               value={
//                 <div className={"name"}>
//                   <div className={row.value ? "open" : ""} />
//                   {row.value ? "Closed" : "Open"}
//                 </div>
//               }
//             />
//           );
//         },
//       },
//       {
//         Header: "Session No.",
//         accessor: "sesNo",
//         Cell: (row) => {
//           return <this.renderLink data={row.row._original} value={row.value} />;
//         },
//       },
//       {
//         Header: "Sales",
//         accessor: "sales",
//         Cell: (row) => {
//           return (
//             <this.renderLink
//               data={row.row._original}
//               value={`${row?.original?.currency} ${commaNumber(
//                 row.value.toFixed(2)
//               )}`}
//             />
//           );
//         },
//       },
//       {
//         Header: "Customers",
//         accessor: "customers",
//         Cell: (row) => {
//           return (
//             <this.renderLink
//               data={row.row._original}
//               value={`${commaNumber(row.value)}`}
//             />
//           );
//         },
//       },
//       {
//         Header: "Checks",
//         accessor: "checks",
//         Cell: (row) => {
//           return (
//             <this.renderLink
//               data={row.row._original}
//               value={`${commaNumber(row.value)}`}
//             />
//           );
//         },
//       },
//       {
//         Header: "Date / Time",
//         accessor: "date",
//         Cell: (row) => {
//           return (
//             <this.renderLink
//               data={row.row._original}
//               value={formatDate.long(row.value)}
//             />
//           );
//         },
//       },
//     ];

//     return (
//       <div>
//         {!this.state.load ? (
//           <div />
//         ) : (
//           <ReactTable
//             className={["-striped", "-highlight"].join(" ")}
//             data={this.state.data.table}
//             columns={columns}
//             sortable
//             filterable
//             getTdProps={() => ({
//               style: {
//                 textAlign: "center",
//               },
//             })}
//           />
//         )}
//       </div>
//     );
//   }
// }
