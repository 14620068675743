import React, { Component } from "react";

import Loader from "../../../../../../settings/loader";

import commaNumber from "comma-number";

import "./style.scss";

export default class Slider1 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        result: null,
        total: 0,
        legend: [],
      },
      load: 0,
    };
  }

  _initialized() {
    if (this.props.data && !this.state.load) {
      this.setState((prevstate) => ({
        data: {
          ...prevstate.data,
          result: this.props.data,
          legend: this.props.data.chains.data.map((data) => ({
            name:
              this.props.data.brands.data.find(
                (brand) => brand.braKey === data.braKey
              ).name +
              " | " +
              data.name,
            chaKey: data.chaKey,
            total: 0,
          })),
        },
        load: 1,
      }));
    }
  }

  loadData() {
    const legendData = this.state.data.legend
      .map((legend) => ({
        ...legend,
        total: this.state.data.result.sales.data
          .filter((sale) => legend.chaKey === sale.chaKey)
          .map((sale) => sale.customers)
          .reduce((a, b) => a + b, 0),
      }))
      .filter((data) => data.total);

    if (this.state.load) {
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          total: legendData
            .map((data) => data.total)
            .reduce((a, b) => a + b, 0),
          legend: legendData,
        },
        load: 2,
      }));
    }
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.export !== this.props.export) {
      this.props.getData({
        type: "table",
        title: "Total Customers",
        body: [
          {
            head: [["Outlet", "Total Customers"]],
            body: this.state.data.legend.map((value) => [
              value.name,
              `${commaNumber(value.total)}`,
            ]),
          },
        ],
      });
    }
  };

  render() {
    switch (this.state.load) {
      case 0:
        this._initialized();
        break;
      case 1:
        this.loadData();
        break;
      default:
        break;
    }

    return (
      <div>
        {!this.state.load ? (
          <Loader />
        ) : (
          <div className={["divider slide7"].join(" ")}>
            <h1 className={"main"}>{commaNumber(this.state.data.total)}</h1>
            <table
              className={["table", "table-striped", "defaultTable"].join(" ")}
            >
              <tbody>
                {this.state.data.legend.map((data, key) => (
                  <tr key={key}>
                    <td>{data.name}</td>
                    <td>{commaNumber(data.total)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    );
  }
}
