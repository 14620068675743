import React, { Component } from "react";

import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import atob from "atob";
import { formatDate } from "../../formatDate";

import "./style.scss";

export default class Navbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menu: [
        {
          name: "Account",
          icon: "user-circle",
          path:
            (this.props.match.path === "/" ? "/app" : this.props.match.path) +
            "/account",
        },
        {
          name: "Logout",
          icon: "sign-out-alt",
          path: "/login",
          method: () => {
            localStorage.clear();
            sessionStorage.clear();
          },
        },
      ],
    };
  }

  render() {
    return (
      <nav className={["navbar"]}>
        <div>
          {this.props.user.data ? (
            <div className={["brand"]}>
              <h1 className={["elems", "id"].join(" ")}>
                Welcome,{" "}
                <span>
                  {JSON.parse(atob(this.props.user.data.usrKey)).username}
                </span>
                !
              </h1>
              <h1 className={["elems", "date"].join(" ")}>
                {formatDate.long(new Date().getTime() / 1000)}
              </h1>
            </div>
          ) : (
            <div />
          )}
        </div>
        <ul className={["navLink"]}>
          {this.state.menu.map((menu, key) => (
            <li key={key}>
              <Link
                to={menu.path}
                onClick={() => (menu.method ? menu.method() : null)}
                className={["link"]}
              >
                <FontAwesomeIcon
                  className={["icon"].join(" ")}
                  icon={["fas", menu.icon]}
                />
                {menu.name}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    );
  }
}
