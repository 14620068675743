import React, { useEffect, useState } from "react";

import Window from "../../../../elements/window";

import Slide1 from "./slides/slide1";
import Slide2 from "./slides/slide2";

import Loader from "../../../../../settings/loader";

function SalesSummary(props) {
  const [sales, setSales] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        if (props?.user?.data) {
          await fetchData();
        }
      } catch (err) {
        console.log("Error occured when fetching");
      }
    })();
  }, []);

  const fetchData = async () => {
    const getSales = await fetch(
      props.chimeraserv.urlGet + "/by-sales-v2/" + props.filter.base64,
      {
        headers: {
          Authorization: props.user.data.usrKey,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );

    const jsonRes = await getSales.json();

    const chains = props?.filter?.data?.chains;

    const withNames = jsonRes?.map((item) => {
      return {
        ...item,
        currency: `${chains
          ?.find((chainItem) => chainItem?.chaKey == item?.chaKey)
          ?.currency?.toUpperCase()}`,
        restaurant_name: `${
          chains?.find((chainItem) => chainItem?.chaKey == item?.chaKey)?.name
        }`,
      };
    });

    await setSales(withNames);
  };

  return sales ? (
    <div className={["contentContainer"]}>
      <div className={["contentRow"]} style={{ width: "100%" }}>
        <Window
          user={props.user}
          chimeraserv={props.chimeraserv}
          title={"Sales by Summary"}
          horizontal
        >
          <Slide1
            {...props}
            slideData={JSON.parse(JSON.stringify(sales))}
            //striginfy to store as value not as reference so no original array changes when modify the copy
          />
        </Window>
      </div>

      <div className={["contentRow"]} style={{ width: "100%" }}>
        <Window
          user={props.user}
          chimeraserv={props.chimeraserv}
          title={"Open and Closed Sales"}
          horizontal
        >
          <Slide2 {...props} slideData={JSON.parse(JSON.stringify(sales))} />
        </Window>
      </div>
    </div>
  ) : (
    <Loader />
  );
}

export default SalesSummary;

// import React, { Component } from "react";

// import request from "request";

// import Window from "../../../../elements/window";

// import Loader from "../../../../../settings/loader";

// import Slide1 from "./slides/slide1";
// import Slide2 from "./slides/slide2";

// export default class sales extends Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       data: {
//         sales: {
//           data: [],
//           state: false,
//         },
//         revCent: {
//           data: [],
//           state: false,
//         },
//         brands: {
//           data: [],
//           state: false,
//         },
//         chains: {
//           data: [],
//           state: false,
//         },
//         currency: {
//           base: {},
//           state: false,
//         },
//         state: false,
//       },
//       load: 0,
//     };
//   }

//   getRevcent(headers, callback) {
//     request(
//       {
//         method: "GET",
//         url: this.props.chimeraserv.urlGet + "/revcent",
//         headers: headers,
//       },
//       (error, response, body) => {
//         callback(JSON.parse(body));
//       }
//     );
//   }

//   getCurrency(headers, callback) {
//     request(
//       {
//         method: "GET",
//         url: this.props.chimeraserv.urlGet + "/group",
//         headers: headers,
//       },
//       (error, response, body) => {
//         let data = [];

//         if (Object.keys(JSON.parse(body)).length) {
//           data = JSON.parse(body);
//         }

//         callback(data);
//       }
//     );
//   }

//   getBrand(headers, callback) {
//     request(
//       {
//         method: "GET",
//         url: this.props.chimeraserv.urlGet + "/brand",
//         headers: headers,
//       },
//       (error, response, body) => {
//         let data = [];

//         if (Object.keys(JSON.parse(body)).length) {
//           data = JSON.parse(body);
//         }

//         callback(data);
//       }
//     );
//   }

//   getChain(headers, callback) {
//     request(
//       {
//         method: "GET",
//         url: this.props.chimeraserv.urlGet + "/chain",
//         headers: headers,
//       },
//       (error, response, body) => {
//         let data = [];

//         if (Object.keys(JSON.parse(body)).length) {
//           data = JSON.parse(body);
//         }

//         callback(data);
//       }
//     );
//   }

//   getData(headers, callback) {
//     request(
//       {
//         method: "GET",
//         url:
//           this.props.chimeraserv.urlGet + "/sales/" + this.props.filter.base64,
//         headers: headers,
//       },
//       (error, response, body) => {
//         let allSales = JSON.parse(body);
//         let data = [];

//         if (Object.keys(allSales).length) {
//           data = allSales;
//         }

//         callback(data);
//       }
//     );
//   }

//   UNSAFE_componentWillMount() {
//     if (this.props.user.data) {
//       const headers = {
//         Authorization: this.props.user.data.usrKey,
//         "Content-Type": "application/json",
//         Accept: "application/json",
//       };

//       this.getData(headers, (data) => {
//         this.setState((prevState) => ({
//           data: {
//             ...prevState.data,
//             sales: {
//               data: data,
//               state: true,
//             },
//           },
//         }));
//       });

//       this.getRevcent(headers, (data) => {
//         this.setState((prevState) => ({
//           data: {
//             ...prevState.data,
//             revCent: {
//               data: data,
//               state: true,
//             },
//           },
//         }));
//       });

//       this.getChain(headers, (data) => {
//         this.setState((prevState) => ({
//           data: {
//             ...prevState.data,
//             chains: {
//               data: data,
//               state: true,
//             },
//           },
//         }));
//       });

//       this.getBrand(headers, (data) => {
//         this.setState((prevState) => ({
//           data: {
//             ...prevState.data,
//             brands: {
//               data: data,
//               state: true,
//             },
//           },
//         }));
//       });

//       this.getCurrency(headers, (data) => {
//         const array = [];

//         const currency = data
//           .map((group) => group.baseCurr)
//           .filter((currency) => !array.includes(currency));

//         this.setState((prevState) => ({
//           data: {
//             ...prevState.data,
//             currency: {
//               base: currency[0] ? currency[0]?.toUpperCase() : "QAR",
//               state: true,
//             },
//           },
//         }));
//       });
//     }
//   }

//   blockFilter() {
//     if (
//       !this.state.load &&
//       this.state.data.currency.state &&
//       this.state.data.sales.state &&
//       this.state.data.revCent.state &&
//       this.state.data.brands.state &&
//       this.state.data.chains.state
//     ) {
//       this.setState((prevState) => ({
//         data: {
//           ...prevState.data,
//           state: true,
//         },
//         load: true,
//       }));
//     }
//   }

//   render() {
//     return (
//       <section>
//         {this.blockFilter()}
//         {!this.state.load ? (
//           <Loader />
//         ) : (
//           <div>
//             <Window
//               user={this.props.user}
//               chimeraserv={this.props.chimeraserv}
//               title={"Sales by Summary"}
//             >
//               <Slide1
//                 {...this.state}
//                 user={this.props.user}
//                 chimeraserv={this.props.chimeraserv}
//                 filter={this.props.filter}
//                 data={this.state.data}
//               />
//             </Window>
//             <Window
//               user={this.props.user}
//               chimeraserv={this.props.chimeraserv}
//               title={"Open and Closed Sales"}
//             >
//               <Slide2
//                 {...this.state}
//                 user={this.props.user}
//                 chimeraserv={this.props.chimeraserv}
//                 filter={this.props.filter}
//                 data={this.state.data}
//               />
//             </Window>
//           </div>
//         )}
//       </section>
//     );
//   }
// }
