import React, { Component } from "react";

import "./style.scss";

import request from "request";

import btoa from "btoa";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker";

let date = new Date();

let today = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0);
today.setHours(0, 0, 0, 0);

let lastDay = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0);
lastDay.setHours(23, 59, 59, 59);

const sixMonthsBefore = new Date(
  date.getFullYear(),
  date.getMonth() - 6,
  date.getDate(),
  0
);
sixMonthsBefore.setHours(0, 0, 0, 0);

export default class filter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filters: {
        groups: {
          orig: [],
          list: [],
          state: false,
        },
        brands: {
          orig: [],
          list: [],
          state: false,
        },
        payType: {
          orig: [],
          list: [],
          state: false,
        },
        revCent: {
          orig: [],
          list: [],
          state: false,
        },
        chains: {
          orig: [],
          list: [],
          state: false,
        },
        date: {
          list: [
            {
              name: "6 Months",
              value: 0,
              status: false,
            },
            {
              name: "Monthly",
              value: 1,
              status: false,
            },
            {
              name: "Weekly",
              value: 2,
              status: false,
            },
            {
              name: "Daily",
              value: 3,
              status: false,
            },
          ],
          start: {
            long: today,
            short: parseInt(today.getTime() / 1000),
          },
          end: {
            long: lastDay,
            short: parseInt(lastDay.getTime() / 1000),
          },
        },
        misc: {
          list: [
            {
              name: "Payment Type",
              value: "payType",
              status: false,
            },
            {
              name: "Revenue Center",
              value: "revCent",
              status: false,
            },
          ],
          value: "",
        },
        state: false,
        load: false,
      },
      reSize: false,
    };
  }

  getPaytype(headers, callback) {
    request(
      {
        method: "GET",
        url: this.props.chimeraserv.urlGet + "/paytype",
        headers: headers,
      },
      (error, response, body) => {
        callback(JSON.parse(body));
      }
    );
  }

  getRevcent(headers, callback) {
    request(
      {
        method: "GET",
        url: this.props.chimeraserv.urlGet + "/revcent",
        headers: headers,
      },
      (error, response, body) => {
        callback(JSON.parse(body));
      }
    );
  }

  getGroup(headers, callback) {
    request(
      {
        method: "GET",
        url: this.props.chimeraserv.urlGet + "/group",
        headers: headers,
      },
      (error, response, body) => {
        let data = [];

        if (Object.keys(JSON.parse(body)).length) {
          data = JSON.parse(body);
        }

        callback(data);
      }
    );
  }

  getBrand(headers, callback) {
    request(
      {
        method: "GET",
        url: this.props.chimeraserv.urlGet + "/brand",
        headers: headers,
      },
      (error, response, body) => {
        let data = [];

        if (Object.keys(JSON.parse(body)).length) {
          data = JSON.parse(body);
        }

        callback(data);
      }
    );
  }

  getChain(headers, callback) {
    request(
      {
        method: "GET",
        url: this.props.chimeraserv.urlGet + "/chain",
        headers: headers,
      },
      (error, response, body) => {
        let data = [];

        if (Object.keys(JSON.parse(body)).length) {
          data = JSON.parse(body);
        }

        callback(data);
      }
    );
  }

  getData() {
    const headers = {
      Authorization: this.props.user.data.usrKey,
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    this.getPaytype(headers, (data) => {
      const list = data.map((item) => ({
        ...item,
        status: false,
      }));

      this.setState((prevState) => ({
        filters: {
          ...prevState.filters,
          payType: {
            orig: list,
            list: list,
            state: true,
          },
        },
      }));
    });

    this.getRevcent(headers, (data) => {
      const list = data.map((item) => ({
        ...item,
        status: false,
      }));

      this.setState((prevState) => ({
        filters: {
          ...prevState.filters,
          revCent: {
            orig: list,
            list: list,
            state: true,
          },
        },
      }));
    });

    this.getGroup(headers, (data) => {
      const list = data.map((item) => ({
        ...item,
        status: false,
      }));

      this.setState((prevState) => ({
        filters: {
          ...prevState.filters,
          groups: {
            orig: list,
            list: list,
            state: true,
          },
        },
      }));
    });

    this.getBrand(headers, (data) => {
      const list = data.map((item) => ({
        ...item,
        status: false,
      }));

      this.setState((prevState) => ({
        filters: {
          ...prevState.filters,
          brands: {
            orig: list,
            list: list,
            state: true,
          },
        },
      }));
    });

    this.getChain(headers, (data) => {
      const list = data.map((item) => ({
        ...item,
        status: false,
      }));

      this.setState((prevState) => ({
        filters: {
          ...prevState.filters,
          chains: {
            orig: list.map((chain) => {
              let name = chain.name;

              const brands = this.state.filters.brands.list.find(
                (brand) => chain.braKey === brand.braKey
              );

              if (brands) {
                name = `${brands.name
                  .split(" ")
                  .map((brand) =>
                    !brand.split("")[0] ? "" : brand.split("")[0].toUpperCase()
                  )
                  .join("")} - ${chain.name}`;
              }

              return {
                ...chain,
                name: name,
              };
            }),
            list: list.map((chain) => {
              let name = chain.name;

              const brands = this.state.filters.brands.list.find(
                (brand) => chain.braKey === brand.braKey
              );

              if (brands) {
                name = `${brands.name
                  .split(" ")
                  .map((brand) =>
                    !brand.split("")[0] ? "" : brand.split("")[0].toUpperCase()
                  )
                  .join("")} - ${chain.name}`;
              }

              return {
                ...chain,
                name: name,
              };
            }),
            state: true,
          },
        },
      }));
    });
  }

  blockFilter() {
    if (
      !this.state.filters.load &&
      this.state.filters.groups.state &&
      this.state.filters.brands.state &&
      this.state.filters.chains.state
    ) {
      this.setState((prevState) => ({
        filters: {
          ...prevState.filters,
          state: true,
          load: true,
        },
      }));
    }
  }

  valueChanged = (event) => {
    const data = event.target;

    let filter = [];
    let brands = [];
    let chains = [];
    let obj = {};
    let id;

    console.log(data);

    switch (data.name) {
      case "groups":
        filter = this.state.filters[data.name].list.map((option) => {
          if (option.grpKey === data.id) {
            return {
              ...option,
              status: option.status ? false : true,
            };
          }

          return { ...option };
        });

        if (filter.filter((group) => group.status).length) {
          let data = filter
            .filter((group) => group.status)
            .map((group) => group.grpKey);

          brands = this.state.filters.brands.orig.filter((brand) =>
            data.includes(brand.grpKey)
          );
          chains = this.state.filters.chains.orig.filter((chain) =>
            data.includes(chain.grpKey)
          );
        } else {
          brands = this.state.filters.brands.orig;
          chains = this.state.filters.chains.orig;
        }
        break;
      case "brands":
        filter = this.state.filters[data.name].list.map((option) => {
          if (option.braKey === data.id) {
            return {
              ...option,
              status: option.status ? false : true,
            };
          }

          return { ...option };
        });

        if (filter.filter((brand) => brand.status).length) {
          let data = filter
            .filter((group) => group.status)
            .map((group) => group.braKey);

          chains = this.state.filters.chains.orig.filter((chain) =>
            data.includes(chain.braKey)
          );
        } else {
          chains = this.state.filters.chains.orig;
        }
        break;
      case "chains":
        filter = this.state.filters[data.name].list.map((option) => {
          if (option.chaKey === data.id) {
            return {
              ...option,
              status: option.status ? false : true,
            };
          }

          return { ...option };
        });
        break;
      case "revCent":
        id = data.id.split(" | ")[0];

        filter = this.state.filters.revCent.list.map((option) => {
          if (option.id === parseInt(id)) {
            return {
              ...option,
              status: option.status ? false : true,
            };
          }

          return { ...option };
        });
        break;
      case "payType":
        id = data.id.split(" | ")[0];

        filter = this.state.filters.payType.list.map((option) => {
          if (option.id === parseInt(id)) {
            return {
              ...option,
              status: option.status ? false : true,
            };
          }

          return { ...option };
        });
        break;
      default:
        break;
    }

    if (brands.length) {
      obj.brands = {
        orig: this.state.filters.brands.orig,
        list: brands,
        state: true,
      };
    }

    if (chains.length) {
      obj.chains = {
        orig: this.state.filters.chains.orig,
        list: chains,
        state: true,
      };
    }

    this.setState((prevstate) => ({
      filters: {
        ...prevstate.filters,
        [data.name]: {
          ...prevstate.filters[data.name],
          list: filter,
        },
        ...obj,
      },
    }));
  };

  startDateChanged = (date) => {
    let { list } = this.state.filters.date;

    list = list.map((list) => ({ ...list, status: false }));

    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        date: {
          ...prevState.filters.date,
          list: list,
          start: {
            long: date,
            short: parseInt(date.getTime() / 1000),
          },
        },
      },
    }));
  };

  endDateChanged = (date) => {
    const selectedDate = date;
    selectedDate.setHours(23, 59, 59, 59);

    let { list } = this.state.filters.date;

    list = list.map((list) => ({ ...list, status: false }));

    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        date: {
          ...prevState.filters.date,
          list: list,
          end: {
            long: date,
            short: parseInt(selectedDate.getTime() / 1000),
          },
        },
      },
    }));
  };

  btnPress = (event) => {
    const data = event.target;

    let filter = {};

    switch (data.id) {
      case "date":
        filter.list = this.state.filters.date.list.map((date) => {
          if (date.value === data.value) {
            return {
              ...date,
              status: date.status ? false : true,
            };
          }

          return {
            ...date,
            status: false,
          };
        });

        let start = "";
        let end = "";

        let date = new Date();

        switch (parseInt(data.value)) {
          case 0: //6 Months
            // let firstYear = new Date(date.getFullYear(), 0);
            // firstYear.setHours(0, 0, 0, 0);

            // let lastYear = new Date(date.getFullYear(), 12, 0);
            // lastYear.setHours(23, 59, 59, 59);

            start = sixMonthsBefore.setDate(sixMonthsBefore.getDate());
            end = today.setDate(today.getDate());

            filter.list[0].status = true;
            filter.list[1].status = false;
            filter.list[2].status = false;
            filter.list[3].status = false;
            break;
          case 1: //Monthly
            let firstDayYear = new Date(
              date.getFullYear(),
              date.getMonth(),
              1,
              0
            );
            firstDayYear.setHours(0, 0, 0, 0);

            let lastDayYear = new Date(
              date.getFullYear(),
              date.getMonth() + 1,
              0
            );
            lastDayYear.setHours(23, 59, 59, 59);

            start = firstDayYear.setDate(firstDayYear.getDate());
            end = lastDayYear.setDate(lastDayYear.getDate());

            filter.list[0].status = false;
            filter.list[1].status = true;
            filter.list[2].status = false;
            filter.list[3].status = false;
            break;
          case 2: //Weekly
            let firstDayMonth = new Date(date.setDate(date.getDate() - 6));
            firstDayMonth.setHours(0, 0, 0, 0);
            let lastDayMonth = new Date();
            lastDayMonth.setHours(23, 59, 59, 59);

            start = firstDayMonth.setDate(firstDayMonth.getDate());
            end = lastDayMonth.setDate(lastDayMonth.getDate());

            filter.list[0].status = false;
            filter.list[1].status = false;
            filter.list[2].status = true;
            filter.list[3].status = false;
            break;
          case 3: //Daily
            let firstday = new Date(
              date.getFullYear(),
              date.getMonth(),
              date.getDate(),
              0
            );
            firstday.setHours(0, 0, 0, 0);
            let lastday = new Date(
              date.getFullYear(),
              date.getMonth(),
              date.getDate() + 1,
              0
            );
            lastday.setHours(23, 59, 59, 59);

            start = firstday.getTime();
            end = lastday.getTime();

            filter.list[0].status = false;
            filter.list[1].status = false;
            filter.list[2].status = false;
            filter.list[3].status = true;
            break;
          case 4: //Hourly
            date.setHours(0, 0, 0, 0);

            start = date.setDate(date.getDate());
            end = date.setDate(date.getDate() + 1);
            break;
          default:
            break;
        }

        filter.start = {
          long: new Date(start),
          short: parseInt(start / 1000),
        };
        filter.end = {
          long: new Date(end),
          short: parseInt(end / 1000),
        };
        break;
      default:
        break;
    }

    this.setState((prevstate) => ({
      filters: {
        ...prevstate.filters,
        [data.id]: {
          ...prevstate.filters[data.id],
          ...filter,
        },
      },
    }));
  };

  reSize = () => this.setState({ reSize: this.state.reSize ? false : true });

  componentDidMount() {
    // TODO: debug this for react udpdates
    this.__initial = this.getData();
  }

  componentWillUnmount = () => {
    this.__initial = null;
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.location !== this.props.location) {
      this.__initial = this.getData();
    }
  };

  setFilter() {
    let group = this.state.filters.groups.orig;
    let brand = this.state.filters.brands.orig;
    let chain = this.state.filters.chains.orig;
    let revCent = this.state.filters.revCent.orig;
    let payType = this.state.filters.payType.orig;

    if (this.state.filters.groups.list.filter((grp) => grp.status).length) {
      group = this.state.filters.groups.list.filter((grp) => grp.status);
      brand = this.state.filters.brands.orig.filter((bra) =>
        group.find((grp) => grp.grpKey === bra.grpKey)
      );
      chain = this.state.filters.chains.orig.filter((cha) =>
        brand.find((bra) => bra.braKey === cha.braKey)
      );

      if (this.state.filters.brands.list.filter((bra) => bra.status).length) {
        brand = this.state.filters.brands.list.filter((bra) => bra.status);
      }

      if (this.state.filters.chains.list.filter((cha) => cha.status).length) {
        chain = this.state.filters.chains.list.filter((cha) => cha.status);
      }
    }

    if (
      !this.state.filters.groups.list.filter((group) => group.status).length &&
      this.state.filters.brands.list.filter((brand) => brand.status).length
    ) {
      brand = this.state.filters.brands.list.filter((brand) => brand.status);
      group = this.state.filters.groups.orig.filter((grp) =>
        brand.find((bra) => bra.grpKey === grp.grpKey)
      );
      chain = this.state.filters.chains.orig.filter((cha) =>
        brand.find((bra) => bra.braKey === cha.braKey)
      );

      if (
        this.state.filters.chains.list.filter((chain) => chain.status).length
      ) {
        chain = this.state.filters.chains.list.filter((chain) => chain.status);
      }
    }

    if (
      !this.state.filters.groups.list.filter((group) => group.status).length &&
      !this.state.filters.brands.list.filter((brand) => brand.status).length &&
      this.state.filters.chains.list.filter((chain) => chain.status).length
    ) {
      chain = this.state.filters.chains.list.filter((cha) => cha.status);
      group = this.state.filters.groups.orig.filter((grp) =>
        chain.find((cha) => grp.grpKey === cha.grpKey)
      );
      brand = this.state.filters.brands.orig.filter((bra) =>
        chain.find((cha) => bra.braKey === cha.braKey)
      );
    }

    if (
      this.state.filters.revCent.list.filter((revCent) => revCent.status).length
    ) {
      revCent = this.state.filters.revCent.list.filter(
        (revCent) => revCent.status
      );
    }

    if (
      this.state.filters.payType.list.filter((payType) => payType.status).length
    ) {
      payType = this.state.filters.payType.list.filter(
        (payType) => payType.status
      );
    }

    let date = {
      start: this.state.filters.date.start.short,
      end: this.state.filters.date.end.short,
    };

    this.props.setFilter({
      data: {
        groups: group,
        brands: brand,
        chains: chain,
        revCent: revCent,
        payType: payType,
        date: {
          start: date.start,
          end: date.end,
        },
      },
      base64: btoa(
        JSON.stringify({
          grpKey: { $in: group.map((group) => group.grpKey) },
          braKey: { $in: brand.map((brand) => brand.braKey) },
          chaKey: { $in: chain.map((chain) => chain.chaKey) },
          revCent: { $in: revCent.map((revCent) => revCent.name) },
          // payType: {$elemMatch: {name: {$in: payType.map(payType => payType.name)}}},
          date: {
            $gt: date.start,
            $lt: date.end,
          },
        })
      ),
    });
  }

  render() {
    return (
      <section
        className={["filter", this.state.reSize ? "hideWin" : ""].join(" ")}
      >
        {this.blockFilter()}
        <div className={[this.state.filters.state ? "" : "disabled"]} />
        <div className={["params", this.state.reSize ? "hide" : ""].join(" ")}>
          <div>
            <h2>
              <FontAwesomeIcon
                className={["icon"].join(" ")}
                icon={["fas", "layer-group"]}
              />
              Group
            </h2>
            <div className={["paramBody"]}>
              <div className={["custom-control", "custom-checkbox"].join(" ")}>
                {this.state.filters.groups.list.map((data, key) => {
                  return (
                    <div key={key}>
                      <input
                        type={"checkbox"}
                        className={["custom-control-input"]}
                        name="groups"
                        id={data.grpKey}
                        onChange={this.valueChanged}
                        checked={data.status}
                      />
                      <label
                        className={"custom-control-label"}
                        htmlFor={data.grpKey}
                      >
                        {data.name}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div>
            <h2>
              <FontAwesomeIcon
                className={["icon"].join(" ")}
                icon={["fas", "store"]}
              />
              Brand
            </h2>
            <div
              className={
                this.state.filters.groups.list.filter((group) => group.status)
                  .length
                  ? "disabledBox"
                  : ""
              }
            />
            <div className={["paramBody"]}>
              <div className={["custom-control", "custom-checkbox"].join(" ")}>
                {this.state.filters.brands.list.map((data, key) => {
                  return (
                    <div key={key}>
                      <input
                        type={"checkbox"}
                        className={["custom-control-input"]}
                        name="brands"
                        id={data.braKey}
                        onChange={this.valueChanged}
                        checked={data.status}
                      />
                      <label
                        className={"custom-control-label"}
                        htmlFor={data.braKey}
                      >
                        {data.name}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div>
            <h2>
              <FontAwesomeIcon
                className={["icon"].join(" ")}
                icon={["fas", "tags"]}
              />
              Outlet
            </h2>
            <div
              className={
                this.state.filters.groups.list.filter((group) => group.status)
                  .length
                  ? "disabledBox"
                  : ""
              }
            />
            <div className={["paramBody"]}>
              <div className={["custom-control", "custom-checkbox"].join(" ")}>
                {this.state.filters.chains.list.map((data, key) => {
                  return (
                    <div key={key}>
                      <input
                        type={"checkbox"}
                        className={["custom-control-input"]}
                        name="chains"
                        id={data.chaKey}
                        onChange={this.valueChanged}
                        checked={data.status}
                      />
                      <label
                        className={"custom-control-label"}
                        htmlFor={data.chaKey}
                      >
                        {data.name}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div>
            <h2>
              <FontAwesomeIcon
                className={["icon"].join(" ")}
                icon={["fas", "ellipsis-h"]}
              />
              Misc.
            </h2>
            {this.props.location !== "/app/sales/session" ? (
              <div className={["paramBody"]}>
                <h1>Revenue Center</h1>
                <div
                  className={["custom-control", "custom-checkbox"].join(" ")}
                >
                  {this.state.filters.revCent.list.map((data, key) => {
                    return (
                      <div key={key}>
                        <input
                          type={"checkbox"}
                          className={["custom-control-input"]}
                          name="revCent"
                          id={`${data.id} | revCent`}
                          onChange={this.valueChanged}
                          checked={data.status}
                        />
                        <label
                          className={"custom-control-label"}
                          htmlFor={`${data.id} | revCent`}
                        >
                          {data.name}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div>
            <h2>
              <FontAwesomeIcon
                className={["icon"].join(" ")}
                icon={["fas", "calendar-alt"]}
              />
              Date
            </h2>
            <div className={["paramBody"]}>
              <div className={["paramContainer"]}>
                <p>Date Range</p>
                <div className={["dateRange"]}>
                  <div>
                    <p>Start</p>
                    <DatePicker
                      selected={this.state.filters.date.start.long}
                      // selected={sixMonthsBefore}
                      onChange={this.startDateChanged}
                      name={"date"}
                      id={"start"}
                      todayButton={"Today"}
                      maxDate={new Date()}
                      minDate={sixMonthsBefore}
                    />
                  </div>
                  <div>
                    <p>End</p>
                    <DatePicker
                      selected={this.state.filters.date.end.long}
                      onChange={this.endDateChanged}
                      name={"date"}
                      todayButton={"Today"}
                      minDate={this.state.filters.date.start.long}
                      id={"end"}
                      // maxDate={new Date()}
                    />
                  </div>
                </div>
                <p>Set by date</p>
              </div>
              <div className={["options"]}>
                {this.state.filters.date.list.map((data, key) => {
                  return (
                    <button
                      key={key}
                      className={[data.status ? "selected" : ""].join(" ")}
                      onClick={(event) => this.btnPress(event)}
                      value={data.value}
                      id={"date"}
                    >
                      {data.name}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className={["footer"]}>
          <div className={["footerHead"]}>
            <button onClick={() => this.reSize()}>
              <FontAwesomeIcon
                className={["icon"].join(" ")}
                icon={[
                  "fas",
                  this.state.reSize ? "plus-square" : "minus-square",
                ]}
              />
            </button>
            <h2>Insights Report</h2>
          </div>
          <button
            className={[this.state.reSize ? "hide" : ""]}
            onClick={() => this.setFilter()}
            disabled={this.state.filters.state ? false : true}
          >
            Generate Report
          </button>
        </div>
      </section>
    );
  }
}
