import React, { Component } from "react";

import { Route, Switch } from "react-router-dom";

import { Helmet } from "react-helmet";

import Filter from "../../elements/filter";
import ReportSummary from "../../elements/reportSummary";

import AuditSummary from "./views/auditSummary";
import DeletionSummary from "./views/deletionSummary";
import VoidSummary from "./views/voidSummary";

import "../style.scss";

export default class sales extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: null,
      page: null,
      routes: [
        {
          name: "Audit Summary",
          path: "/logs",
          component: AuditSummary,
        },
        {
          name: "Void Summary",
          path: "/voids",
          component: VoidSummary,
        },
        {
          name: "Deletion Summary",
          path: "/deletion",
          component: DeletionSummary,
        },
      ],
    };
  }

  setFilter = (filters) => {
    this.setState({
      filter: null,
    });

    setTimeout(() => {
      this.setState({
        filter: filters,
        page: this.props.location.pathname,
      });
    }, 500);
  };

  checkPage() {
    if (this.props.location.pathname !== this.state.page) {
      setTimeout(() => {
        this.setState({
          filter: null,
          page: this.props.location.pathname,
        });
      }, 500);
    }
  }

  UNSAFE_componentWillMount() {
    this.setState({
      page: this.props.location.pathname,
    });
  }

  renderTitle = (title) => {
    let name = "Audit";

    if (title) {
      name = title.name + " | Audit";

      if (this.props.location.pathname.split("/").length === 3) {
        name = "Audit Summary";
      }
    }

    return (
      <Helmet>
        <title>{`${name} | Chimera Solutions | Insights`}</title>
      </Helmet>
    );
  };

  render() {
    this.checkPage();

    let title = this.state.routes.find(
      (route) => route.path === "/" + this.props.location.pathname.split("/")[3]
    );

    if (this.props.location.pathname.split("/").length === 3) {
      title = { name: "Audit Summary" };
    }

    return (
      <section>
        {this.renderTitle(title)}
        <Filter
          user={this.props.user}
          chimeraserv={this.props.chimeraserv}
          setFilter={this.setFilter}
        />
        <div className={["report"]}>
          <div className={["viewTitle"]}>{title ? title.name : <div />}</div>
          <Switch>
            <Route
              exact
              path={this.props.match.path}
              render={(routerProps) =>
                this.state.filter ? (
                  <section>
                    <ReportSummary filter={this.state.filter} />
                    <AuditSummary
                      {...routerProps}
                      user={this.props.user}
                      chimeraserv={this.props.chimeraserv}
                      filter={this.state.filter}
                    />
                  </section>
                ) : (
                  <div />
                )
              }
            />

            {this.state.routes.map((route, key) => (
              <Route
                key={key}
                path={this.props.match.path + route.path}
                render={(routerProps) =>
                  this.state.filter ? (
                    <section>
                      <ReportSummary filter={this.state.filter} />
                      <route.component
                        {...routerProps}
                        user={this.props.user}
                        chimeraserv={this.props.chimeraserv}
                        filter={this.state.filter}
                      />
                    </section>
                  ) : (
                    <div />
                  )
                }
              />
            ))}

            <Route component={this.props.components.setting[0].component} />
          </Switch>
        </div>
      </section>
    );
  }
}
