import React, { Component } from "react";

import CanvasJSReact from "../../../../../../../lib/canvasjs/canvasjs.react";

import Loader from "../../../../../../settings/loader";

import commaNumber from "comma-number";

import "./style.scss";

const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;
CanvasJS.addColorSet("barChart4", [
  "#FFA32E",
  "#E38029",
  "#FA8039",
  "#E6693C",
  "#FA684B",
]);

export default class Slider2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        result: null,
        cols: [],
        chart: [],
        chains: [],
      },
      load: 0,
    };
  }

  addSymbols(e) {
    let suffixes = ["", "K", "M", "B"];
    let order = Math.max(Math.floor(Math.log(e.value) / Math.log(1000)), 0);
    if (order > suffixes.length - 1) order = suffixes.length - 1;
    let suffix = suffixes[order];
    return CanvasJS.formatNumber(e.value / Math.pow(1000, order)) + suffix;
  }

  _initialized() {
    if (this.props.data && !this.state.load) {
      this.setState((prevstate) => ({
        data: {
          ...prevstate.data,
          result: this.props.data,
          cols: this.props.data.revCent.data.map((data, key) => ({
            name: data.name,
            key: key,
          })),
          chains: this.props.data.chains.data.map((data, key) => ({
            name: data.name,
            braKey: data.braKey,
            chaKey: data.chaKey,
            key: key,
          })),
        },
        load: 1,
      }));
    }
  }

  loadData() {
    const data = this.state.data.result.chains.data
      .map((chain) => {
        const revCent = this.state.data.cols.map((rev) => ({
          ...rev,
          total: this.state.data.result.sales.data
            .filter(
              (sale) =>
                sale.revCent === rev.name && chain.chaKey === sale.chaKey
            )
            .map((sale) => sale.total)
            .reduce((a, b) => a + b, 0),
        }));

        return {
          y: revCent.reduce((a, b) => a + b.total, 0),
          label:
            this.state.data.result.brands.data.find(
              (brand) => brand.braKey === chain.braKey
            ).name +
            " | " +
            chain.name,
          toolTipContent: `{label} : {y} {currency}`,
          currency: chain?.currency?.toUpperCase(),
        };
      })
      .filter((data) => data.y);

    if (this.state.load) {
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          chart: data,
        },
        load: 2,
      }));
    }
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.export !== this.props.export) {
      this.props.getData({
        type: "image",
        title: "Sales By Revenue Center",
        filter: {
          brands: this.props?.filter.data.brands.map((brand) => brand.name),
          chains: this.props?.filter.data.chains.map((chain) => chain.name),
          revCent: this.props?.filter.data.revCent.map(
            (revCent) => revCent.name
          ),
          payType: this.props?.filter.data.payType.map(
            (payType) => payType.name
          ),
          date: {
            start: this.props?.filter.data.date.start,
            end: this.props?.filter.data.date.end,
          },
        },
        body: {
          table: [
            {
              head: [["Revenue Center", "Total"]],
              body: [
                ...this.state.data.chart.map((data) => [
                  data.label,
                  `${data?.currency} ${commaNumber(data.y.toFixed(2))}`,
                ]),
              ],
            },
          ],
          id: "exportPdf_Slide3",
        },
      });
    }
  };

  render() {
    switch (this.state.load) {
      case 0:
        this._initialized();
        break;
      case 1:
        this.loadData();
        break;
      default:
        break;
    }

    const chainsCurrencies = this.state?.data?.chart?.map((item) =>
      item?.currency?.toLowerCase()
    );

    const isEqualCurr = chainsCurrencies?.every(
      (val, i, arr) => val === arr[0]
    );

    const options = {
      colorSet: "barChart4",
      axisX: {
        interval: 1,
        interlacedColor: "#f0f8ff",
      },
      axisY: {
        // prefix: `${this.props.data.currency.base} `,
        gridColor: "#d6d6d6",
        gridThickness: 1,
        prefix: isEqualCurr
          ? `${
              chainsCurrencies[0]?.toUpperCase() ||
              this.props.data.currency.base.toUpperCase()
            }`
          : "",
      },
      toolTip: {
        shared: true,
      },
      data: [
        {
          type: "bar",
          // indexLabel: "{y}",
          // yValueFormatString: `${this.props.data.currency.base} #,##0.00`,
          yValueFormatString: `#,##0.00`,
          dataPoints: this.state.data.chart,
        },
      ],
    };

    if (
      this.state.data.result &&
      this.state.data.result.chains.data.length <= 2
    ) {
      delete options.axisX.interval;
    }

    return (
      <div id={"exportPdf_Slide3"}>
        {!this.state.load ? <Loader /> : <CanvasJSChart options={options} />}
      </div>
    );
  }
}
