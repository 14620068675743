import React, { Component } from "react";

import { Link, Route, Switch } from "react-router-dom";

import { Helmet } from "react-helmet";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ListUser from "./views/listUser";
import CreateUser from "./views/createUser";
import ViewUser from "./views/viewUser";
import EditUser from "./views/editUser";
import EditAccountUser from "./views/editAccountUser";

import "../style.scss";

export default class Client extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: "Manage User" + " | " + "Chimera Solutions | Insights",
      routes: [
        {
          name: "ListUser",
          path: "/list",
          component: ListUser,
        },
        {
          name: "CreateUser",
          path: "/create",
          auth: true,
          level: [0, 1],
          component: CreateUser,
        },
        {
          name: "ViewUser",
          path: "/view/:uid",
          component: ViewUser,
        },
        {
          name: "EditUser",
          path: "/edit/:uid",
          auth: true,
          level: [0],
          component: EditUser,
        },
        {
          name: "EditAccount",
          path: "/edit-account/:uid",
          component: EditAccountUser,
        },
      ],
    };
  }

  renderTitle = () => (
    <Helmet>
      <title>{this.state.title}</title>
    </Helmet>
  );

  renderComponent = (routerProps, route) => {
    let Component = () => (
      <Route component={this.props.components.setting[0].component} />
    );

    if (route.auth) {
      if (
        route.level.filter((level) => level === this.props.user.data.level)
          .length
      ) {
        Component = () => (
          <route.component
            {...routerProps}
            user={this.props.user}
            chimeraserv={this.props.chimeraserv}
            components={this.props.components}
          />
        );
      } else {
        Component = () => (
          <Route component={this.props.components.setting[0].component} />
        );
      }
    } else {
      Component = () => (
        <route.component
          {...routerProps}
          user={this.props.user}
          chimeraserv={this.props.chimeraserv}
          components={this.props.components}
        />
      );
    }

    return Component();
  };

  render() {
    return (
      <div className={"viewContainer"}>
        {this.renderTitle()}
        <div className={["heading"]}>
          {[0, 1].includes(this.props.user.data.level) ? (
            <Link to={this.props.match.path + "/create"}>
              <FontAwesomeIcon
                className={["icon"].join(" ")}
                icon={["fas", "plus"]}
              />{" "}
              Create New User
            </Link>
          ) : (
            <div />
          )}
          <Link to={this.props.match.path + "/list"}>List of Users</Link>
        </div>
        <div className={["viewBody"]}>
          <Switch>
            <Route
              exact
              path={this.props.match.path}
              render={(routerProps) => (
                <ListUser
                  {...routerProps}
                  user={this.props.user}
                  chimeraserv={this.props.chimeraserv}
                  components={this.props.components}
                />
              )}
            />

            {this.state.routes.map((route, key) => (
              <Route
                key={key}
                path={this.props.match.path + route.path}
                render={(routerProps) =>
                  this.renderComponent(routerProps, route)
                }
              />
            ))}

            <Route component={this.props.components.setting[0].component} />
          </Switch>
        </div>
      </div>
    );
  }
}
