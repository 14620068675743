import React, { Component } from "react";
import ReactTable from "react-table-v6";
import Loader from "../../../../../../settings/loader";
import commaNumber from "comma-number";
import "react-table-v6/react-table.css";
import "./style.scss";

export default class Slider4 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        result: null,
        table: [],
      },
      load: 0,
    };
  }

  _initialized() {
    if (this.props.data && !this.state.load) {
      this.setState((prevstate) => ({
        data: {
          ...prevstate.data,
          result: this.props.data,
        },
        load: 1,
      }));
    }
  }

  loadData() {
    if (this.state.load) {
      const revCent = this.state.data.result.revCent.data.map(
        (revCent, key) => ({
          ...revCent,
          key: key,
          checks: 0,
          total: 0,
          percentage: 0,
        })
      );

      this.state.data.result.sales.data.forEach((sale) => {
        const data = revCent.find((revCent) => revCent.name === sale.revCent);

        if (data) {
          revCent[data.key].checks = revCent[data.key].checks + 1;
          revCent[data.key].total = revCent[data.key].total + sale.total;
        }
      });

      const totalSum = revCent.reduce((a, b) => a + b.total, 0);

      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          table: revCent.sort(this.sortSales).map((rev) => ({
            ...rev,
            percentage: !(rev.total / totalSum)
              ? 0
              : parseFloat((rev.total / totalSum) * 100).toFixed(2),
          })),
        },
        load: 2,
      }));
    }
  }

  sortSales(a, b) {
    if (parseInt(a.total) > parseInt(b.total)) {
      return -1;
    }

    if (parseInt(a.total) < parseInt(b.total)) {
      return 1;
    }

    return 0;
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.export !== this.props.export) {
      this.props.getData({
        type: "table",
        title: "Revenue Center by Percentage",
        filter: {
          brands: this.props.filter.data.brands.map((brand) => brand.name),
          chains: this.props.filter.data.chains.map((chain) => chain.name),
          revCent: this.props.filter.data.revCent.map(
            (revCent) => revCent.name
          ),
          payType: this.props.filter.data.payType.map(
            (payType) => payType.name
          ),
          date: {
            start: this.props.filter.data.date.start,
            end: this.props.filter.data.date.end,
          },
        },
        body: [
          {
            head: [["Revenue Center", "Checks", "Percentage", "Total"]],
            body: this.state.data.table.map((value) => [
              value.name,
              commaNumber(value.checks),
              `${value.percentage}%`,
              `${this.props.data.currency.base?.toUpperCase()} ${commaNumber(
                value.total.toFixed(2)
              )}`,
            ]),
          },
        ],
      });
    }
  };

  render() {
    switch (this.state.load) {
      case 0:
        this._initialized();
        break;
      case 1:
        this.loadData();
        break;
      default:
        break;
    }

    const columns = [
      {
        Header: "Revenue Center",
        accessor: "name",
      },
      {
        id: "checks",
        Header: "Checks",
        accessor: "checks",
        Cell: (row) => {
          return `${commaNumber(row.value)}`;
        },
      },
      {
        id: "percentage",
        Header: "Percentage",
        accessor: (row) => parseFloat(row.percentage),
        Cell: (row) => {
          return `${commaNumber(row.value)}%`;
        },
      },
      {
        id: "total",
        Header: "Total",
        accessor: "total",
        Cell: (row) => {
          return `${this.props.data.currency.base?.toUpperCase()} ${commaNumber(
            row.value.toFixed(2)
          )}`;
        },
      },
    ];

    return (
      <div>
        {!this.state.load ? (
          <Loader />
        ) : (
          <ReactTable
            className={["-striped", "-highlight"].join(" ")}
            data={this.state.data.table}
            columns={columns}
            sortable
            filterable
            getTdProps={() => ({
              style: {
                textAlign: "center",
              },
            })}
          />
        )}
      </div>
    );
  }
}
