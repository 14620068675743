import React, { Component } from "react";

import { Route, Switch } from "react-router-dom";
import Breadcrumbs from "./breadcrumbs";

import "./style.scss";

export default class Body extends Component {
  render() {
    return (
      <section className={["body"]}>
        <Breadcrumbs path={this.props.location.pathname} />
        <Switch>
          <Route
            exact
            path={this.props.match.path}
            render={(routerProps) => {
              let Component = this.props.components.page[0].component;

              return (
                <Component
                  {...routerProps}
                  user={this.props.user}
                  chimeraserv={this.props.chimeraserv}
                  components={this.props.components}
                />
              );
            }}
          />

          {this.props.components.page.map((route, key) => {
            if (route.auth) {
              if (
                this.props.user.data &&
                (this.props.user.data.type === "admin" ||
                  this.props.user.data.level === 1)
              ) {
                return (
                  <Route
                    key={key}
                    path={this.props.match.path + route.path}
                    render={(routerProps) => (
                      <route.component
                        {...routerProps}
                        user={this.props.user}
                        chimeraserv={this.props.chimeraserv}
                        components={this.props.components}
                      />
                    )}
                  />
                );
              } else {
                return (
                  <Route
                    key={key}
                    component={this.props.components.setting[0].component}
                  />
                );
              }
            }

            return (
              <Route
                key={key}
                path={this.props.match.path + route.path}
                render={(routerProps) => (
                  <route.component
                    {...routerProps}
                    user={this.props.user}
                    chimeraserv={this.props.chimeraserv}
                    components={this.props.components}
                  />
                )}
              />
            );
          })}

          <Route component={this.props.components.setting[0].component} />
        </Switch>
      </section>
    );
  }
}
