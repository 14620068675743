import React, { useEffect, useState } from "react";

import Window from "../../../../elements/window";

import Slide1 from "./slides/slide1";
import Slide2 from "./slides/slide2";
import Slide3 from "./slides/slide3";

import Loader from "../../../../../settings/loader";

function Group(props) {
  const [salesByGroup, setSalesByGroup] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        if (props?.user?.data) {
          await fetchData();
        }
      } catch (err) {
        console.log("Error occured when fetching");
      }
    })();
  }, []);

  const fetchData = async () => {
    const getSales = await fetch(
      props.chimeraserv.urlGet + "/group-type-v2/" + props.filter.base64,
      {
        headers: {
          Authorization: props.user.data.usrKey,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );

    const jsonRes = await getSales.json();

    const chains = props?.filter?.data?.chains;

    const withNames = jsonRes?.map((item) => {
      return {
        ...item,
        currency: `${chains
          ?.find((chainItem) => chainItem?.chaKey == item?.chainKey)
          ?.currency?.toUpperCase()}`,
        restaurant_name: `${
          chains?.find((chainItem) => chainItem?.chaKey == item?.chainKey)?.name
        }`,
      };
    });

    await setSalesByGroup(withNames);
  };

  return salesByGroup ? (
    <div className={["contentContainer"]}>
      <div className={["contentRow"]} style={{ width: "100%" }}>
        <Window
          user={props.user}
          chimeraserv={props.chimeraserv}
          title={"Group Types"}
          horizontal
        >
          <Slide1
            {...props}
            slideData={JSON.parse(JSON.stringify(salesByGroup))}
            //striginfy to store as value not as reference so no original array changes when modify the copy
          />
        </Window>
      </div>

      <div className={["contentRow"]} style={{ width: "49%" }}>
        <Window
          user={props.user}
          chimeraserv={props.chimeraserv}
          title={"Group Types"}
          horizontal
        >
          <Slide2
            {...props}
            slideData={JSON.parse(JSON.stringify(salesByGroup))}
            //striginfy to store as value not as reference so no original array changes when modify the copy
          />
        </Window>
      </div>

      <div className={["contentRow"]} style={{ width: "49%" }}>
        <Window
          user={props.user}
          chimeraserv={props.chimeraserv}
          title={"Group Types"}
          horizontal
        >
          <Slide3
            {...props}
            slideData={JSON.parse(JSON.stringify(salesByGroup))}
            //striginfy to store as value not as reference so no original array changes when modify the copy
          />
        </Window>
      </div>
    </div>
  ) : (
    <Loader />
  );
}

export default Group;
