import React, { Component } from "react";

import { Helmet } from "react-helmet";

import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import request from "request";

export default class ViewClient extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: "Brand Info" + " | " + "Chimera Solutions | Insights",
      data: [],
      load: false,
    };

    this.headers = {
      Authorization: this.props.user.data.usrKey,
      "Content-Type": "application/json",
      Accept: "application/json",
    };
  }

  outletChange = (groupData, group, brand, chain) => {
    let groupIndex = groupData.brands
      .map((brands, index) => ({
        ...brands,
        chains: brands.chains
          .map((chains, key) => ({ ...chains, index: key }))
          .find((chains) => chains.chaKey === chain.chaKey),
        index: index,
      }))
      .find((brands) => brands.braKey === chain.brand.braKey);

    groupData.brands[groupIndex.index].chains[groupIndex.chains.index].status =
      groupData.brands[groupIndex.index].chains[groupIndex.chains.index]
        .status === 1
        ? 0
        : 1;

    let data = {
      ...groupData,
    };

    request(
      {
        method: "PUT",
        url: this.props.chimeraserv.urlPost + "/group/update",
        headers: {
          Authorization: this.props.user.data.usrKey,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: data,
        json: true,
      },
      (error, response, body) => false
    );
  };

  valueChange = (data, key) => {
    data.status = data.status === 1 ? 0 : 1;

    request(
      {
        method: "PUT",
        url: this.props.chimeraserv.urlPost + "/group/update",
        headers: {
          Authorization: this.props.user.data.usrKey,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: data,
        json: true,
      },
      (error, response, body) => {
        this.setState((prevState) => ({
          load: false,
        }));

        this.setState((prevState) => ({
          data: [data],
          load: true,
        }));
      }
    );
  };

  renderTitle = () => (
    <Helmet>
      <title>{this.state.title}</title>
    </Helmet>
  );

  loadData = () =>
    request(
      {
        method: "GET",
        url: this.props.chimeraserv.urlGet + "/group/",
        headers: this.headers,
      },
      (error, response, body) =>
        this.setState((prevState) => ({
          data: JSON.parse(body).filter((group) =>
            group.brands.find(
              (brand) => brand.braKey === this.props.match.params.id
            )
          ),
          load: true,
        }))
    );

  UNSAFE_componentWillMount = () => {
    this._initial = this.loadData();
  };

  componentWillUnmout = () => {
    this._initial = null;
  };

  render() {
    let Loader = this.props.components.setting[1].component;

    return (
      <div className={["custForm", "custView"].join(" ")}>
        {this.renderTitle()}
        <div className={["viewTitle"]}>Brand Info</div>
        {!this.state.load ? (
          <Loader />
        ) : (
          <div>
            <div className={["form", "fullWidth"].join(" ")}>
              {this.state.data.map((data) =>
                data.brands
                  .filter((data) => data.braKey === this.props.match.params.id)
                  .map((data, key) => (
                    <div>
                      <div className={["form-group"]}>
                        <label htmlFor={["exampleInputEmail1"]}>
                          Brand Name
                        </label>
                        <div className={["field", "value"].join(" ")}>
                          {data.name}
                        </div>
                      </div>
                      <div className={["form-group"]}>
                        <label htmlFor={["exampleInputEmail1"]}>Status</label>
                        <div className={["field", "value"].join(" ")}>
                          {data.status ? "Active" : "Inactive"}
                        </div>
                      </div>
                      {this.props.user.data.level === 0 ? (
                        <div className={["actions", "actionsAdmin"].join(" ")}>
                          <Link
                            to={
                              this.props.match.path
                                .split("/")
                                .splice(0, 3)
                                .join("/") +
                              "/main/edit/" +
                              data.braKey
                            }
                            className={["btn btn-primary"]}
                          >
                            Edit
                          </Link>
                          <button
                            type={"button"}
                            // onClick={() => this.valueChange(data, key)}
                            className={["btn", "btn-primary", "disable"].join(
                              " "
                            )}
                          >
                            {data.status ? "Disable" : "Enable"}
                          </button>
                        </div>
                      ) : (
                        <div />
                      )}
                    </div>
                  ))
              )}
            </div>
            <div className={["form", "fullWidth"].join(" ")}>
              {[].concat
                .apply(
                  [],
                  this.state.data.map((data) => ({
                    group: data,
                    brands: data.brands.filter(
                      (brand) => brand.braKey === this.props.match.params.id
                    ),
                  }))
                )
                .map((data, key) => (
                  <div>
                    <div className={["form-group"]}>
                      <label htmlFor={["exampleInputEmail1"]}>Brand Key</label>
                      <div className={["field", "value"].join(" ")}>
                        {data.brands.find(
                          (brand) => brand.braKey === this.props.match.params.id
                        )
                          ? data.brands.find(
                              (brand) =>
                                brand.braKey === this.props.match.params.id
                            ).braKey
                          : ""}
                      </div>
                    </div>
                    <div className={["form-group"]}>
                      <label htmlFor={["exampleInputEmail1"]}>Outlets</label>
                      <table className={["table", "table-striped"].join(" ")}>
                        <thead>
                          <tr>
                            <th>Brand</th>
                            <th>Outlet</th>
                            <th>Status</th>
                            <th>Chain Key</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {[].concat
                            .apply(
                              [],
                              data.brands.map((brand, braKey) =>
                                brand.chains.map((chain) => ({
                                  data: data,
                                  group: {
                                    index: key,
                                    grpKey: data.grpKey,
                                    _id: data._id,
                                    name: data.name,
                                  },
                                  brand: {
                                    index: braKey,
                                    braKey: brand.braKey,
                                    name: brand.name,
                                  },
                                  ...chain,
                                }))
                              )
                            )
                            .map((chain) => (
                              <tr>
                                <td>{chain.brand.name}</td>
                                <td>{chain.name}</td>
                                <td>
                                  <div className={"name"}>
                                    <div
                                      className={[
                                        "status",
                                        chain.info.status ? "" : "offline",
                                      ].join(" ")}
                                    />
                                    {chain.info.status ? "Online" : "Offline"}
                                  </div>
                                </td>
                                <td>{chain.chaKey}</td>
                                <td className={["actions"]}>
                                  {this.props.user.data.level === 0 ? (
                                    <div
                                      className={[
                                        "actions",
                                        "actionsAdmin",
                                      ].join(" ")}
                                    >
                                      <div
                                        className={[
                                          "custom-control",
                                          "custom-switch",
                                        ].join(" ")}
                                      >
                                        <input
                                          type={"checkbox"}
                                          className={"custom-control-input"}
                                          id={chain.chaKey}
                                          defaultChecked={
                                            chain.status ? true : false
                                          }
                                          onChange={() =>
                                            this.outletChange(
                                              chain.data,
                                              chain.group,
                                              chain.brand,
                                              chain,
                                              key
                                            )
                                          }
                                        />
                                        <label
                                          className={"custom-control-label"}
                                          htmlFor={chain.chaKey}
                                        />
                                      </div>
                                      <Link
                                        to={
                                          this.props.match.path
                                            .split("/")
                                            .splice(0, 3)
                                            .join("/") +
                                          "/outlet/edit/" +
                                          chain.chaKey
                                        }
                                      >
                                        <FontAwesomeIcon
                                          className={["icon"].join(" ")}
                                          icon={["fas", "cog"]}
                                        />{" "}
                                        Edit
                                      </Link>
                                    </div>
                                  ) : (
                                    <div />
                                  )}
                                  <Link
                                    to={
                                      this.props.match.path
                                        .split("/")
                                        .splice(0, 3)
                                        .join("/") +
                                      "/outlet/view/" +
                                      chain.chaKey
                                    }
                                  >
                                    <FontAwesomeIcon
                                      className={["icon"].join(" ")}
                                      icon={["fas", "eye"]}
                                    />{" "}
                                    View
                                  </Link>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    );
  }
}
