import React, { Component } from "react";

import CanvasJSReact from "../../../../../../../lib/canvasjs/canvasjs.react";

import Loader from "../../../../../../settings/loader";

import commaNumber from "comma-number";

import "./style.scss";

const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;
CanvasJS.addColorSet("pieChart1", [
  "#8F2DFB",
  "#29D1E3",
  "#90FA39",
  "#E39F29",
  "#FB502D",
  "#E3B029",
  "#90FA39",
  "#29E3C6",
  "#494CFF",
  "#B36F69",
  "#F7FFC9",
  "#FFB7B0",
  "#789FCC",
  "#7290B3",
  "#5DB363",
  "#C9B8FF",
  "#9EFFA5",
  "#CC986A",
  "#B38A66",
  "#ab6120",
  "#ab2220",
  "#9bab20",
  "#2037ab",
  "#ab208d",
  "#de7e7e",
  "#7ede90",
  "#ac7ede",
  "#de7eb4",
  "#3efb2d",
  "#fb2d2d",
  "#eb4034",
  "#ebb434",
  "#eb3483",
  "#56eb34",
  "#eb34e2",
  "#34eb40",
  "#9c34eb",
  "#34eb71",
  "#b134eb",
  "#34ebc9",
  "#7434eb",
  "#c334eb",
]);

export default class Slider3 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        result: null,
        chart: [],
      },
      load: 0,
    };
  }

  _initialized() {
    if (this.props.data && !this.state.load) {
      this.setState((prevstate) => ({
        data: {
          ...prevstate.data,
          result: this.props.data,
        },
        load: 1,
      }));
    }
  }

  loadData() {
    let payType = this.state.data.result.payType.data.map((payType) => ({
      ...payType,
      total: 0,
    }));

    this.state.data.result.sales.data.forEach((sales) => {
      sales.payType.forEach((type) => {
        const data = payType
          .map((pay, key) => ({ ...pay, key: key }))
          .find((pay) => pay.name === type.name);

        if (data) {
          payType[data.key].total = payType[data.key].total + type.amount;
        }
      });
    });

    let data = payType
      .map((pay) => {
        return {
          y: pay.total,
          name: pay.name,
        };
      })
      .filter((data) => data.y);

    if (this.state.load) {
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          chart: data,
        },
        load: 2,
      }));
    }
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.export !== this.props.export) {
      this.props.getData({
        type: "image",
        title: "Payment Type",
        body: {
          table: [
            {
              head: [["Payment Type", "Total"]],
              body: this.state.data.chart.map((data) => [
                data.name,
                `${this.props.data.currency.base.toUpperCase()} ${commaNumber(
                  data.y.toFixed(2)
                )}`,
              ]),
            },
          ],
          id: "exportPdf_Slide3",
        },
      });
    }
  };

  render() {
    switch (this.state.load) {
      case 0:
        this._initialized();
        break;
      case 1:
        this.loadData();
        break;
      default:
        break;
    }

    const options = {};

    if (this.state.load) {
      options.colorSet = "pieChart1";
      options.legend = {
        cursor: "pointer",
      };
      options.data = [
        {
          type: "pie",
          showInLegend: true,
          indexLabel: "{name}: {y}",
          yValueFormatString: `${this.props.data.currency.base.toUpperCase()} #,##0.00`,
          dataPoints: this.state.data.chart,
        },
      ];
    }

    return (
      <div id={"exportPdf_Slide3"}>
        {!this.state.load ? <Loader /> : <CanvasJSChart options={options} />}
      </div>
    );
  }
}
