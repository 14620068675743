import React, { useState, useEffect, useRef } from "react";

import CanvasJSReact from "../../../../../../../lib/canvasjs/canvasjs.react";

import "./style.scss";

const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

CanvasJS.addColorSet("pieChart1", [
  "#8F2DFB",
  "#29D1E3",
  "#90FA39",
  "#E39F29",
  "#FB502D",
  "#E3B029",
  "#90FA39",
  "#29E3C6",
  "#494CFF",
  "#B36F69",
  "#F7FFC9",
  "#FFB7B0",
  "#789FCC",
  "#7290B3",
  "#5DB363",
  "#C9B8FF",
  "#9EFFA5",
  "#CC986A",
  "#B38A66",
  "#ab6120",
  "#ab2220",
  "#9bab20",
  "#2037ab",
  "#ab208d",
  "#de7e7e",
  "#7ede90",
  "#ac7ede",
  "#de7eb4",
  "#3efb2d",
  "#fb2d2d",
  "#eb4034",
  "#ebb434",
  "#eb3483",
  "#56eb34",
  "#eb34e2",
  "#34eb40",
  "#9c34eb",
  "#34eb71",
  "#b134eb",
  "#34ebc9",
  "#7434eb",
  "#c334eb",
]);

function Slide2(props) {
  const mounted = useRef();

  const [data, setData] = useState([]);

  useEffect(() => {
    const formattedData = Object.values(
      props.slideData.reduce(
        (a, c) => (
          (a[c.group_type] = a[c.group_type]
            ? ((a[c.group_type].total += c.total), a[c.group_type])
            : c),
          a
        ),
        {}
      )
    );
    setData(formattedData);
  }, [props.slideData]);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      if (data.length > 0) {
        props.getData({
          type: "image",
          title: "Sales By Group Type",
          filter: {
            brands: props?.filter.data.brands.map((brand) => brand.name),
            chains: props?.filter.data.chains.map((chain) => chain.name),
            revCent: props?.filter.data.revCent.map((revCent) => revCent.name),
            payType: props?.filter.data.payType.map((payType) => payType.name),
            date: {
              start: props?.filter.data.date.start,
              end: props?.filter.data.date.end,
            },
          },
          body: {
            table: [
              {
                head: [["Group type", "Total"]],
                body: [
                  ...data?.map((item) => {
                    console.log("item", item);
                    return [
                      item?.group_type,
                      `${item?.total?.toFixed(2)} ${item?.currency}`,
                    ];
                  }),
                ],
              },
            ],
            id: "exportPdf_Slide2",
          },
        });
      }
    }
  }, [props.export]);

  const options = {
    colorSet: "pieChart1",
    legend: {
      cursor: "pointer",
    },

    data: [
      {
        type: "pie",
        showInLegend: true,
        indexLabel: "{name}: {y}",
        // yValueFormatString: `${this.props.data.currency.base} #,##0.00`,
        yValueFormatString: `${props?.filter?.data?.groups[0]?.baseCurr.toUpperCase()} #,##0.00`,
        dataPoints: data?.map((item) => {
          return {
            y: item.total,
            name: item.group_type,
          };
        }),
      },
    ],
  };

  return (
    <div id={"exportPdf_Slide2"}>
      <CanvasJSChart options={options} />
    </div>
  );
}

export default Slide2;
