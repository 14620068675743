import React, { Component } from "react";

import CanvasJSReact from "../../../../../../../lib/canvasjs/canvasjs.react";

import Loader from "../../../../../../settings/loader";

import { formatDate } from "../../../../../../formatDate";
import commaNumber from "comma-number";

import "./style.scss";

const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;
CanvasJS.addColorSet("lineChart1", [
  "#8F2DFB",
  "#29D1E3",
  "#90FA39",
  "#E39F29",
  "#FB502D",
  "#E3B029",
  "#90FA39",
  "#29E3C6",
  "#494CFF",
  "#B36F69",
  "#F7FFC9",
  "#FFB7B0",
  "#789FCC",
  "#7290B3",
  "#5DB363",
  "#C9B8FF",
  "#9EFFA5",
  "#CC986A",
  "#B38A66",
]);

export default class Slider1 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        result: null,
        chart: [],
      },
      load: 0,
    };
  }

  addSymbols(e) {
    let suffixes = ["", "K", "M", "B"];
    let order = Math.max(Math.floor(Math.log(e.value) / Math.log(1000)), 0);
    if (order > suffixes.length - 1) order = suffixes.length - 1;
    let suffix = suffixes[order];
    return CanvasJS.formatNumber(e.value / Math.pow(1000, order)) + suffix;
  }

  _initialized() {
    if (this.props.data && !this.state.load) {
      this.setState((prevstate) => ({
        data: {
          ...prevstate.data,
          result: this.props.data,
          chains: this.props.data.chains.data.map((data, key) => ({
            name: data.name,
            braKey: data.braKey,
            chaKey: data.chaKey,
            key: key,
          })),
        },
        load: 1,
      }));
    }
  }

  loadData() {
    const chains = this.state.data.chains.map((chain) => {
      const sales = this.state.data.result.sales.data
        .filter((sale) => sale.chaKey === chain.chaKey)
        .map((sale) => ({
          date: sale.date,
          total: sale.total,
        }));

      return {
        value: sales,
        name:
          this.state.data.result.brands.data.find(
            (brand) => brand.braKey === chain.braKey
          ).name +
          " | " +
          chain.name,
      };
    });

    if (this.state.load) {
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          chart: chains.map((chain) => ({
            type: "line",
            showInLegend: true,
            name: chain.name,
            yValueFormatString: `${this.props.fields.currency.base} #,##0`,
            dataPoints: this.loadDate().map((date, key) => ({
              x: new Date(date.gt),
              y:
                this.props.user.type === "demo"
                  ? chain.value.reduce(
                      (a, b) =>
                        a +
                        b.total *
                          ((Math.floor(Math.random() * 10 + 1) / 100) * 100),
                      0
                    )
                  : chain.value
                      .filter(
                        (value) =>
                          parseInt(date.gt / 1000) <= value.date &&
                          value.date <= parseInt(date.lt / 1000)
                      )
                      .reduce((a, b) => a + b.total, 0),
            })),
          })),
        },
        load: 2,
      }));
    }
  }

  loadDate() {
    const week = [];
    const today = new Date();

    const oneWeekAgo = new Date(today.setDate(today.getDate() - 6));

    for (let i = 0; i < 7; ++i) {
      let firstDayMonth = new Date(today.setDate(oneWeekAgo.getDate() + i));
      firstDayMonth.setHours(0, 0, 0, 0);

      let lastDayMonth = new Date(today.setDate(oneWeekAgo.getDate() + 1 + i));
      lastDayMonth.setHours(23, 59, 59, 59);

      week.push({
        gt: firstDayMonth.getTime(),
        lt: lastDayMonth.getTime(),
      });
    }

    return week;
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.export !== this.props.export) {
      const table = {
        head: [],
        body: [],
      };

      const sort = 4;

      const head = [].concat
        .apply(
          [],
          this.state.data.chart
            .filter((data, key) => (key ? null : true))
            .map((data) => data.dataPoints)
        )
        .map((data) => formatDate.longShort(data.x / 1000));

      const body = [].concat
        .apply(
          [],
          this.state.data.chart.map((data) => ({
            name: data.name,
            dataPoints: data.dataPoints,
          }))
        )
        .map((data) => [
          data.name,
          ...data.dataPoints.map(
            (data) =>
              `${this.props.fields.currency.base} ${commaNumber(
                data.y.toFixed(2)
              )}`
          ),
        ]);

      for (let i = 0; i < Math.ceil(head.length / sort); ++i) {
        table.head.push([
          "Business Name",
          head.slice(i * sort, i ? i * sort + sort : sort),
        ]);
      }

      for (let i = 0; i < Math.ceil(body.length / sort); ++i) {
        table.body.push(body.slice(i * sort, i ? i * sort + sort : sort));
      }

      const tableArray = [];

      for (let i = 0; i < table.head.length; ++i) {
        tableArray.push({
          head: table.head[i],
          body: table.body[i],
        });
      }

      this.props.getData({
        type: "image",
        title: "Weekly - Weekly Sales",
        body: {
          table: [
            {
              head: [
                [
                  "Business Name",
                  ...[].concat
                    .apply(
                      [],
                      this.state.data.chart
                        .filter((data, key) => (key ? null : true))
                        .map((data) => data.dataPoints)
                    )
                    .map((data) => formatDate.longShort(data.x / 1000)),
                ],
              ],
              body: [].concat
                .apply(
                  [],
                  this.state.data.chart.map((data) => ({
                    name: data.name,
                    dataPoints: data.dataPoints,
                  }))
                )
                .map((data) => [
                  data.name,
                  ...data.dataPoints.map(
                    (data) =>
                      `${this.props.fields.currency.base} ${commaNumber(
                        data.y.toFixed(2)
                      )}`
                  ),
                ]),
            },
          ],
          id: "exportPdf_Slide1",
        },
      });
    }
  };

  render() {
    switch (this.state.load) {
      case 0:
        this._initialized();
        break;
      case 1:
        this.loadData();
        break;
      default:
        break;
    }

    const options = {
      colorSet: "barChart3",
      axisY: {
        includeZero: false,
        prefix: `${this.props.fields.currency.base} `,
        gridColor: "#d6d6d6",
        gridThickness: 1,
      },
      axisX: {
        interval: 1,
        interlacedColor: "#f0f8ff",
      },
      toolTip: {
        shared: true,
      },
      legend: {
        fontSize: 13,
      },
      data: this.state.data.chart,
    };

    return (
      <div id={"exportPdf_Slide1"}>
        {!this.state.load ? <Loader /> : <CanvasJSChart options={options} />}
      </div>
    );
  }
}
