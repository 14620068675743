import React, { useEffect, useState, useRef } from "react";

import CanvasJSReact from "../../../../../../../lib/canvasjs/canvasjs.react";

import "./style.scss";

const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

CanvasJS.addColorSet("barChart4", [
  "#FFA32E",
  "#E38029",
  "#FA8039",
  "#E6693C",
  "#FA684B",
]);

const addSymbols = (e) => {
  const suffixes = ["", "K", "M", "B"];
  let order = Math.max(Math.floor(Math.log(e.value) / Math.log(1000)), 0);
  if (order > suffixes.length - 1) order = suffixes.length - 1;
  const suffix = suffixes[order];
  return CanvasJS.formatNumber(e.value / Math.pow(1000, order)) + suffix;
};

function Slide1(props) {
  const mounted = useRef();

  const [data, setData] = useState([]);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      if (data.length > 0) {
        props.getData({
          type: "image",
          title: "Sales By Group Type",
          filter: {
            brands: props?.filter.data.brands.map((brand) => brand.name),
            chains: props?.filter.data.chains.map((chain) => chain.name),
            revCent: props?.filter.data.revCent.map((revCent) => revCent.name),
            payType: props?.filter.data.payType.map((payType) => payType.name),
            date: {
              start: props?.filter.data.date.start,
              end: props?.filter.data.date.end,
            },
          },
          body: {
            table: [
              {
                head: [["Reataurant name", "Total"]],
                body: [
                  ...data?.map((item) => [
                    item?.label,
                    `${item?.currency} ${item?.y?.toFixed(2)}`,
                  ]),
                ],
              },
            ],
            id: "exportPdf_Slide1",
          },
        });
      }
    }
  }, [props.export]);

  useEffect(() => {
    const slide1Data = Object.values(
      props.slideData.reduce(
        (a, c) => (
          (a[c.restaurant_name] = a[c.restaurant_name]
            ? ((a[c.restaurant_name].total += c.total), a[c.restaurant_name])
            : c),
          a
        ),
        {}
      )
    );

    const slide1DataF = slide1Data?.map((item) => {
      return {
        y: item.total,
        label: item.restaurant_name,
        toolTipContent: `{label} : {y} {currency}`,
        currency: item?.currency,
      };
    });

    setData(slide1DataF);
  }, [props.slideData]);

  const chainsCurrencies = props?.filter?.data?.chains?.map((item) =>
    item?.currency?.toLowerCase()
  );

  const isEqualCurr = chainsCurrencies?.every((val, i, arr) => val === arr[0]);

  const options = {
    colorSet: "barChart4",
    axisX: {
      interval: 1,
      interlacedColor: "#f0f8ff",
    },
    axisY: {
      labelFormatter: addSymbols,

      prefix: isEqualCurr
        ? `${
            chainsCurrencies[0]?.toUpperCase() ||
            props?.filter?.data?.groups[0]?.baseCurr.toUpperCase()
          }`
        : "",

      gridColor: "#d6d6d6",

      gridThickness: 1,
    },
    data: [
      {
        type: "bar",
        // indexLabel: "{y}",
        // yValueFormatString: `${this.props.data.currency.base} #,##0.00`,
        yValueFormatString: `#,##0.00`,

        dataPoints: data,
      },
    ],
  };

  if (data && data.length <= 2) {
    delete options.axisX.interval;
  }

  return (
    <div id={"exportPdf_Slide1"}>
      <CanvasJSChart options={options} />
    </div>
  );
}

export default Slide1;
