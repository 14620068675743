import React, { Component } from "react";

import "./style.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { formatTitle } from "../../formatTitle";

export default class breadcrumbs extends Component {
  render() {
    const path = this.props.path.split("/").slice(1);

    if (path.length === 1) {
      path.push("dashboard");
    }

    return (
      <section className={["breadcrumbs"]}>
        {path.map((value, key) =>
          value !== "main" ? (
            <p key={key}>
              {key ? (
                <FontAwesomeIcon
                  className={["icon"].join(" ")}
                  icon={["fas", "caret-right"]}
                />
              ) : (
                <span />
              )}
              {formatTitle(value)}
            </p>
          ) : (
            <div key={key} />
          )
        )}
      </section>
    );
  }
}
