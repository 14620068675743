import html2canvas from "html2canvas";
import jspdf from "jspdf";
import "jspdf-autotable";

import { formatDate } from "./formatDate";

const date = new Date();

export const exportPdf = {
  table: (data) => {
    let doc = new jspdf();
    let y = 10;
    doc.setFontSize(9);

    const currDate = formatDate.long(date.getTime() / 1000);

    if (!data.title) {
      data.title = " ";
    }

    const header = `${data.title} | Insights Export | Date Exported: ${currDate}`;
    const headerBlock = Math.ceil(
      (doc.splitTextToSize(header, 170).length * doc.getLineHeight(header)) /
        doc.internal.scaleFactor
    );

    doc.text(doc.splitTextToSize(header, 170), 10, y);

    y += headerBlock + 5;

    if (data.filter) {
      const filter = `Filtered By: \n\nBrands: ${data.filter.brands.join(
        ", "
      )} \n\nChains: ${data.filter.chains.join(", ")} 
      ${
        data?.filter?.revCent
          ? `\n\nRevenue Center: ${data.filter.revCent.join(", ")}`
          : ""
      }\n\nDate: ${formatDate.longShort(
        data.filter.date.start
      )} to: ${formatDate.longShort(data.filter.date.end)}`;

      const filterBlock = Math.ceil(
        (doc.splitTextToSize(filter, 170).length * doc.getLineHeight(filter)) /
          doc.internal.scaleFactor
      );

      doc.text(doc.splitTextToSize(filter, 170), 10, y);

      y += filterBlock + 5;
    }

    data.body.forEach((body, key) => {
      doc.autoTable({
        theme: "striped",
        ...(key > 0 && { pageBreak: "always" }),
        margin: { right: 10, left: 10 },
        startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 10 : y,
        headStyles: {
          fillColor: "#212529",
          textColor: "#ffffff",
          lineColor: "#524e4e",
        },

        head: body.head,
        body: body.body,
      });
    });

    doc.save(
      "insightsExport_" +
        data.title.split(" ").join("_") +
        "_Report_" +
        date.getTime() +
        ".pdf"
    );
  },

  image: (data) => {
    const element = document.getElementById(data.body.id);

    return html2canvas(element).then(function (canvas) {
      let doc = new jspdf();
      let y = 10;
      doc.setFontSize(9);

      const currDate = formatDate.long(date.getTime() / 1000);

      if (!data.title) {
        data.title = " ";
      }

      const header =
        data.title + " | Insights Export | Date Exported: " + currDate;
      const headerBlock = Math.ceil(
        (doc.splitTextToSize(header, 170).length * doc.getLineHeight(header)) /
          doc.internal.scaleFactor
      );

      doc.text(doc.splitTextToSize(header, 170), 10, y);

      y += headerBlock + 5;

      if (data.filter) {
        const filter = `Filtered By: \n\nBrands: ${data.filter.brands.join(
          ", "
        )} \n\nChains: ${data.filter.chains.join(", ")}
         ${
           data?.filter?.revCent
             ? `\n\nRevenue Center: ${data.filter.revCent.join(", ")}`
             : ""
         }\n\nDate: ${formatDate.longShort(
          data.filter.date.start
        )} to: ${formatDate.longShort(data.filter.date.end)}`;

        const filterBlock = Math.ceil(
          (doc.splitTextToSize(filter, 170).length *
            doc.getLineHeight(filter)) /
            doc.internal.scaleFactor
        );

        doc.text(doc.splitTextToSize(filter, 170), 10, y);

        y += filterBlock + 5;
      }

      const imgData = canvas.toDataURL("image/png");

      const width = doc.internal.pageSize.getWidth() - 10 * 2;
      const height =
        (doc.getImageProperties(imgData).height * width) /
        doc.getImageProperties(imgData).width;

      doc.addImage(imgData, "JPEG", 10, y, width, height);

      y += height + 5;

      data.body.table.forEach((body, key) => {
        doc.autoTable({
          theme: "striped",
          startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 10 : y,
          margin: { right: 10, left: 10 },
          headStyles: {
            fillColor: "#212529",
            textColor: "#ffffff",
            lineColor: "#524e4e",
          },
          head: body.head,
          body: body.body,
        });
      });

      doc.save(
        `insightsExport_${data.title
          .split(" ")
          .join("_")}_Report_${date.getTime()}.pdf`
      );
    });
  },
};
