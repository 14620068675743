import React, { useEffect, useRef } from "react";

import commaNumber from "comma-number";

import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import "./style.scss";

function Slide3(props) {
  const mounted = useRef();

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      if (props?.slideData.length > 0) {
        props.getData({
          type: "table",
          title: "Group types",
          filter: {
            brands: props?.filter.data.brands.map((brand) => brand.name),
            chains: props?.filter.data.chains.map((chain) => chain.name),
            revCent: props?.filter.data.revCent.map((revCent) => revCent.name),
            payType: props?.filter.data.payType.map((payType) => payType.name),
            date: {
              start: props?.filter.data.date.start,
              end: props?.filter.data.date.end,
            },
          },
          body: [
            {
              head: [["Restaurant name", "Group type", "Total"]],
              body: props?.slideData?.map((value) => [
                value.restaurant_name,
                value.group_type,
                `${value.total} ${value.currency}`,
              ]),
            },
          ],
        });
      }
    }
  }, [props.export]);

  const columns = [
    { Header: "Restaurant name", accessor: "restaurant_name" },
    {
      Header: "Group type",
      accessor: "group_type",
    },
    {
      id: "total",
      Header: "Total",
      accessor: (row) => `${commaNumber(row.total.toFixed(2))} `,
      Cell: (row) => {
        return `${row.value} ${row?.original?.currency}`;
      },
    },
  ];

  return (
    <div>
      <ReactTable
        className={["-striped", "-highlight"].join(" ")}
        data={props.slideData}
        columns={columns}
        sortable
        filterable
        getTdProps={() => ({
          style: {
            textAlign: "center",
          },
        })}
      />
    </div>
  );
}

export default Slide3;
