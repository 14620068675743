import React, { Component } from "react";

import CanvasJSReact from "../../../../../../../lib/canvasjs/canvasjs.react";

import commaNumber from "comma-number";

import Loader from "../../../../../../settings/loader";

import "./style.scss";

const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;
CanvasJS.addColorSet("pieChart1", [
  "#8F2DFB",
  "#29D1E3",
  "#90FA39",
  "#E39F29",
  "#FB502D",
  "#E3B029",
  "#90FA39",
  "#29E3C6",
  "#494CFF",
]);

export default class Slider3 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        result: null,
        chart: [],
      },
      load: 0,
    };
  }

  _initialized() {
    if (this.props.data && !this.state.load) {
      this.setState((prevstate) => ({
        data: {
          ...prevstate.data,
          result: this.props.data,
        },
        load: 1,
      }));
    }
  }

  loadData() {
    let groups = this.state.data.result.group.data.map((group) => ({
      ...group,
      qty: 0,
      total: 0,
    }));

    this.state.data.result.sales.data.forEach((sales) => {
      sales.items.forEach((item, itemKey) => {
        const itemTotal = item.amount;
        const itemGroup = item.group;

        groups.forEach((group, key) => {
          const item = itemGroup.find((item) => item.type === group.name);

          if (item) {
            groups[key].qty = groups[key].qty + 1;
            groups[key].total = groups[key].total + itemTotal;
          }
        });
      });
    });

    let data = groups.map((grp) => {
      return {
        y: grp.total,
        name: grp.name,
      };
    });

    if (this.state.load) {
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          chart: data.filter((data) => data.y),
        },
        load: 2,
      }));
    }
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.export !== this.props.export) {
      this.props.getData({
        type: "image",
        title: "Monthly - Group By Items",
        body: {
          table: [
            {
              head: [["Item Group", "Total"]],
              body: this.state.data.chart.map((data) => [
                data.name,
                `${this.props.fields.currency.base} ${commaNumber(
                  data.y.toFixed(2)
                )}`,
              ]),
            },
          ],
          id: "exportPdf_Slide4",
        },
      });
    }
  };

  render() {
    switch (this.state.load) {
      case 0:
        this._initialized();
        break;
      case 1:
        this.loadData();
        break;
      default:
        break;
    }

    const options = {
      colorSet: "pieChart1",
      legend: {
        cursor: "pointer",
      },
      data: [
        {
          type: "pie",
          showInLegend: true,
          indexLabel: "{name}: {y}",
          yValueFormatString: `${this.props.fields.currency.base} #,##0.00`,
          dataPoints: this.state.data.chart,
        },
      ],
    };

    return (
      <div id={"exportPdf_Slide4"}>
        {!this.state.load ? <Loader /> : <CanvasJSChart options={options} />}
      </div>
    );
  }
}
