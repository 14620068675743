import React, { Component } from "react";

import { Redirect } from "react-router-dom";

import atob from "atob";

import Navbar from "./elements/navbar";
import Body from "./elements/body";
import Sidebar from "./elements/sidebar";

import "./style.scss";

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {
        state: true,
        data: null,
      },
      page: false,
      toggle: false,
    };
  }

  getData() {
    if (localStorage.getItem("user") || sessionStorage.getItem("user")) {
      let user = "";

      if (sessionStorage.getItem("user")) {
        user = atob(sessionStorage.getItem("user"));
      }

      if (localStorage.getItem("user")) {
        user = atob(localStorage.getItem("user"));
      }

      this.setState((prevState) => ({
        user: {
          ...prevState.user,
          data: JSON.parse(user),
        },
      }));
    } else {
      this.setState((prevState) => ({
        user: {
          ...prevState.user,
          state: false,
        },
      }));
    }
  }

  validateUser() {
    if (!this.state.user.state) {
      return <Redirect to={"/login"} />;
    }
  }

  toggle = () => {
    this.setState({
      toggle: this.state.toggle ? false : true,
    });
  };

  UNSAFE_componentWillMount() {
    // TODO: debug this for react udpdates
    this.getData();
  }

  render() {
    return (
      <div className={["home"]}>
        {this.validateUser()}
        <section
          className={["left", this.state.toggle ? "leftActive" : ""].join(" ")}
        >
          <Sidebar
            location={this.props.location}
            user={this.state.user}
            match={this.props.match}
            toggle={this.toggle}
          />
        </section>
        <section className={["right"].join(" ")}>
          <Navbar
            location={this.props.location}
            user={this.state.user}
            match={this.props.match}
          />
          <div className={["bodyContainer"]}>
            <Body
              location={this.props.location}
              match={this.props.match}
              user={this.state.user}
              chimeraserv={this.props.chimeraserv}
              components={this.props.components}
            />
          </div>
        </section>
      </div>
    );
  }
}
