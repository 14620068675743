import React, { useState, useEffect } from "react";
import Popup from "reactjs-popup";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "reactjs-popup/dist/index.css";
import "./style.scss";

const contentStyle = {
  maxWidth: "800px",
  width: "90%",
  overflow: "scroll",
  maxHeight: "600px",
};

const warningColors = {
  expired: "#dd2828",
  expiring: "#ffa104",
};

function ExpirinOutletsModal({ expiringChains }) {
  //check if the array has at least on expired outlet

  const [openModal, setOpenModal] = useState(false);

  const isRemindered = sessionStorage.getItem("isRemindered");

  useEffect(() => {
    if (isRemindered == "false") {
      setOpenModal(true);
    }
  }, []);

  useEffect(() => {
    if (openModal) {
      //when close the reminder: set isRemindered to true
      sessionStorage.setItem("isRemindered", "true");
    }
  }, [openModal]);

  const closeModal = () => {
    setOpenModal(false);
  };

  const hasExpired =
    expiringChains?.filter((item) => item?.status == "expired").length > 0;
  return (
    <div className="example-warper">
      <Popup
        open={openModal}
        onClose={closeModal}
        trigger={
          <div
            style={{
              cursor: "pointer",
              display: "inline-block",
              color: hasExpired
                ? warningColors.expired
                : warningColors.expiring,
            }}
          >
            <FontAwesomeIcon
              icon={["fas", "exclamation-triangle"]}
              // icon="fas exclamation-triangle"
            />

            <span
              style={{
                fontWeight: "bold",
              }}
            >{` Click here to check your subscription status`}</span>
          </div>
        }
        modal
        contentStyle={contentStyle}
      >
        {(close) => (
          <div className="modals">
            <div type="button" className="close" onClick={close}>
              &times;
            </div>

            <div className="header">
              <h1
                style={{
                  color: hasExpired
                    ? warningColors.expired
                    : warningColors.expiring,
                }}
              >
                <FontAwesomeIcon
                  icon={["fas", "exclamation-triangle"]}
                  // icon="fas fa-exclamation-triangle"
                />
                {` Subscription Expiration Reminder`}
              </h1>
            </div>
            <div className="content">
              <p>
                Please check the following list of expiring/expired outlets:
              </p>
              <ul>
                {expiringChains?.map((item) => (
                  <li
                    style={{
                      color:
                        item?.status == "expired"
                          ? warningColors.expired
                          : warningColors.expiring,
                    }}
                  >
                    <b>{item?.message}</b>
                  </li>
                ))}
              </ul>
              <span>
                Note: your access to POS-Insights will be automatically paused
                for the expired outlets untill renewing the subscription.
              </span>
            </div>
          </div>
        )}
      </Popup>
    </div>
  );
}

export default ExpirinOutletsModal;
