import React, { Component } from "react";

import request from "request";

import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class ListBrand extends Component {
  constructor(props) {
    super(props);

    this.state = {
      table: {
        head: ["Group", "Brand", "No. of Outlets", "Actions"],
        data: false,
      },
    };
  }

  valueChange = (group, brand) => {
    let groupIndex = group.brands
      .map((brands, index) => ({ ...brands, index: index }))
      .find((brands) => brands.braKey === brand.braKey);

    group.brands[groupIndex.index].status =
      group.brands[groupIndex.index].status === 1 ? 0 : 1;

    let data = {
      ...group,
    };

    request(
      {
        method: "PUT",
        url: this.props.chimeraserv.urlPost + "/group/update",
        headers: {
          Authorization: this.props.user.data.usrKey,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: data,
        json: true,
      },
      (error, response, body) => false
    );
  };

  renderData = () =>
    this.state.table.data.map((data) =>
      data.brands.map((brand, key) => {
        return (
          <tr key={key}>
            <td>{data.name}</td>
            <td>{brand.name}</td>
            <td>{brand.chains.length}</td>
            <td className={["actions"]}>
              {this.props.user.data.level === 0 ? (
                <div className={["actions", "actionsAdmin"].join(" ")}>
                  <div
                    className={["custom-control", "custom-switch"].join(" ")}
                  >
                    <input
                      type={"checkbox"}
                      className={"custom-control-input"}
                      id={brand.braKey}
                      defaultChecked={brand.status ? true : false}
                      onChange={() => this.valueChange(data, brand, key)}
                    />
                    <label
                      className={"custom-control-label"}
                      htmlFor={brand.braKey}
                    />
                  </div>
                  <Link
                    to={
                      this.props.match.path.split("/").splice(0, 4).join("/") +
                      "/edit/" +
                      brand.braKey
                    }
                  >
                    <FontAwesomeIcon
                      className={["icon"].join(" ")}
                      icon={["fas", "cog"]}
                    />{" "}
                    Edit
                  </Link>
                </div>
              ) : (
                <div />
              )}
              <Link
                to={
                  this.props.match.path.split("/").splice(0, 4).join("/") +
                  "/view/" +
                  brand.braKey
                }
              >
                <FontAwesomeIcon
                  className={["icon"].join(" ")}
                  icon={["fas", "eye"]}
                />{" "}
                View
              </Link>
            </td>
          </tr>
        );
      })
    );

  componentDidMount() {
    if (this.props.user.data) {
      request(
        {
          method: "GET",
          url: this.props.chimeraserv.urlGet + "/group",
          headers: {
            Authorization: this.props.user.data.usrKey, //dyanmic this
            "Contet-Type": "application/json",
            Accept: "application/json",
          },
        },
        (error, response, body) => {
          let data = [];

          if (Object.keys(JSON.parse(body)).length) {
            data = JSON.parse(body);
          }

          this.setState((prevState) => ({
            table: {
              ...prevState.table,
              data: data,
            },
          }));
        }
      );
    }
  }

  render() {
    let Loader = this.props.components.setting[1].component;

    return (
      <div className={"custForm"}>
        <div className={["viewTitle"]}>List of Brands</div>
        <div>
          {!this.state.table.data ? (
            <Loader />
          ) : (
            <table
              className={["table", "table-striped", "defaultTable"].join(" ")}
            >
              <thead>
                <tr>
                  {this.state.table.head.map((head, key) => (
                    <th scope={"col"} key={key}>
                      {head}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>{this.renderData()}</tbody>
            </table>
          )}
        </div>
      </div>
    );
  }
}
