import React, { Component } from "react";
import ReactTable from "react-table-v6";
import Loader from "../../../../../../settings/loader";
import commaNumber from "comma-number";
import "react-table-v6/react-table.css";
import "./style.scss";

export default class Slider2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        result: null,
        table: [],
      },
      load: 0,
    };
  }

  _initialized() {
    if (this.props.data.state && !this.state.load) {
      this.setState((prevstate) => ({
        data: {
          ...prevstate.data,
          result: this.props.data,
        },
        load: 1,
      }));
    }
  }

  loadData() {
    if (this.props.data.state && this.state.load) {
      let payType = this.state.data.result.payType.data
        .filter((pt) => pt.name !== "Void")
        .map((payType, key) => ({
          ...payType,
          key: 0,
          checks: 0,
          percentage: 0,
          total: 0,
        }));

      this.state.data.result.sales.data.forEach((sales) => {
        sales.payType.forEach((type) => {
          const data = payType
            .map((pay, key) => ({ ...pay, key: key }))
            .find((pay) => pay.name === type.name);

          if (data) {
            payType[data.key].checks = payType[data.key].checks + 1;
            payType[data.key].total = payType[data.key].total + type.amount;
          }
        });
      });

      const totalSum = payType.reduce((a, b) => a + b.total, 0);

      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          table: payType.sort(this.sortSales).map((pay) => ({
            ...pay,
            percentage: (pay.percentage = !(pay.total / totalSum)
              ? 0
              : ((pay.total / totalSum) * 100).toFixed(0)),
          })),
        },
        load: 2,
      }));
    }
  }

  sortSales(a, b) {
    if (parseInt(a.total) > parseInt(b.total)) {
      return -1;
    }

    if (parseInt(a.total) < parseInt(b.total)) {
      return 1;
    }

    return 0;
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.export !== this.props.export) {
      this.props.getData({
        type: "table",
        title: "Payment Type by Percentage",
        filter: {
          brands: this.props.filter.data.brands.map((brand) => brand.name),
          chains: this.props.filter.data.chains.map((chain) => chain.name),
          revCent: this.props.filter.data.revCent.map(
            (revCent) => revCent.name
          ),
          payType: this.props.filter.data.payType.map(
            (payType) => payType.name
          ),
          date: {
            start: this.props.filter.data.date.start,
            end: this.props.filter.data.date.end,
          },
        },
        body: [
          {
            head: [["Revenue Center", "Checks", "Percentage", "Total"]],
            body: this.state.data.table.map((value) => [
              value.name,
              commaNumber(value.checks),
              `${value.percentage}%`,
              `${this.props.data.currency.base} ${commaNumber(
                value.total.toFixed(2)
              )}`,
            ]),
          },
        ],
      });
    }
  };

  render() {
    switch (this.state.load) {
      case 0:
        this._initialized();
        break;
      case 1:
        this.loadData();
        break;
      default:
        break;
    }

    const columns = [
      {
        Header: "Item Name",
        accessor: "name",
      },
      {
        id: "checks",
        Header: "Checks",
        accessor: "checks",
        Cell: (row) => {
          return `${commaNumber(row.value)}`;
        },
      },
      {
        id: "percentage",
        Header: "Percentage",
        accessor: (row) => parseFloat(row.percentage),
        Cell: (row) => {
          return `${commaNumber(row.value)}%`;
        },
      },
      {
        id: "total",
        Header: "Total",
        accessor: "total",
        Cell: (row) => {
          return `${this.props.data.currency.base} ${commaNumber(
            row.value.toFixed(2)
          )}`;
        },
      },
    ];

    return (
      <div>
        {!this.state.load ? (
          <Loader />
        ) : (
          <ReactTable
            className={["-striped", "-highlight"].join(" ")}
            data={this.state.data.table}
            columns={columns}
            sortable
            filterable
            getTdProps={() => ({
              style: {
                textAlign: "center",
              },
            })}
          />
        )}
      </div>
    );
  }
}
