import React, { Component } from "react";

import request from "request";

import { Link, Redirect } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import btoa from "btoa";

import "./style.scss";

export default class LoginForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      field: {
        username: "",
        password: "",
        store: false,
      },
      form: false,
      login: false,
      alert: {
        state: false,
        data: null,
      },
    };

    this.valueChanged = this.valueChanged.bind(this);
    this.validate = this.validate.bind(this);
  }

  valueChanged = (event) => {
    const element = event.target;

    if (this.validateField(element)) {
      this.setState((prevState) => ({
        field: {
          ...prevState.field,
          [element.id]:
            element.id === "store" ? element.checked : element.value,
        },
      }));
    }
  };

  getData = () => {
    if (localStorage.getItem("user") || sessionStorage.getItem("user")) {
      this.setState((prevState) => ({
        login: true,
      }));
    } else {
      this.setState((prevState) => ({
        user: {
          ...prevState.user,
          state: false,
        },
      }));
    }
  };

  validateField(field) {
    return true;
  }

  validate = (event) => {
    event.preventDefault();

    this.setState({
      alert: {
        state: false,
        data: null,
      },
    });

    setTimeout(() => {
      this.setState({
        alert: {
          state: true,
          data: {
            status: "#747474",
            message: "logging in...",
          },
        },
      });
    }, 500);

    request(
      {
        method: "POST",
        url: this.props.chimeraserv.urlGet + "/login",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: {
          username: this.state.field.username,
          password: this.state.field.password,
        },
        json: true,
      },
      (error, response, body) => {
        if (!body.error && response.statusCode === 200) {
          this.setState({
            alert: {
              state: true,
              data: {
                status: "#61bd1a",
                message: "Login successful!",
              },
            },
          });

          sessionStorage.setItem("isRemindered", "false"); //is reminded with expiration outlets when login

          setTimeout(() => {
            switch (this.state.field.store) {
              case true:
                localStorage.setItem("user", btoa(JSON.stringify(body)));
                sessionStorage.setItem("user", btoa(JSON.stringify(body)));
                break;
              default:
                sessionStorage.setItem("user", btoa(JSON.stringify(body)));
                break;
            }

            this.setState({
              form: true,
            });
          }, 500);
        } else {
          setTimeout(() => {
            this.setState({
              alert: {
                state: true,
                data: {
                  status: "#d62424",
                  message: "Incorrect Username or Password.",
                },
              },
            });
          }, 1500);
        }
      }
    );
  };

  validateForm(field) {
    if (this.state.form) {
      return <Redirect to="/app" />;
    }
  }

  validateLogin() {
    if (this.state.login) {
      return <Redirect to="/app" />;
    }
  }

  componentDidMount() {
    this.getData();
  }

  render() {
    return (
      <div className={["login"]}>
        {this.validateLogin()}
        {this.validateForm()}

        <form onSubmit={this.validate}>
          <div className={["form-group"]}>
            <FontAwesomeIcon
              className={["icon"].join(" ")}
              icon={["fas", "user-circle"]}
            />
            <input
              type={"text"}
              className={["form-control"]}
              id={"username"}
              placeholder={"username"}
              value={this.state.field.username}
              onChange={this.valueChanged}
            />
          </div>
          <div className={["form-group"]}>
            <FontAwesomeIcon
              className={["icon"].join(" ")}
              icon={["fas", "key"]}
            />
            <input
              type={"password"}
              className={["form-control"]}
              id={"password"}
              placeholder={"password"}
              value={this.state.field.password}
              onChange={this.valueChanged}
            />
          </div>
          <div className={["custom-control", "custom-checkbox"].join(" ")}>
            <input
              type={"checkbox"}
              className={["custom-control-input"]}
              id={"store"}
              onChange={this.valueChanged}
            />
            <label className={"custom-control-label"} htmlFor={"store"}>
              Remember me
            </label>
          </div>

          <div
            className={["form-group", "statusContainer"].join(" ")}
            style={
              this.state.alert.data
                ? { color: this.state.alert.data.status }
                : {}
            }
          >
            {this.state.alert.state ? (
              this.state.alert.data.message
            ) : (
              <div className={["breakSpace"]} />
            )}
          </div>

          <button
            type={"submit"}
            className={["btn btn-primary"]}
            disabled={
              this.state.field.username && this.state.field.password
                ? false
                : true
            }
          >
            Login
          </button>
        </form>

        <Link
          className={["formLink"].join(" ")}
          to={{
            pathname: this.props.match.path + "/forgot",
            prevUrl: this.props.location.pathname,
          }}
        >
          Forgot Password
        </Link>
      </div>
    );
  }
}
