import React, { Component } from "react";

import "./style.scss";

import { formatDate } from "../../formatDate";

export default class reportSummary extends Component {
  render() {
    const filter = {
      groups: [],
      brands: [],
      revCent: [],
      payType: [],
      chains: [],
    };

    const sort = 6;

    for (
      let i = 0;
      i < Math.ceil(this.props.filter.data.groups.length / sort);
      ++i
    ) {
      filter.groups.push(
        this.props.filter.data.groups.slice(
          i * sort,
          i ? i * sort + sort : sort
        )
      );
    }

    for (
      let i = 0;
      i < Math.ceil(this.props.filter.data.brands.length / sort);
      ++i
    ) {
      filter.brands.push(
        this.props.filter.data.brands.slice(
          i * sort,
          i ? i * sort + sort : sort
        )
      );
    }

    for (
      let i = 0;
      i < Math.ceil(this.props.filter.data.chains.length / sort);
      ++i
    ) {
      filter.chains.push(
        this.props.filter.data.chains.slice(
          i * sort,
          i ? i * sort + sort : sort
        )
      );
    }

    for (
      let i = 0;
      i < Math.ceil(this.props.filter.data.revCent.length / sort);
      ++i
    ) {
      filter.revCent.push(
        this.props.filter.data.revCent.slice(
          i * sort,
          i ? i * sort + sort : sort
        )
      );
    }

    for (
      let i = 0;
      i < Math.ceil(this.props.filter.data.payType.length / sort);
      ++i
    ) {
      filter.payType.push(
        this.props.filter.data.payType.slice(
          i * sort,
          i ? i * sort + sort : sort
        )
      );
    }

    return (
      <section className={["reportSummary"]}>
        <p>filtered by:</p>
        <div className={["attribute"]}>
          <div className={"section"}>
            <h1>Groups</h1>
            <div className={"collection"}>
              {filter.groups.map((data, key) => (
                <ul key={key}>
                  {data.map((value, index) => (
                    <li key={index}>{value.name}</li>
                  ))}
                </ul>
              ))}
            </div>
          </div>
          <div className={"section"}>
            <h1>Brand</h1>
            <div className={"collection"}>
              {filter.brands.map((data, key) => (
                <ul key={key}>
                  {data.map((value, index) => (
                    <li key={index}>{value.name}</li>
                  ))}
                </ul>
              ))}
            </div>
          </div>
          <div className={"section"}>
            <h1>Outlet</h1>
            <div className={"collection"}>
              {filter.chains.map((data, key) => (
                <ul key={key}>
                  {data.map((value, index) => (
                    <li key={index}>{value.name}</li>
                  ))}
                </ul>
              ))}
            </div>
          </div>
          {this.props.location !== "/app/sales/session" && (
            <div className={"section"}>
              <h1>Revenue Center</h1>
              <div className={"collection"}>
                {filter.revCent.map((data, key) => (
                  <ul key={key}>
                    {data.map((value, index) => (
                      <li key={index}>{value.name}</li>
                    ))}
                  </ul>
                ))}
              </div>
            </div>
          )}
          {/*<div className={"section"}>
											<h1>Payment Type</h1>
											<div className={"collection"}>
												{
													filter.payType.map((data, key) => (
														<ul key={key}>
															{
																data.map((value, index) => (
																	<li key={index}>{value.name}</li>
																))
															}
														</ul>
													))
												}
											</div>
										</div>*/}
          <div className={["section", "date"].join(" ")}>
            <h1>Date</h1>
            <ul>
              <li>
                From:{" "}
                <span>
                  {formatDate.longShort(this.props.filter.data.date.start)}
                </span>
              </li>
              <li>
                To:{" "}
                <span>
                  {formatDate.longShort(this.props.filter.data.date.end)}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </section>
    );
  }
}
