import React, { Component } from "react";

import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./style.scss";

export default class LoginForm extends Component {
  render() {
    return (
      <div className={["error"]}>
        <div className={["box"]}>
          <FontAwesomeIcon
            className={["icon"].join(" ")}
            icon={["fas", "exclamation-circle"]}
          />
          <div>
            Error Page
            <Link to={"/app"}>Go to homepage</Link>
          </div>
        </div>
      </div>
    );
  }
}
