import React, { Component } from "react";

import request from "request";

import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "../../style.scss";

export default class ListClient extends Component {
  constructor(props) {
    super(props);

    this.state = {
      table: {
        head: ["#", "Username", "Email", "Actions"],
        data: null,
      },
    };
  }

  valueChange = (data, key) => {
    data.status = data.status === 1 ? 0 : 1;

    request(
      {
        method: "PUT",
        url: this.props.chimeraserv.urlPost + "/user/update",
        headers: {
          Authorization: this.props.user.data.usrKey,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: data,
        json: true,
      },
      (error, response, body) => false
    );
  };

  renderData = () =>
    this.state.table.data
      .filter((data) => !(data.usrKey === this.props.user.data.usrKey))
      .map((data, key) => {
        return (
          <tr key={key}>
            <td>{key + 1}</td>
            <td>{data.username}</td>
            <td>{data.email}</td>
            <td className={["actions"]}>
              {this.props.user.data.level === 0 ? (
                <div className={["actions", "actionsAdmin"].join(" ")}>
                  <div
                    className={["custom-control", "custom-switch"].join(" ")}
                  >
                    <input
                      type={"checkbox"}
                      className={"custom-control-input"}
                      id={data._id}
                      defaultChecked={data.status ? true : false}
                      onChange={() => this.valueChange(data, key)}
                    />
                    <label
                      className={"custom-control-label"}
                      htmlFor={data._id}
                    />
                  </div>
                  <Link
                    to={
                      this.props.match.path.split("/").splice(0, 3).join("/") +
                      "/edit/" +
                      data._id
                    }
                  >
                    <FontAwesomeIcon
                      className={["icon"].join(" ")}
                      icon={["fas", "cog"]}
                    />{" "}
                    Edit
                  </Link>
                </div>
              ) : (
                <div />
              )}
              <Link
                to={
                  this.props.match.path.split("/").splice(0, 3).join("/") +
                  "/view/" +
                  data._id
                }
              >
                <FontAwesomeIcon
                  className={["icon"].join(" ")}
                  icon={["fas", "eye"]}
                />{" "}
                View
              </Link>
            </td>
          </tr>
        );
      });

  componentDidMount() {
    if (this.props.user.data) {
      request(
        {
          method: "GET",
          url: this.props.chimeraserv.urlGet + "/user",
          headers: {
            Authorization: this.props.user.data.usrKey,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        },
        (error, response, body) => {
          let data = [];

          if (Object.keys(JSON.parse(body)).length) {
            data = JSON.parse(body);
          }

          this.setState((prevState) => ({
            table: {
              ...prevState.table,
              data: data,
            },
          }));
        }
      );
    }
  }

  render() {
    let Loader = this.props.components.setting[1].component;

    return (
      <div className={"custForm"}>
        <div className={["viewTitle"]}>List of Users</div>

        {!this.state.table.data ? (
          <Loader />
        ) : (
          <table
            className={["table", "table-striped", "defaultTable"].join(" ")}
          >
            <thead>
              <tr>
                {this.state.table.head.map((head, key) => (
                  <th scope={"col"} key={key}>
                    {head}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>{this.renderData()}</tbody>
          </table>
        )}
      </div>
    );
  }
}
