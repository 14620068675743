import React, { Component } from "react";

import request from "request";

import Window from "../../../../elements/window";

import Loader from "../../../../../settings/loader";

import Slide1 from "./slides/slide1";
import Slide2 from "./slides/slide2";
import Slide3 from "./slides/slide3";

export default class sales extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        sales: {
          data: [],
          state: false,
        },
        revCent: {
          data: [],
          state: false,
        },
        brands: {
          data: [],
          state: false,
        },
        chains: {
          data: [],
          state: false,
        },
        currency: {
          base: {},
          state: false,
        },
        state: false,
      },
      load: 0,
    };
  }

  getRevcent(headers, callback) {
    request(
      {
        method: "GET",
        url: this.props.chimeraserv.urlGet + "/revcent",
        headers: headers,
      },
      (error, response, body) => {
        console.log(body);

        callback(JSON.parse(body));
      }
    );
  }

  getBrand(headers, callback) {
    request(
      {
        method: "GET",
        url: this.props.chimeraserv.urlGet + "/brand",
        headers: headers,
      },
      (error, response, body) => {
        let data = [];

        if (Object.keys(JSON.parse(body)).length) {
          data = JSON.parse(body);
        }

        callback(data);
      }
    );
  }

  getCurrency(headers, callback) {
    request(
      {
        method: "GET",
        url: this.props.chimeraserv.urlGet + "/group",
        headers: headers,
      },
      (error, response, body) => {
        let data = [];

        if (Object.keys(JSON.parse(body)).length) {
          data = JSON.parse(body);
        }

        callback(data);
      }
    );
  }

  getChain(headers, callback) {
    request(
      {
        method: "GET",
        url: this.props.chimeraserv.urlGet + "/chain",
        headers: headers,
      },
      (error, response, body) => {
        let data = [];

        if (Object.keys(JSON.parse(body)).length) {
          data = JSON.parse(body);
        }

        callback(data);
      }
    );
  }

  getData(headers, callback) {
    request(
      {
        method: "GET",
        url:
          this.props.chimeraserv.urlGet +
          "/sales/set/0/" +
          this.props.filter.base64,
        headers: headers,
      },
      (error, response, body) => {
        callback(JSON.parse(body));
      }
    );
  }

  UNSAFE_componentWillMount() {
    if (this.props.user.data) {
      const headers = {
        Authorization: this.props.user.data.usrKey,
        "Content-Type": "application/json",
        Accept: "application/json",
      };

      this.getData(headers, (data) => {
        this.setState((prevState) => ({
          data: {
            ...prevState.data,
            sales: {
              data: data,
              state: true,
            },
          },
        }));
      });

      this.getRevcent(headers, (data) => {
        this.setState((prevState) => ({
          data: {
            ...prevState.data,
            revCent: {
              data: data,
              state: true,
            },
          },
        }));
      });

      this.getChain(headers, (data) => {
        this.setState((prevState) => ({
          data: {
            ...prevState.data,
            chains: {
              data: data,
              state: true,
            },
          },
        }));
      });

      this.getBrand(headers, (data) => {
        this.setState((prevState) => ({
          data: {
            ...prevState.data,
            brands: {
              data: data,
              state: true,
            },
          },
        }));
      });

      this.getCurrency(headers, (data) => {
        const array = [];

        const currency = data
          .map((group) => group.baseCurr)
          .filter((currency) => !array.includes(currency));

        this.setState((prevState) => ({
          data: {
            ...prevState.data,
            currency: {
              base: currency[0] ? currency[0]?.toUpperCase() : "QAR",
              state: true,
            },
          },
        }));
      });
    }
  }

  render() {
    if (
      this.state.data.currency.state &&
      this.state.data.sales.state &&
      this.state.data.revCent.state &&
      this.state.data.brands.state &&
      this.state.data.chains.state &&
      !this.state.load
    ) {
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          state: true,
        },
        load: 1,
      }));
    }

    return (
      <section>
        {!this.state.load ? (
          <Loader />
        ) : (
          <div>
            <Window
              user={this.props.user}
              chimeraserv={this.props.chimeraserv}
              title={"Sales By Revenue Center"}
            >
              <Slide3
                {...this.state}
                user={this.props.user}
                chimeraserv={this.props.chimeraserv}
                filter={this.props.filter}
                data={this.state.data}
              />
            </Window>
            <div className={["contentContainer"]}>
              <div className={["contentRow"]} style={{ width: "49.55%" }}>
                <Window
                  user={this.props.user}
                  chimeraserv={this.props.chimeraserv}
                  title={"Revenue Centers"}
                  horizontal
                >
                  <Slide1
                    {...this.state}
                    user={this.props.user}
                    chimeraserv={this.props.chimeraserv}
                    filter={this.props.filter}
                    data={this.state.data}
                  />
                </Window>
              </div>
              <div className={["contentRow"]} style={{ width: "49.55%" }}>
                <Window
                  user={this.props.user}
                  chimeraserv={this.props.chimeraserv}
                  title={"Revenue Center by Percentage"}
                  horizontal
                >
                  <Slide2
                    {...this.state}
                    user={this.props.user}
                    chimeraserv={this.props.chimeraserv}
                    filter={this.props.filter}
                    data={this.state.data}
                  />
                </Window>
              </div>
            </div>
          </div>
        )}
      </section>
    );
  }
}
