import React, { useEffect, useRef } from "react";

import commaNumber from "comma-number";

import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import "./style.scss";

function Slide3(props) {
  const mounted = useRef();

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      if (props.slideData?.length > 0) {
        props.getData({
          type: "table",
          title: "Open and closed",
          filter: {
            brands: props?.filter.data.brands.map((brand) => brand.name),
            chains: props?.filter.data.chains.map((chain) => chain.name),
            revCent: props?.filter.data.revCent.map((revCent) => revCent.name),
            payType: props?.filter.data.payType.map((payType) => payType.name),
            date: {
              start: props?.filter.data.date.start,
              end: props?.filter.data.date.end,
            },
          },

          body: [
            {
              head: [["Buisness name", "Open", "Closed", "Total"]],
              body: props.slideData?.map((value) => [
                value.restaurant_name,
                `${value.totalOpenSales} ${value.currency}`,
                `${value.totalClosedSales} ${value.currency}`,
                `${value.totalClosedSales + value.totalOpenSales} ${
                  value.currency
                }`,
              ]),
            },
          ],
        });
      }
    }
  }, [props.export]);

  const columns = [
    {
      Header: "Business Name",
      accessor: "restaurant_name",
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }

        if (row[filter.id] === filter.value) {
          return row[filter.id];
        }
      },
      Filter: ({ filter, onChange }) => {
        return (
          <select
            onChange={(event) => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}
          >
            <option value="all">All</option>
            {[
              ...new Set(
                props?.slideData.map((table) => table.restaurant_name)
              ),
            ].map((name) => (
              <option value={name}>{name}</option>
            ))}
          </select>
        );
      },
    },

    {
      Header: "Open",
      accessor: "totalOpenSales",
      Cell: (row) => {
        return `${row?.original?.currency?.toUpperCase()} ${commaNumber(
          row.value.toFixed(2)
        )}`;
      },
    },
    {
      Header: "Closed",
      accessor: "totalClosedSales",
      Cell: (row) => {
        return `${row?.original?.currency?.toUpperCase()} ${commaNumber(
          row.value.toFixed(2)
        )}`;
      },
    },
    {
      Header: "Total",
      Cell: (row) => {
        return `${row?.original?.currency?.toUpperCase()} ${commaNumber(
          row.original.totalClosedSales + row.original.totalOpenSales
        )}`;
      },
    },
  ];

  return (
    <div>
      <ReactTable
        className={["-striped", "-highlight"].join(" ")}
        data={props.slideData}
        columns={columns}
        sortable
        filterable
        getTdProps={() => ({
          style: {
            textAlign: "center",
          },
        })}
      />
    </div>
  );
}

export default Slide3;

// import React, { Component } from "react";
// import ReactTable from "react-table-v6";
// import commaNumber from "comma-number";
// import "react-table-v6/react-table.css";
// import "./style.scss";

// export default class page extends Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       data: {
//         result: null,
//         table: [],
//       },
//       load: 0,
//     };
//   }

//   _initialized() {
//     if (this.props.data && !this.state.load) {
//       this.setState((prevstate) => ({
//         data: {
//           ...prevstate.data,
//           result: {
//             ...this.props.filter.data,
//             sales: this.props.data.sales.data,
//           },
//         },
//         load: 1,
//       }));
//     }
//   }

//   loadData() {
//     if (this.state.load) {
//       let chains = this.state.data.result.chains.map((chain, key) => {
//         let brand = this.state.data.result.brands.find(
//           (brand) => brand.braKey === chain.braKey
//         );

//         return {
//           ...chain,
//           name: brand.name + " - " + chain.name,
//           key: key,
//           total: 0,
//           open: 0,
//           closed: 0,
//         };
//       });

//       this.state.data.result.sales.forEach((data, index) => {
//         let queryChain = chains.find((chain) => chain.chaKey === data.chaKey);

//         if (queryChain) {
//           chains[queryChain.key].total =
//             chains[queryChain.key].total + data.total;

//           switch (data.status) {
//             case 1:
//               chains[queryChain.key].closed =
//                 chains[queryChain.key].closed + data.total;
//               break;
//             case 0:
//               chains[queryChain.key].open =
//                 chains[queryChain.key].open + data.total;
//               break;
//             default:
//               break;
//           }
//         }
//       });

//       this.setState((prevState) => ({
//         data: {
//           ...prevState.data,
//           table: chains.sort(this.sortSales),
//         },
//         load: 2,
//       }));
//     }
//   }

//   sortSales(a, b) {
//     if (parseInt(a.name) > parseInt(b.name)) {
//       return -1;
//     }

//     if (parseInt(a.name) < parseInt(b.name)) {
//       return 1;
//     }

//     return 0;
//   }

//   componentDidUpdate = (prevProps) => {
//     if (prevProps.export !== this.props.export) {
//       this.props.getData({
//         type: "table",
//         title: "Open and Close Sales",
//         filter: {
//           brands: this.props.filter.data.brands.map((brand) => brand.name),
//           chains: this.props.filter.data.chains.map((chain) => chain.name),
//           revCent: this.props.filter.data.revCent.map(
//             (revCent) => revCent.name
//           ),
//           payType: this.props.filter.data.payType.map(
//             (payType) => payType.name
//           ),
//           date: {
//             start: this.props.filter.data.date.start,
//             end: this.props.filter.data.date.end,
//           },
//         },
//         body: [
//           {
//             head: [["Business name", "Open", "Closed", "Total"]],
//             body: this.state.data.table.map((value) => [
//               value.name,
//               `${value?.currency?.toUpperCase()} ${commaNumber(
//                 value.open.toFixed(2)
//               )}`,
//               `${value?.currency?.toUpperCase()} ${commaNumber(
//                 value.closed.toFixed(2)
//               )}`,
//               `${value?.currency?.toUpperCase()} ${commaNumber(
//                 value.total.toFixed(2)
//               )}`,
//             ]),
//           },
//         ],
//       });
//     }
//   };

//   render() {
//     switch (this.state.load) {
//       case 0:
//         this._initialized();
//         break;
//       case 1:
//         this.loadData();
//         break;
//       default:
//         break;
//     }

//     let uniqueNames;

//     if (this.state.data.table) {
//       uniqueNames = [
//         ...new Set(this.state.data.table.map((table) => table.name)),
//       ];
//     }

//     const columns = [
//       {
//         Header: "Business Name",
//         accessor: "name",
//         filterMethod: (filter, row) => {
//           if (filter.value === "all") {
//             return true;
//           }

//           if (row[filter.id] === filter.value) {
//             return row[filter.id];
//           }
//         },
//         Filter: ({ filter, onChange }) => {
//           return (
//             <select
//               onChange={(event) => onChange(event.target.value)}
//               style={{ width: "100%" }}
//               value={filter ? filter.value : "all"}
//             >
//               <option value="all">All</option>
//               {uniqueNames.map((name) => (
//                 <option value={name}>{name}</option>
//               ))}
//             </select>
//           );
//         },
//       },
//       {
//         Header: "Open",
//         accessor: "open",
//         Cell: (row) => {
//           return `${row?.original?.currency?.toUpperCase()} ${commaNumber(
//             row.value.toFixed(2)
//           )}`;
//         },
//       },
//       {
//         Header: "Closed",
//         accessor: "closed",
//         Cell: (row) => {
//           return `${row?.original?.currency?.toUpperCase()} ${commaNumber(
//             row.value.toFixed(2)
//           )}`;
//         },
//       },
//       {
//         Header: "Total",
//         accessor: "total",
//         Cell: (row) => {
//           return `${row?.original?.currency?.toUpperCase()} ${commaNumber(
//             row.value.toFixed(2)
//           )}`;
//         },
//       },
//     ];

//     return (
//       <div>
//         {!this.state.load ? (
//           <div />
//         ) : (
//           <ReactTable
//             className={["-striped", "-highlight"].join(" ")}
//             data={this.state.data.table}
//             columns={columns}
//             sortable
//             filterable
//             getTdProps={() => ({
//               style: {
//                 textAlign: "center",
//               },
//             })}
//           />
//         )}
//       </div>
//     );
//   }
// }
