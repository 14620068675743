import React, { useEffect, useState, useRef } from "react";

import { Helmet } from "react-helmet";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import request from "request";

import { currencies } from "../../../../../../../static/currencies";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function EditOutlet(props) {
  const headers = {
    Authorization: props.user.data.usrKey,
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  const [originalChainObj, setOriginalChainObj] = useState({});

  const [chainObj, setChainObj] = useState({});
  const [groups, setGroups] = useState([]);
  const [brands, setBrands] = useState([]);
  const [isValidChainName, setIsValidChainName] = useState(false);

  useEffect(() => {
    getGroups();
  }, []);

  useEffect(() => {
    if (groups.length > 0) {
      getSingleChain();
    }
  }, [groups]);

  useEffect(() => {
    if (groups.length > 0 && chainObj?.grpKey) {
      setBrands(
        groups.filter((item) => item.grpKey === chainObj.grpKey)[0].brands
      );
    }
  }, [groups, chainObj]);

  const firstUpdate_brands = useRef(true);

  useEffect(() => {
    if (brands.length > 0) {
      if (firstUpdate_brands.current) {
        // when change the group for this selected chain ==> we will get a new list of brands ==>
        // select first brand by default
        firstUpdate_brands.current = false;
        return;
      }
      setChainObj({ ...chainObj, braKey: brands[0].braKey });
    }
  }, [brands]);

  useEffect(() => {
    if (brands.length > 0 && chainObj.name) {
      validateChainName();
    }
  }, [chainObj.name, brands]);

  const validateChainName = async () => {
    const selectedName = chainObj?.name?.toLowerCase();

    if (selectedName) {
      const originalChainName = originalChainObj?.brands?.chains?.name;
      const chainsNames = brands
        .filter((brandItem) => brandItem.braKey == chainObj?.braKey)[0]
        .chains.filter((item) => item.name != originalChainName)
        .map((chainItem) => chainItem.name.toLowerCase());
      await setIsValidChainName(!chainsNames.includes(selectedName));
    } else {
      await setIsValidChainName(false);
    }
  };

  const iconChecker = (type = "default") => {
    if (type == "name") {
      return (
        <div className="mr-3">
          {!chainObj.grpKey ? (
            <FontAwesomeIcon icon="spinner" spin />
          ) : isValidChainName && chainObj?.name ? (
            <FontAwesomeIcon
              className={"icon correct"}
              icon={["fas", "check-circle"]}
            />
          ) : (
            <FontAwesomeIcon
              className={"icon wrong"}
              icon={["fas", "times-circle"]}
            />
          )}
        </div>
      );
    }
    return (
      <div className="mr-3">
        {chainObj?.grpKey ? (
          <FontAwesomeIcon
            className={"icon correct"}
            icon={["fas", "check-circle"]}
          />
        ) : (
          <FontAwesomeIcon icon="spinner" spin />
        )}
      </div>
    );
  };

  const getGroups = async () => {
    await request(
      {
        method: "GET",
        url: props.chimeraserv.urlGet + "/group",
        headers: headers,
      },
      (error, response, body) => {
        setGroups(JSON.parse(body));
      }
    );
  };

  const getSingleChain = async () => {
    await request(
      {
        method: "GET",
        url: props.chimeraserv.urlGet + "/singleChain/" + props.match.params.id,
        headers: headers,
      },
      async (error, response, body) => {
        const jsonBody = JSON.parse(body);
        const selectedChain = jsonBody[0];
        console.log("selectedChain", selectedChain);

        const obj = {
          grpKey: selectedChain?.grpKey,
          braKey: selectedChain?.brands.braKey,
          name: selectedChain?.brands?.chains?.name,
          currency: selectedChain.brands?.chains?.currency || currencies[0],
          outletExp: selectedChain?.brands?.chains?.outletExp
            ? new Date(selectedChain?.brands?.chains?.outletExp * 1000)
            : null,
        };
        await setOriginalChainObj(selectedChain);
        await setChainObj(obj);
      }
    );
  };

  const submitEditing = async (e) => {
    e.preventDefault();

    if (isValidChainName && chainObj?.outletExp) {
      await request(
        {
          method: "PUT",
          url:
            props.chimeraserv.urlGet + "/singleChain/" + props.match.params.id,
          headers: headers,
          body: {
            grpKey: chainObj?.grpKey,
            currency: chainObj?.currency,
            name: chainObj?.name,
            outletExp: chainObj?.outletExp,
          },
          json: true,
        },
        async (error, response, body) => {
          if (!error) {
            console.log("submitEditing body", body);
            alert("successfully updated");
            await getSingleChain();
          }
        }
      );
    } else {
      if (!isValidChainName) {
        alert("this name was already choosed by another outlet");
      } else {
        alert("invalid expiration date value");
      }
    }
  };

  return (
    <div className={"custForm"}>
      <Helmet>
        <title>Edit Outlet | Chimera Solutions | Insights</title>
      </Helmet>

      <div className={["viewTitle"]}>Edit an Outlet</div>
      <form onSubmit={submitEditing} className={["form"]}>
        <div className={["form-group align-items-center"]}>
          <label htmlFor="group">
            Group <span>*</span>
          </label>
          {iconChecker()}

          <select
            required
            disabled
            className="form-control"
            id="group"
            onChange={(event) => {
              console.log("seelcetdValue", event.target.value);
              setChainObj({
                ...chainObj,
                grpKey: event.target.value,
              });
            }}
          >
            {/* <option defaultValue={0} value={0}>
              -- business group of outlet --
            </option> */}
            {groups.map((data, key) => (
              <option
                selected={data?.grpKey === chainObj?.grpKey}
                value={data.grpKey}
                key={key}
              >
                {data.name}
              </option>
            ))}
          </select>
        </div>

        <div className={"form-group align-items-center"}>
          <label htmlFor="brand">
            Brand <span>*</span>
          </label>
          {iconChecker()}
          <select
            disabled
            required
            className="form-control"
            id="brand"
            onChange={(event) => {
              console.log("seelcetdValue", event.target.value);
              setChainObj({
                ...chainObj,
                braKey: event.target.value,
              });
            }}
          >
            {/* <option defaultValue={0} value={0}>
              -- business brand of outlet --
            </option> */}
            {brands.map((data, key) => {
              return (
                <option
                  selected={Boolean(data?.braKey === chainObj?.braKey)}
                  value={data.braKey}
                  key={key}
                >
                  {data.name}
                </option>
              );
            })}
          </select>
        </div>

        <div className={"form-group align-items-center"}>
          <label>
            Outlet Name <span>*</span>
          </label>
          {iconChecker("name")}
          <div className={["field"]}>
            <input
              required
              type={"text"}
              className={["form-control"]}
              id={"name"}
              minlength="4"
              placeholder={"enter outlet name"}
              value={chainObj?.name}
              onChange={(e) =>
                setChainObj({ ...chainObj, name: e.target.value })
              }
            />
          </div>
        </div>

        <div className={"form-group align-items-center"}>
          <label htmlFor="group">
            Currency <span>*</span>
          </label>
          {iconChecker()}
          <select
            required
            className="form-control"
            id="group"
            onChange={(e) =>
              setChainObj({ ...chainObj, currency: e.target.value })
            }
          >
            {currencies.map((item, key) => (
              <option
                selected={item === chainObj?.currency}
                key={key}
                value={item}
              >
                {item}
              </option>
            ))}
          </select>
        </div>

        <div className={["form-group"]}>
          <label>
            Outlet Expiration <span>*</span>
          </label>
          {iconChecker()}
          <div className={["date"].join(" ")}>
            <DatePicker
              className={["form-control"]}
              placeholderText="MM-DD-YY"
              selected={chainObj.outletExp}
              onSelect={async (date) =>
                await setChainObj({ ...chainObj, outletExp: new Date(date) })
              }
              onChange={async (date) =>
                await setChainObj({ ...chainObj, outletExp: new Date(date) })
              }
              // minDate={new Date()}
            />
          </div>
        </div>

        <button type={"submit"} className={["btn btn-primary"]}>
          Save
        </button>
        <button
          type={"button"}
          onClick={() => getSingleChain()}
          className={["btn btn-primary", "reset"].join(" ")}
        >
          Reset
        </button>
      </form>
    </div>
  );
}

export default EditOutlet;
