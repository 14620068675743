export function formatTitle(string) {
  let pathname = string
    .toLowerCase()
    .split(/(?: - )|(?:-)/)
    .map((string) =>
      string
        .split(/ /)
        .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
        .join(" ")
    )
    .join(" ");

  return pathname;
}
