import React, { Component } from "react";

import { Helmet } from "react-helmet";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import request from "request";

import "../../style.scss";

export default class CreateClient extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reset: new Date().getTime(),
      title: "Create User" + " | " + "Chimera Solutions | Insights",
      form: {
        state: null,
        message: "",
      },
      field: {
        username: {
          value: "",
          message: "",
          state: null,
        },
        password: {
          value: "",
          state: null,
        },
        rePassword: {
          value: "",
          message: "",
          state: null,
        },
        email: {
          value: "",
          message: "",
          state: null,
        },
        type: {
          value: "",
          data: [
            {
              value: "admin",
              name: "Admin",
            },
            {
              value: "user",
              name: "User",
            },
            {
              value: "demo",
              name: "Demo",
            },
          ],
          message: "",
          state: null,
        },
        group: {
          value: "",
          data: [],
          message: "",
          state: null,
        },
        level: {
          value: "",
          data: [],
          message: "",
          state: null,
        },
      },
    };

    this.valueChanged = this.valueChanged.bind(this);

    this.headers = {
      Authorization: this.props.user.data.usrKey,
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    this.time = null;
  }

  renderTitle = () => (
    <Helmet>
      <title>{this.state.title}</title>
    </Helmet>
  );

  validateFields = () => {
    this.setState((prevState) => ({
      form: {
        state: false,
      },
    }));

    if (
      this.state.field.username.state &&
      this.state.field.rePassword.state &&
      this.state.field.email.state &&
      /*this.state.field.accntExp.state &&*/ this.state.field.group.state &&
      this.state.field.level.state &&
      this.state.field.type.state
    ) {
      this.setState((prevState) => ({
        form: {
          state: true,
        },
      }));
    }
  };

  checkField = (data) => {
    clearTimeout(this.time);
    this.time = setTimeout(() => {
      switch (data.id) {
        case "username":
          if (data.value.length) {
            request(
              {
                method: "GET",
                url: this.props.chimeraserv.urlGet + "/user",
                headers: this.headers,
              },
              (error, response, body) => {
                let state = true;
                let message = "";

                if (data.value.length <= 3) {
                  state = false;
                  message = "username should be minimum of 4 characters";
                }

                if (
                  JSON.parse(body).find(
                    (result) => result.username === data.value
                  )
                ) {
                  state = false;
                  message = "username already exist";
                }

                this.setState((prevState) => ({
                  field: {
                    ...prevState.field,
                    [data.id]: {
                      ...prevState.field[data.id],
                      state: state,
                      message: message,
                    },
                  },
                }));
              }
            );
          }
          break;
        case "password":
          if (data.value.length) {
            let state = true;
            let message = "";

            if (data.value.length <= 4) {
              state = false;
              message = "password should be minimum of 8 characters";
            }

            this.setState((prevState) => ({
              field: {
                ...prevState.field,
                [data.id]: {
                  ...prevState.field[data.id],
                  state: state,
                  message: message,
                },
                rePassword: {
                  state: null,
                  message: "",
                },
              },
            }));
          }
          break;
        case "rePassword":
          if (data.value.length) {
            let state = true;
            let message = "";

            if (
              this.state.field.password.value !==
              this.state.field.rePassword.value
            ) {
              state = false;
              message = "passwords did not match";
            }

            this.setState((prevState) => ({
              field: {
                ...prevState.field,
                [data.id]: {
                  ...prevState.field[data.id],
                  state: state,
                  message: message,
                },
              },
            }));
          }
          break;
        case "email":
          if (data.value.length) {
            request(
              {
                method: "GET",
                url: this.props.chimeraserv.urlGet + "/user",
                headers: this.headers,
              },
              (error, response, body) => {
                let state = true;
                let message = "";

                // eslint-disable-next-line
                const regex =
                  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

                if (!regex.test(String(data.value).toLowerCase())) {
                  state = false;
                  message = "invalid email address";
                }

                if (
                  JSON.parse(body).find((result) => result.email === data.value)
                ) {
                  state = false;
                  message = "email address already exist";
                }

                this.setState((prevState) => ({
                  field: {
                    ...prevState.field,
                    [data.id]: {
                      ...prevState.field[data.id],
                      state: state,
                      message: message,
                    },
                  },
                }));
              }
            );
          }
          break;
        case "type":
          this.setState((prevState) => ({
            field: {
              ...prevState.field,
              type: {
                ...prevState.field.type,
                value: null,
                state: null,
              },
              group: {
                ...prevState.field.group,
                value: null,
                state: null,
              },
              level: {
                ...prevState.field.level,
                value: null,
                data: [],
                state: null,
              },
            },
          }));

          if (data.value !== "0") {
            if (data.value === "admin") {
              this.setState((prevState) => ({
                field: {
                  ...prevState.field,
                  type: {
                    ...prevState.field.type,
                    value: data.value,
                    state: true,
                  },
                  group: {
                    ...prevState.field.group,
                    value: "",
                    state: true,
                  },
                  level: {
                    ...prevState.field.level,
                    value: 0,
                    state: true,
                  },
                },
              }));
            } else {
              this.setState((prevState) => ({
                field: {
                  ...prevState.field,
                  type: {
                    ...prevState.field.type,
                    value: data.value,
                    state: true,
                  },
                },
              }));
            }
          }
          break;
        case "group":
          const group = JSON.parse(data.value);

          this.setState((prevState) => ({
            field: {
              ...prevState.field,
              group: {
                ...prevState.field.group,
                value: null,
                state: null,
              },
              level: {
                ...prevState.field.level,
                value: null,
                data: [],
                state: null,
              },
            },
          }));

          if (data.value !== "0") {
            this.setState((prevState) => ({
              field: {
                ...prevState.field,
                group: {
                  ...prevState.field.group,
                  value: group._id,
                  state: true,
                },
                level: {
                  ...prevState.field.level,
                  data: this.state.field.group.data.find(
                    (res) => res.grpKey === group.grpKey
                  ).levels,
                },
              },
            }));
          }
          break;
        case "level":
          this.setState((prevState) => ({
            field: {
              ...prevState.field,
              level: {
                ...prevState.field.level,
                value: null,
                state: null,
              },
            },
          }));

          if (data.value !== "0") {
            this.setState((prevState) => ({
              field: {
                ...prevState.field,
                level: {
                  ...prevState.field.level,
                  value: data.value,
                  state: true,
                },
              },
            }));
          }
          break;
        default:
          this.setState({});
          break;
      }

      this.validateFields();
    }, 500);
  };

  valueChanged = (event) => {
    const element = event.target;

    this.setState((prevState) => ({
      field: {
        ...prevState.field,
        [element.id]: {
          ...prevState.field[element.id],
          value: element.value,
          message: "",
          state: null,
        },
      },
      form: {
        state: false,
      },
    }));

    this.checkField(element);
  };

  getGroup = () =>
    request(
      {
        method: "GET",
        url: this.props.chimeraserv.urlGet + "/group",
        headers: this.headers,
      },
      (error, response, body) => {
        this.setState((prevState) => ({
          field: {
            ...prevState.field,
            group: {
              ...prevState.field.group,
              data: JSON.parse(body),
            },
          },
        }));
      }
    );

  reset = () =>
    this.setState({
      reset: new Date().getTime(),
    });

  componentDidMount = () => {
    if (this.props.user.data.type !== "admin") {
      this.setState((prevState) => ({
        field: {
          ...prevState.field,
          type: {
            ...prevState.field.type,
            value: "user",
            state: true,
          },
        },
      }));
    }

    this.getGroup();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.reset !== this.state.reset) {
      this.setState((prevState) => ({
        field: {
          ...prevState.field,
          username: {
            value: "",
            message: "",
            state: null,
          },
          password: {
            value: "",
            state: null,
          },
          rePassword: {
            value: "",
            message: "",
            state: null,
          },
          email: {
            value: "",
            message: "",
            state: null,
          },
          group: {
            value: "",
            data: [],
            message: "",
            state: null,
          },
          level: {
            value: "",
            data: [],
            message: "",
            state: null,
          },
          type: {
            value: "null",
            data: [],
            message: "",
            state: null,
          },
        },
      }));

      setTimeout(() => {
        if (this.props.user.data.type !== "admin") {
          this.setState((prevState) => ({
            field: {
              ...prevState.field,
              type: {
                ...prevState.field.type,
                value: "user",
                state: true,
              },
            },
          }));
        } else {
          this.setState((prevState) => ({
            field: {
              ...prevState.field,
              type: {
                ...prevState.field.type,
                data: [
                  {
                    value: "admin",
                    name: "Admin",
                  },
                  {
                    value: "user",
                    name: "User",
                  },
                  {
                    value: "demo",
                    name: "Demo",
                  },
                ],
              },
            },
          }));
        }
      }, 500);

      this.getGroup();
    }
  };

  checker(state) {
    const check = {
      icon: "check-circle",
      style: "null",
    };

    switch (state) {
      case true:
        check.icon = "check-circle";
        check.style = "correct";
        break;
      case false:
        check.icon = "times-circle";
        check.style = "wrong";
        break;
      default:
        break;
    }

    return check;
  }

  submit = () => {
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        state: false,
        message: "",
      },
    }));

    request(
      {
        method: "POST",
        url: this.props.chimeraserv.urlPost + "/user/create",
        headers: {
          Authorization: this.props.user.data.usrKey,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: {
          username: this.state.field.username.value,
          password: this.state.field.password.value,
          email: this.state.field.email.value,
          group: this.state.field.group.value,
          type: this.state.field.type.value,
          level: this.state.field.level.value,
        },
        json: true,
      },
      (error, response, body) => {
        if (!error) {
          if (!body.__v) {
            this.reset();

            this.setState((prevState) => ({
              form: {
                ...prevState.form,
                message: "User Created",
              },
            }));
          }
        } else {
          this.setState((prevState) => ({
            form: {
              ...prevState.form,
              message: JSON.stringify(error),
            },
          }));
        }
      }
    );
  };

  genPass = () => {
    const length = 8;
    const charset = `!@#$%^&*()-=abcdefghijklmnopqrstuvwxyz0123456789`;
    const retVal = [];

    for (let i = 0, n = charset.length; i < length; ++i) {
      let char = charset.charAt(Math.floor(Math.random() * n));

      if (Math.round(Math.random())) {
        retVal.push(char.toUpperCase());
      } else {
        retVal.push(char.toLowerCase());
      }
    }

    const password = String(retVal.join(""));

    this.setState(
      (prevState) => ({
        field: {
          ...prevState.field,
          password: {
            ...prevState.field.password,
            message: `Generated new password: ${password}`,
            value: password,
            state: true,
          },
          rePassword: {
            ...prevState.field.rePassword,
            value: password,
            state: true,
          },
        },
      }),
      () => this.validateFields()
    );
  };

  render() {
    return (
      <div className={"custForm"}>
        {this.renderTitle()}
        <div className={["viewTitle"]}>Create New User</div>
        <form className={"form"}>
          <div className={["form-group"]}>
            <label htmlFor={["exampleInputEmail1"]}>
              Username <span>*</span>
            </label>
            <FontAwesomeIcon
              className={[
                "icon",
                this.checker(this.state.field.username.state).style,
              ].join(" ")}
              icon={["fas", this.checker(this.state.field.username.state).icon]}
            />
            <div className={["field"]}>
              <input
                type={"text"}
                className={["form-control"]}
                id={"username"}
                placeholder={"username"}
                value={this.state.field.username.value}
                onChange={this.valueChanged}
              />
              <span>{this.state.field.username.message}</span>
            </div>
          </div>
          <div className={["form-group"]}>
            <label htmlFor={["exampleInputEmail1"]}>
              Password <span>*</span>
            </label>
            <FontAwesomeIcon
              className={[
                "icon",
                this.checker(this.state.field.password.state).style,
              ].join(" ")}
              icon={["fas", this.checker(this.state.field.password.state).icon]}
            />
            <div className={["field"].join(" ")}>
              <div className={["password"].join(" ")}>
                <input
                  type={"password"}
                  className={["form-control"]}
                  id={"password"}
                  placeholder={"password"}
                  value={this.state.field.password.value}
                  onChange={this.valueChanged}
                />
                <button type={"button"} onClick={() => this.genPass()}>
                  <FontAwesomeIcon
                    className={["icon"].join(" ")}
                    icon={"sync-alt"}
                  />
                </button>
              </div>
              <span>{this.state.field.password.message}</span>
            </div>
          </div>
          <div className={["form-group"]}>
            <label htmlFor={["exampleInputEmail1"]}>
              Re-type Password <span>*</span>
            </label>
            <FontAwesomeIcon
              className={[
                "icon",
                this.checker(this.state.field.rePassword.state).style,
              ].join(" ")}
              icon={[
                "fas",
                this.checker(this.state.field.rePassword.state).icon,
              ]}
            />
            <div className={["field"].join(" ")}>
              <div className={["password"].join(" ")}>
                <input
                  type={"password"}
                  className={["form-control"]}
                  id={"rePassword"}
                  placeholder={"re-type password"}
                  value={this.state.field.rePassword.value}
                  onChange={this.valueChanged}
                />
              </div>
              <span>{this.state.field.rePassword.message}</span>
            </div>
          </div>
          <div className={["form-group"]}>
            <label htmlFor={["exampleInputEmail1"]}>
              Email Address <span>*</span>
            </label>
            <FontAwesomeIcon
              className={[
                "icon",
                this.checker(this.state.field.email.state).style,
              ].join(" ")}
              icon={["fas", this.checker(this.state.field.email.state).icon]}
            />
            <div className={["field"]}>
              <input
                type={"text"}
                className={["form-control"]}
                id={"email"}
                placeholder={"email address"}
                value={this.state.field.email.value}
                onChange={this.valueChanged}
              />
              <span>{this.state.field.email.message}</span>
            </div>
          </div>
          {this.props.user.data.type === "admin" ? (
            <div>
              <div className={["form-group"]}>
                <label htmlFor="type">
                  User Type <span>*</span>
                </label>
                <FontAwesomeIcon
                  className={[
                    "icon",
                    this.checker(this.state.field.type.state).style,
                  ].join(" ")}
                  icon={["fas", this.checker(this.state.field.type.state).icon]}
                />
                <select
                  className="form-control"
                  id="type"
                  onChange={this.valueChanged}
                >
                  <option defaultValue={0} value={0}>
                    -- type of user --
                  </option>
                  {this.state.field.type.data.map((data, key) => (
                    <option value={data.value} key={key}>
                      {data.name}
                    </option>
                  ))}
                </select>
              </div>
              {this.state.field.type.state ? (
                this.state.field.type.value !== "admin" ? (
                  <div>
                    <div className={["form-group"]}>
                      <label htmlFor="group">
                        Group <span>*</span>
                      </label>
                      <FontAwesomeIcon
                        className={[
                          "icon",
                          this.checker(this.state.field.group.state).style,
                        ].join(" ")}
                        icon={[
                          "fas",
                          this.checker(this.state.field.group.state).icon,
                        ]}
                      />
                      <select
                        className="form-control"
                        id="group"
                        onChange={this.valueChanged}
                      >
                        <option defaultValue={0} value={0}>
                          -- business group of user --
                        </option>
                        {this.state.field.group.data.map((data, key) => (
                          <option
                            value={JSON.stringify({
                              name: data.name,
                              grpKey: data.grpKey,
                              _id: data._id,
                            })}
                            key={key}
                          >
                            {data.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className={["form-group"]}>
                      <label htmlFor="type">
                        User level <span>*</span>
                      </label>
                      <FontAwesomeIcon
                        className={[
                          "icon",
                          this.checker(this.state.field.level.state).style,
                        ].join(" ")}
                        icon={[
                          "fas",
                          this.checker(this.state.field.level.state).icon,
                        ]}
                      />
                      <select
                        className="form-control"
                        id="level"
                        onChange={this.valueChanged}
                      >
                        <option defaultValue={0} value={0}>
                          -- level of user --
                        </option>
                        {this.state.field.level.data.map((data, key) => (
                          <option value={data.id} key={key}>
                            {data.id} - {data.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                ) : (
                  <div />
                )
              ) : (
                <div />
              )}
            </div>
          ) : (
            <div>
              <div>
                <div className={["form-group"]}>
                  <label htmlFor="group">
                    Group <span>*</span>
                  </label>
                  <FontAwesomeIcon
                    className={[
                      "icon",
                      this.checker(this.state.field.group.state).style,
                    ].join(" ")}
                    icon={[
                      "fas",
                      this.checker(this.state.field.group.state).icon,
                    ]}
                  />
                  <select
                    className="form-control"
                    id="group"
                    onChange={this.valueChanged}
                  >
                    <option defaultValue={0}>
                      -- business group of user --
                    </option>
                    {this.state.field.group.data.map((data, key) => (
                      <option
                        value={JSON.stringify({
                          name: data.name,
                          grpKey: data.grpKey,
                          _id: data._id,
                        })}
                        key={key}
                      >
                        {data.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className={["form-group"]}>
                  <label htmlFor="type">
                    User level <span>*</span>
                  </label>
                  <FontAwesomeIcon
                    className={[
                      "icon",
                      this.checker(this.state.field.level.state).style,
                    ].join(" ")}
                    icon={[
                      "fas",
                      this.checker(this.state.field.level.state).icon,
                    ]}
                  />
                  <select
                    className="form-control"
                    id="level"
                    onChange={this.valueChanged}
                  >
                    <option defaultValue={0}>-- level of user --</option>
                    {this.state.field.level.data.map((data, key) => (
                      <option value={data.id} key={key}>
                        {data.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          )}
          {this.state.form.message ? (
            <div className={["message", "form-group"].join(" ")}>
              <FontAwesomeIcon
                className={["icon", "correct"].join(" ")}
                icon={"check-circle"}
              />
              {this.state.form.message}
            </div>
          ) : (
            <div />
          )}
          <button
            type={"button"}
            className={["btn btn-primary"]}
            disabled={this.state.form.state ? false : true}
            onClick={() => this.submit()}
          >
            Create
          </button>
          <button
            type={"button"}
            onClick={() => this.reset()}
            className={["btn btn-primary", "reset"].join(" ")}
          >
            Reset
          </button>
        </form>
      </div>
    );
  }
}
